import React, { useState, useEffect } from "react";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";
import LoadingIcon from "../../components/LoadingIcon";
import {
  MDBBtn,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInputGroup,
} from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";

const CurrentPermissionsList = () => {
  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [allPermissions, setAllPermissions] = useState({});
  const [newPermission, setNewPermission] = useState("");
  const [permissionToDelete, setPermissionToDelete] = useState(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [canCreate, setCanCreate] = useState(false);

  const getPermissions = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/permissions", "get")
      .then((response) => {
        setAllPermissions(processPermissions(response.permissions, response.can_delete));
        setCanCreate(response.can_create);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to display a list of permissions.", "warning");
      });
  };

  const processPermissions = (permissions, canDeletePermissions) => {
    permissions.sort();
    const columns = [
      {
        label: "Name",
        field: "name",
      },
      {
        label: "",
        field: "actions",
        sort: "disabled",
      },
    ];
    let rows = [];
    permissions.map((permission) => {
      let deleteButton = null;
      if (canDeletePermissions)
        deleteButton = (
          <MDBIcon
            icon="times"
            onClick={(e) => onDeletePermissionClick(permission)}
            className="float-right red-text clickable"
          />
        );
      rows.push({
        name: permission,
        actions: deleteButton,
      });
      return true;
    });
    return {
      columns: columns,
      rows: rows,
    };
  };

  const onDeletePermissionClick = (permission) => {
    setPermissionToDelete(permission);
    setConfirmDeleteModalOpen(true);
  };

  const deletePermission = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/permissions/" + permissionToDelete, "del")
      .then((response) => {
        setConfirmDeleteModalOpen(false);
        showNotification("Permission deleted", permissionToDelete + " was successfully deleted.", "success");
        getPermissions();
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to delete permissions.", "warning");
      });
  };

  const toggleDeleteModal = () => {
    setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
  };

  const onSaveNewPermission = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/permissions", "put", {
      body: { name: newPermission },
    })
      .then((response) => {
        setNewPermission("");
        getPermissions();
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to create permissions.", "warning");
      });
  };

  let loadingContent = null;
  if (isLoading) loadingContent = <LoadingIcon fullHeight={false} />;

  let newPermissionContent = null;
  if (canCreate)
    newPermissionContent = (
      <MDBCard className="mt-4 mb-4">
        <MDBCardBody>
          <MDBCardTitle>New permission</MDBCardTitle>
          <form onSubmit={(e) => onSaveNewPermission(e)}>
            <MDBInputGroup
              material
              containerClassName="mb-3 mt-0"
              hint="New permission"
              value={newPermission}
              getValue={setNewPermission}
              append={
                <MDBBtn color="primary" className="m-0 px-3 py-2 z-depth-0" onClick={(e) => onSaveNewPermission(e)}>
                  Save
                </MDBBtn>
              }
            />
          </form>
        </MDBCardBody>
      </MDBCard>
    );

  return (
    <>
      {loadingContent}
      {newPermissionContent}
      <MDBDataTable
        key="current-permissions-table"
        hover
        entriesOptions={[50, 100]}
        entries={100}
        pagesAmount={4}
        data={allPermissions}
        noRecordsFoundLabel="No permissions to display."
      />
      <MDBModal isOpen={confirmDeleteModalOpen} toggle={toggleDeleteModal}>
        <MDBModalHeader>Delete permission</MDBModalHeader>
        <MDBModalBody>
          Are you sure you want to delete {permissionToDelete}? This action cannot be undone, and will affect every role
          that includes this permission.
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={(e) => toggleDeleteModal()}>
            Cancel
          </MDBBtn>
          <MDBBtn color="primary" onClick={(e) => deletePermission()}>
            OK
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
};

export default CurrentPermissionsList;
