import React, { useState } from "react";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBDataTable,
} from "mdbreact";
import showNotification from "../../helpers/showNotification";

const WorkflowRecipesNewModal = ({ components, saveNewRecord }) => {
  const [newRecordModalOpen, setNewRecordModalOpen] = useState(false);
  const [newRecord, setNewRecord] = useState({
    name: "",
    metadata: {},
    componentId: null,
  });
  // const [selectedComponentName, setSelectedComponentName] = useState("");

  const onNewRecordClick = () => {
    toggleNewRecordModal();
  };
  const toggleNewRecordModal = () => {
    setNewRecordModalOpen(!newRecordModalOpen);
  };
  const onNewRecordSubmit = (e) => {
    e.preventDefault();
    const validateNewRecord = (newRecord) => {
      if (newRecord.name !== "" && newRecord.metadata) {
        return true;
      } else {
        return false;
      }
    };

    if (validateNewRecord(newRecord)) {
      saveNewRecord(newRecord);
      toggleNewRecordModal();
    } else {
      showNotification("Error", "Please make sure you have entered a name and at least one metadata", "warning");
    }
  };

  const saveNewMetadata = (e) => {
    e.preventDefault();
    const newMetadataName = document.getElementById("new-metadata-name").value;
    const newMetadataValue = document.getElementById("new-metadata-value").value;
    if (newMetadataName !== "") {
      let newNewRecord = Object.assign({}, newRecord);
      newNewRecord.metadata[newMetadataName] = {value: newMetadataValue};
      setNewRecord(newNewRecord);
      document.getElementById("new-metadata-name").value = "";
      document.getElementById("new-metadata-value").value = "";
    }
  };

  const deleteMetadata = (metadataName) => {
    let newNewRecord = Object.assign({}, newRecord);
    delete newNewRecord.metadata[metadataName];
    setNewRecord(newNewRecord);
  };

  let newRecordMetadatas;
  if (newRecord.metadata) {
    let newRecordMetadatasData = {
      columns: [
        { label: "Name", field: "name" },
        { label: "Value", field: "value" },
        { label: "", field: "actions" },
      ],
      rows: [],
    };
    Object.keys(newRecord.metadata).forEach((metadataName) => {
      newRecordMetadatasData.rows.push({
        name: metadataName,
        value: newRecord.metadata[metadataName].value.toString(),
        actions: (
          <MDBIcon icon="times" className="clickable float-right" onClick={(e) => deleteMetadata(metadataName)} />
        ),
      });
    });
    newRecordMetadatas = (
      <MDBDataTable
        data={newRecordMetadatasData}
        displayEntries={false}
        info={false}
        noBottomColumns={true}
        paging={false}
        searching={false}
        sortable={false}
        noRecordsFoundLabel="Please add at least one metadata item"
        hover
      />
    );
  }

  let componentsSelectOptions = [];
  if (components.length) {
    components.forEach((component) => {
      componentsSelectOptions.push(
        <option value={component.componentId} key={component.componentId}>
          {component.name}
        </option>
      );
    });
  }

  const onComponentSelectChange = (e) => {
    components.forEach((component) => {
      if (component.componentId === parseInt(e)) {
        let newNewRecord = Object.assign({}, newRecord);
        newNewRecord.metadata = component.inputs;
        newNewRecord.componentId = component.componentId;
        setNewRecord(newNewRecord);
      }
    });
  };

  return (
    <>
      <MDBBtn color="primary" onClick={(e) => onNewRecordClick()}>
        <MDBIcon icon="plus-circle" className="mr-2" /> Add new
      </MDBBtn>
      <MDBModal isOpen={newRecordModalOpen} toggle={toggleNewRecordModal}>
        <MDBModalHeader>New recipe</MDBModalHeader>
        <form onSubmit={(e) => onNewRecordSubmit(e)}>
          <MDBModalBody>
            <MDBInput
              label="Name"
              valueDefault=""
              getValue={(e) =>
                setNewRecord((oldNewRecord) => {
                  oldNewRecord.name = e;
                  return oldNewRecord;
                })
              }
            />
            <label htmlFor="select-component">Component</label>
            <select
              className="browser-default custom-select mb-2"
              onChange={(e) => onComponentSelectChange(e.target.value)}
              id="select-component"
            >
              <option value={null} disabled selected>
                Select a component
              </option>
              {componentsSelectOptions}
            </select>
            <fieldset>
              <legend>Metadata</legend>
              <p>Default metadata from your selected component is displayed below. Overwrite using the below form.</p>
              <form onSubmit={(e) => saveNewMetadata(e)}>
                <MDBRow>
                  <MDBCol size="4">
                    <MDBInput label="Metadata name" id="new-metadata-name" valueDefault="" />
                  </MDBCol>
                  <MDBCol size="5">
                    <MDBInput label="Metadata value" id="new-metadata-value" valueDefault="" />
                  </MDBCol>
                  <MDBCol size="3">
                    <MDBBtn type="submit" onClick={(e) => saveNewMetadata(e)} className="mb-0">
                      Set
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>
              {newRecordMetadatas}
            </fieldset>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={(e) => {
                setNewRecord({});
                toggleNewRecordModal();
              }}
              color="secondary"
            >
              Cancel
            </MDBBtn>
            <MDBBtn type="submit" onClick={(e) => onNewRecordSubmit(e)}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </>
  );
};

export default WorkflowRecipesNewModal;
