import { MDBCol, MDBRow } from "mdbreact";
import React, { useEffect, useState } from "react";
import { MonthPicker, DayPicker } from "react-dropdown-date";

const Birthday = ({ userData, updateUserData }) => {
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    if ("birthday" in userData) {
      setSelectedDay(parseInt(userData.birthday.split("/")[0]));
      setSelectedMonth(parseInt(userData.birthday.split("/")[1]) - 1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateUserData({ target: { id: "birthday", value: selectedDay + "/" + (selectedMonth + 1) } });
    // eslint-disable-next-line
  }, [selectedDay, selectedMonth]);

  return (
    <div className="form-group">
      <label>Birthday</label>
      <MDBRow>
        <MDBCol md="3">
          <DayPicker
            required={!(isNaN(selectedMonth) && !isNaN(selectedDay))}
            classes="form-control"
            defaultValue={"Day"}
            year={2012} // mandatory
            month={selectedMonth} // mandatory
            value={selectedDay} // mandatory
            onChange={(day) => {
              console.log("Day-M:",typeof(selectedMonth),"Day-D:",typeof(selectedDay))
              setSelectedDay(parseInt(day));
            }}
            id={"day"}
            name={"day"}
          />
        </MDBCol>
        <MDBCol md="4">
          <MonthPicker
            required={!(isNaN(selectedDay) && !isNaN(selectedMonth))}
            classes="form-control"
            defaultValue={"Month"}
            year={2012} // mandatory
            value={selectedMonth} // mandatory
            onChange={(month) => {
              console.log("Month-M:",selectedMonth,"Month-D:",selectedDay)
              setSelectedMonth(parseInt(month));
            }}
            id={"month"}
            name={"month"}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default Birthday;
