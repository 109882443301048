import { MDBCard, MDBCardBody, MDBCardTitle, MDBInput } from "mdbreact";
import React, { useRef } from "react";

const ConfigurationSetInformation = ({
  schemas,
  configurationSet,
  updateConfigurationSetInformation,
  defaultSchemaValue,
}) => {
  const defaultSchemaValueRef = useRef(defaultSchemaValue);

  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          Configuration set details
        </MDBCardTitle>
        <MDBInput
          label="Name *"
          getValue={(e) => updateConfigurationSetInformation("name", e)}
          value={configurationSet.name}
        />

        {schemas && (
          <select
            className="browser-default custom-select"
            onChange={(e) => updateConfigurationSetInformation("schema", e.target.value)}
            defaultValue={defaultSchemaValueRef.current}
          >
            <option value="0">Schema</option>
            {schemas.map((schema) => {
              return (
                <option
                  value={schema.uuid}
                  key={schema.uuid}
                  // selected={configurationSet.schema !== null && schema.uuid === configurationSet.schema.uuid}
                >
                  {schema.label}
                </option>
              );
            })}
          </select>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetInformation;
