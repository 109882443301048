const config = {
  environmentName: "production",
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://va0tciam90.execute-api.eu-west-2.amazonaws.com/production",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_A93qLPaFj",
    APP_CLIENT_ID: "2m1cp36kiqb0eub168e02jv5aa",
  },
  uploaderUrl: "https://uploader.take1.tv/",
  uploaderApiUrl: "https://uploader-api.take1.tv/",
  cookieDomain: ".take1.tv",
  largeFileUploaderHostname: "largefileuploader.take1.tv"
};

export default config;
