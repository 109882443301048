import { MDBCard, MDBCardBody, MDBInput } from "mdbreact";
import React from "react";
import moment from "moment";

const SchemaMoreInformation = ({ schema, userFullName }) => {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        {userFullName && <MDBInput disabled value={userFullName} label="Schema owner" />}
        {"uuid" in schema && <MDBInput disabled value={schema.uuid} label="Schema UUID" />}
        <MDBInput disabled value={moment(schema.createDateTime).format("YYYY-MM-DD hh:mm:ss")} label="Created" />
        {"modifiedDateTime" in schema && (
          <MDBInput
            disabled
            value={moment(schema.modifiedDateTime).format("YYYY-MM-DD hh:mm:ss")}
            label="Last modified"
          />
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaMoreInformation;
