import React, { useState } from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from "mdbreact";
import showNotification from "../helpers/showNotification";
import { Auth } from "aws-amplify";

const ResetPassword = () => {
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [confirmationCodeModalOpen, setConfirmationCodeModalOpen] = useState(false);
  const [completeModalOpen, setCompleteModalOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");

  const validateEmail = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        // eslint-disable-next-line
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/;
        if (passwordRegex.test(password)) {
          return true;
        } else {
          showNotification(
            "Invalid password",
            "Please make sure your password is at least 8 characters long and contains uppercase letters, lowercase letters, numbers, and at least one special character.",
            "warning"
          );
          return false;
        }
      } else {
        showNotification("Password mismatch", "Please make sure you have entered your password correctly.", "warning");
        return false;
      }
    } else {
      showNotification(
        "No password entered",
        "Please make sure you have entered and confirmed your password.",
        "warning"
      );
      return false;
    }
  };

  const onResetPasswordClick = (e) => {
    e.preventDefault();
    setResetPasswordModalOpen(true);
  };
  const onResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail()) {
      await Auth.forgotPassword(email)
        .then((response) => {
          setResetPasswordModalOpen(false);
          setConfirmationCodeModalOpen(true);
          setRecipientEmail(response.CodeDeliveryDetails.Destination);
        })
        .catch((e) => {
          showNotification("Error", "Something went wrong.", "error");
          console.log(e);
        });
    } else {
      showNotification("Invalid email", "Please make sure you have entered a valid email address", "warning");
    }
  };
  const toggleResetPasswordModal = () => {
    setResetPasswordModalOpen(!resetPasswordModalOpen);
  };
  const onConfirmationCodeSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await Auth.forgotPasswordSubmit(email, code, password)
        .then(() => {
          setConfirmationCodeModalOpen(false);
          setCompleteModalOpen(true);
        })
        .catch((e) => {
          showNotification("Error", "Something went wrong.", "error");
          console.log(e);
        });
    }
  };
  const toggleConfirmationCodeModal = () => {
    setConfirmationCodeModalOpen(!confirmationCodeModalOpen);
  };

  const toggleCompleteModal = (e) => {
    e.preventDefault();
    setCompleteModalOpen(!completeModalOpen);
  };

  return (
    <>
      <p>
        <a href="#!" onClick={(e) => onResetPasswordClick(e)}>
          Request new password
        </a>
      </p>

      <MDBModal isOpen={resetPasswordModalOpen} toggle={toggleResetPasswordModal}>
        <MDBModalHeader>Reset password</MDBModalHeader>
        <form onSubmit={(e) => onResetPasswordSubmit(e)}>
          <MDBModalBody>
            <MDBInput type="text" getValue={setEmail} value={email} label="Email address" autocomplete="new-password" />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={(e) => {
                setEmail("");
                setResetPasswordModalOpen(false);
              }}
            >
              Cancel
            </MDBBtn>
            <MDBBtn type="submit" onClick={(e) => onResetPasswordSubmit(e)}>
              OK
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>

      <MDBModal isOpen={confirmationCodeModalOpen} toggle={toggleConfirmationCodeModal}>
        <MDBModalHeader>Reset password</MDBModalHeader>
        <form onSubmit={(e) => onConfirmationCodeSubmit(e)}>
          <MDBModalBody>
            <p>A confirmation code has been sent to {recipientEmail}. Please enter it below to continue.</p>
            <MDBInput type="text" getValue={setCode} label="Confirmation code" value={code} autocomplete="new-password" />
            <MDBInput type="password" getValue={setPassword} label="New password" value={password} autocomplete="new-password" />
            <MDBInput
              type="password"
              getValue={setConfirmPassword}
              label="Confirm new password"
              value={confirmPassword}
              autocomplete="new-password"
            />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn type="submit" onClick={(e) => onConfirmationCodeSubmit(e)}>
              OK
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>

      <MDBModal isOpen={completeModalOpen} toggle={toggleCompleteModal}>
        <MDBModalHeader>Reset password</MDBModalHeader>
        <MDBModalBody>
          <p>Your password was reset successfully. You can now log in.</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn onClick={(e) => toggleCompleteModal(e)}>Continue</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
};

export default ResetPassword;
