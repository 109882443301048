import React, { useState } from "react";
import { MDBBtn, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput, MDBSelect } from "mdbreact";

const HomepageCardsCreateModal = ({ toggleCreateModal, createCard, cardCategories }) => {
  const [taskDetails, setTaskDetails] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    createCard(taskDetails);
  };

  let cardCategoryOptions = [];
  if (cardCategories) {
    cardCategories.forEach((category) => {
      let cardCategoryOption = {
        value: category.name,
        text: category.label,
      };
      category.name === taskDetails.cardCategory
        ? (cardCategoryOption.checked = true)
        : (cardCategoryOption.checked = false);
      cardCategoryOptions.push(cardCategoryOption);
    });
  }

  return (
    <form onSubmit={handleSubmit} className="homepage-card-form">
      <MDBModalHeader toggle={toggleCreateModal}>New homepage card</MDBModalHeader>
      <MDBModalBody>
        <div className="form-group">
          <MDBInput
            label="Title"
            type="text"
            value={taskDetails.cardTitle}
            onChange={(e) => setTaskDetails({ ...taskDetails, cardTitle: e.target.value })}
          />
        </div>
        <div className="form-group">
          <MDBInput
            label="Icon"
            type="text"
            value={taskDetails.cardIcon}
            onChange={(e) =>
              setTaskDetails({
                ...taskDetails,
                cardIcon: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <MDBInput
            label="URL"
            type="text"
            value={taskDetails.cardUrl}
            onChange={(e) =>
              setTaskDetails({
                ...taskDetails,
                cardUrl: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <MDBInput
            label="Required permission"
            type="text"
            value={taskDetails.cardPermission}
            onChange={(e) =>
              setTaskDetails({
                ...taskDetails,
                cardPermission: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <MDBSelect
            options={cardCategoryOptions}
            label="Category"
            getValue={(e) => {
              if (e[0] !== taskDetails.cardCategory) {
                setTaskDetails({
                  ...taskDetails,
                  cardCategory: e[0],
                });
              }
            }}
          />
        </div>
        <div className="form-group">
          <MDBInput
            label="Active"
            type="checkbox"
            id="card-active"
            checked={taskDetails.active}
            onChange={(e) =>
              setTaskDetails({
                ...taskDetails,
                active: e.target.checked,
              })
            }
          />
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={toggleCreateModal}>
          Close
        </MDBBtn>
        <MDBBtn color="primary" type="submit">
          OK
        </MDBBtn>
      </MDBModalFooter>
    </form>
  );
};

export default HomepageCardsCreateModal;
