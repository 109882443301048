import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

import { MDBRow, MDBCol } from "mdbreact";
import RecordsToolbar from "../../components/RecordsToolbar";
import RecordsListing from "../../components/RecordsListing";
import RecordsFilter from "../../components/RecordsFilter";
import IngredientSetDetailsModal from "../../components/IngredientSetDetailModal/IngredientSetDetailsModal";
import LoadingIcon from "../../components/LoadingIcon";

const IngredientSetsListing = props => {
  setPageTitle("Ingredient sets");
  const [shortenedRecords, setShortenedRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [filterQueryString, setFilterQueryString] = useState("");

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [currentIngredientSet, setCurrentIngredientSet] = useState("");

  const [canWrite, setCanWrite] = useState(false);

  let content_config = config[props.content];

  useEffect(() => {
    fetchData(filterQueryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    await API.get(
      config.api_name,
      content_config.listing.api_url,
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        setActiveFilters([]);
        setAvailableFilters(response.filters);
        setShortenedRecords(shortenRecords(response.ingredient_sets));
        setLoading(false);
        setCanWrite(response.can_write);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const refreshData = async queryString => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    await API.get(
      config.api_name,
      content_config.listing.api_url + queryString,
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        setShortenedRecords(shortenRecords(response.ingredient_sets));
        setLoading(false);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const shortenRecords = records => {
    let count = 0;
    let shortenedRecords = [];
    const visible_fields = content_config.listing.visible_fields;
    if (records) {
      records.map(record => {
        shortenedRecords[count] = {};
        visible_fields.forEach(field => {
          shortenedRecords[count][field] = record[field];
        });
        count++;
        return true;
      });
    }
    return shortenedRecords;
  };

  const filterRecords = newFilter => {
    const addFilter = newFilter => {
      let existingFilters = activeFilters;
      if (!existingFilters.length) {
        // There are no existing filters, so create a new one
        // let existingFilters = [];
        existingFilters.push({
          field: newFilter.field,
          values: [newFilter.value]
        });

        setActiveFilters(existingFilters);
      } else {
        // There are already filters, so add a new one
        let existingFilterCount = 0;
        existingFilters.forEach(existingFilter => {
          if (existingFilter.field === newFilter.field) {
            // Already a filter for this field, so add a new acceptable value to this filter
            existingFilters[existingFilterCount].values.push(newFilter.value);
            setActiveFilters(existingFilters);
          } else {
            // No filter for this field, so add a new one
            existingFilters.push({
              field: newFilter.field,
              values: [newFilter.value]
            });
            setActiveFilters(existingFilters);
          }
          existingFilterCount++;
        });
      }
    };
    const removeFilter = newFilter => {
      let currentFilters = activeFilters;
      let currentFiltersCount = 0;
      currentFilters.forEach(filter => {
        if (filter.field === newFilter.field) {
          // Found a matching field, so go through values for that filter and remove the appropriate one
          let currentFiltersValuesCount = 0;
          filter.values.forEach(value => {
            // If it's the last value for this filter, i.e. there are no values left, remove the filter altogether
            if (filter.values.length === 1) {
              currentFilters.splice(currentFiltersCount, 1);
            } else if (value === newFilter.value) {
              currentFilters[currentFiltersCount].values.splice(
                currentFiltersValuesCount,
                1
              );
            }
            currentFiltersValuesCount++;
          });
        }
        currentFiltersCount++;
      });
      setActiveFilters(currentFilters);
    };

    if (newFilter.status === false) {
      removeFilter(newFilter);
    } else {
      addFilter(newFilter);
    }
  };

  const runFilters = () => {
    let queryString = "?";
    activeFilters.forEach(filter => {
      const key = filter.field;
      const values = filter.values;
      let valuesString = "";
      if (values.length > 1) {
        values.forEach(value => {
          valuesString = valuesString + value + ",";
        });
      } else {
        valuesString = values[0];
      }
      queryString = queryString + key + "=" + valuesString;
      queryString = queryString.replace(/,\s*$/, "");
      queryString = queryString + "&";
    });
    queryString = queryString.replace(/&\s*$/, "");
    setFilterQueryString(queryString);
    refreshData(queryString);
  };

  const triggerDetailsModal = async (e, recordId) => {
    const resetDetailsModalContent = () => {
      setCurrentIngredientSet("");
    };
    if (detailModalOpen === true) {
      setDetailModalOpen(false);
      resetDetailsModalContent();
    } else {
      setDetailModalOpen(true);
      e.preventDefault();

      setLoading(true);

      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
          user_name: jwt.accessToken.payload.username
        }
      };
      await API.get(
        config.api_name,
        content_config.listing.api_url + "/" + recordId,
        requestOptions
      ).then(response => {
        if (response.statusCode === 200) {
          setLoading(false);
          response && response.template
            ? setCurrentIngredientSet(response.template)
            : setCurrentIngredientSet({});
        } else {
          alert("Not authorised!");
          window.location.href = "/";
        }
      });
    }
  };

  let recordsListing = "";

  if (loading) {
    recordsListing = <LoadingIcon />;
  } else {
    if (shortenedRecords) {
      recordsListing = (
        <>
          <MDBRow>
            <MDBCol>
              <RecordsToolbar
                refreshRecords={fetchData}
                newRecordUrl={content_config.listing.create_url}
                canWrite={canWrite}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="3">
              <RecordsFilter
                availableFilters={availableFilters}
                filterRecords={filterRecords}
                activeFilters={activeFilters}
                runFilters={runFilters}
              />
            </MDBCol>
            <MDBCol size="9">
              <RecordsListing
                records={shortenedRecords}
                openDetailModal={triggerDetailsModal}
                idField={content_config.listing.id_field}
              />
            </MDBCol>
          </MDBRow>
          <IngredientSetDetailsModal
            detailModalOpen={detailModalOpen}
            triggerDetailsModal={triggerDetailsModal}
            ingredientSet={currentIngredientSet}
            canWrite={canWrite}
          />
        </>
      );
    }
  }

  return (
    <Fragment>
      <MDBRow>
        <MDBCol>
          <h1 className="page-title">{content_config.title}</h1>
        </MDBCol>
      </MDBRow>
      {recordsListing}
    </Fragment>
  );
};

export default withRouter(IngredientSetsListing);
