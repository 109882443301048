import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBTypography } from "mdbreact";
import React, { useState } from "react";

const SchemaPermission = ({ schema, allRoles, permission, setSchema, canEdit }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  let permissionTitle;
  switch (permission) {
    case "schemaView":
      permissionTitle = "Who can view this schema?";
      break;
    case "schemaEdit":
      permissionTitle = "Who can edit this schema?";
      break;
    case "configurationSetsView":
      permissionTitle = "Who can view configuration sets based on this schema?";
      break;
    case "configurationSetsEdit":
      permissionTitle = "Who can edit configuration sets based on this schema?";
      break;

    default:
      break;
  }
  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };
  const onEditPermissionClick = (e) => {
    e.preventDefault();
    setEditModalOpen(true);
  };
  const onPermissionChange = (value, checked) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      if (checked) {
        _schema.permissions[permission].push(value);
      } else {
        _schema.permissions[permission] = _schema.permissions[permission].filter((role) => role !== value);
      }
      return _schema;
    });
  };

  const onRestrictedToggleChange = (checked) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      if (checked) {
        _schema.permissions[permission] = [];
      } else {
        _schema.permissions[permission] = null;
      }
      return _schema;
    });
  };

  return (
    <>
      <h5 className="h6">{permissionTitle}</h5>
      <MDBTypography listInLine>
        {schema.permissions[permission] ? (
          schema.permissions[permission].length ? (
            schema.permissions[permission].map((role) => {
              let returnItem = false;
              allRoles.map((roleData) => {
                if (roleData.uuid === role) {
                  returnItem = (
                    <li className="list-inline-item" key={role}>
                      {roleData.label}
                    </li>
                  );
                }
                return returnItem;
              });
              return returnItem;
            })
          ) : (
            <li className="list-inline-item">Nobody</li>
          )
        ) : (
          <li className="list-inline-item">Anyone</li>
        )}
        {canEdit && (
          <li className="list-inline-item">
            <a href="#!" onClick={(e) => onEditPermissionClick(e)}>
              Edit
            </a>
          </li>
        )}
      </MDBTypography>
      {canEdit && (
        <MDBModal isOpen={editModalOpen} toggle={toggleEditModal}>
          <MDBModalHeader>{permissionTitle}</MDBModalHeader>
          <MDBModalBody>
            <div className="custom-control custom-switch mb-4">
              <input
                type="checkbox"
                className="custom-control-input"
                id={"permission-" + permission + "-restricted"}
                defaultChecked={schema.permissions[permission] !== null}
                onChange={(e) => onRestrictedToggleChange(e.target.checked)}
                readOnly
              />
              <label className="custom-control-label" htmlFor={"permission-" + permission + "-restricted"}>
                Restrict to the following Console roles:
              </label>
            </div>

            {schema.permissions[permission] !== null &&
              allRoles.map((role) => (
                <div className="form-check" key={role.uuid}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={role.uuid}
                    id={role.uuid}
                    onChange={(e) => onPermissionChange(role.uuid, e.target.checked)}
                    defaultChecked={
                      schema.permissions[permission] && schema.permissions[permission].includes(role.uuid)
                    }
                  />
                  <label className="form-check-label" htmlFor={role.uuid}>
                    {role.label}
                  </label>
                </div>
              ))}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={(e) => {
                e.preventDefault();
                toggleEditModal();
              }}
            >
              OK
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      )}
    </>
  );
};

export default SchemaPermission;
