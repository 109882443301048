const config = {
  api_name: "take-1-console",
  service_templates: {
    title: "Service templates",
    listing: {
      api_url: "/service-templates",
      create_url: "/service-templates/new",
      visible_fields: ["referenceName", "serviceTemplateId", "dateCreated", "legacyReference"],
      link_field: "referenceName",
      link_url: "/service-templates",
      id_field: "serviceTemplateId",
    },
    delete: {
      api_url: "/service-templates",
    },
    edit: {
      api_url: "/service-templates",
    },
  },
  capture_instruction_templates: {
    title: "Capture instruction templates",
    listing: {
      api_url: "/capture-instruction-templates",
      create_url: "/capture-instruction-templates/new",
      visible_fields: [
        "captureInstructionTemplateId",
        "originName",
        "configOriginId",
        "ingredientSetName",
        "originJobSkillId",
        "defaultFromLanguageId",
        "defaultToLanguageId",
      ],
      link_field: "originName",
      link_url: "/capture-instruction-templates",
      id_field: "captureInstructionTemplateId",
      filters: [
        {
          name: "Ingredient set",
          field: "ingredientSetId",
        },
      ],
    },
    delete: {
      api_url: "/capture-instruction-templates",
    },
    edit: {
      api_url: "/capture-instruction-templates",
    },
  },
  ingredient_sets: {
    title: "Ingredient sets",
    listing: {
      api_url: "/ingredient-sets",
      create_url: "/ingredient-sets/new",
      visible_fields: [
        "ingredientSetId",
        "displayName",
        "templateStructureSetName",
        "styleSetName",
        "subjectiveInstructionSetName",
      ],
      link_field: "displayName",
      link_url: "/ingredient-sets",
      id_field: "ingredientSetId",
    },
    delete: {
      api_url: "/ingredient-sets",
    },
    edit: {
      api_url: "/ingredient-sets",
    },
  },
  workflows: {
    title: "Workflows",
    listing: {
      api_url: "/workflows",
      create_url: "/workflow-configuration/workflows/new",
      visible_fields: ["workflowId", "title", "status", "owner_fullname"],
      link_field: "title",
      link_url: "/workflow-configuration",
      id_field: "workflowId",
    },
    create: {
      api_url: "/workflows",
    },
    delete: {
      api_url: "/workflows",
    },
    edit: {
      api_url: "/workflows",
    },
  },
  workflow_connections: {
    title: "Connections",
    listing: {
      api_url: "/workflow-connections",
      visible_fields: ["connectionId", "name", "workflowId", "workflowRegistrationTypeId"],
      id_field: "connectionId",
    },
    delete: {
      api_url: "/workflow-connections",
    },
    create: {
      api_url: "/workflow-connections",
    },
    edit: {
      api_url: "/workflow-connections",
    },
  },
  workflow_recipes: {
    title: "Recipes",
    listing: {
      api_url: "/workflow-recipes",
      visible_fields: ["recipeId", "name", "componentId"],
      id_field: "recipeId",
    },
    delete: {
      api_url: "/workflow-recipes",
    },
    create: {
      api_url: "/workflow-recipes",
    },
    edit: {
      api_url: "/workflow-recipes",
    },
  },
  workflow_locations: {
    title: "Locations",
    listing: {
      api_url: "/workflow-locations",
      visible_fields: ["locationId", "name"],
      id_field: "locationId",
    },
    delete: {
      api_url: "/workflow-locations",
    },
    create: {
      api_url: "/workflow-locations",
    },
    edit: {
      api_url: "/workflow-locations",
    },
  },
  workflow_component_prototypes: {
    title: "Component prototypes",
    listing: {
      api_url: "/workflow-configuration/component-prototypes",
      visible_fields: ["componentId", "name"],
      id_field: "componentId",
    },
    delete: {
      api_url: "/workflow-configuration/component-prototypes",
    },
    create: {
      api_url: "/workflow-configuration/component-prototypes",
    },
    edit: {
      api_url: "/workflow-configuration/component-prototypes",
    },
  },
  workflow_components: {
    title: "Components",
    listing: {
      api_url: "/workflow-configuration/components",
      visible_fields: ["componentId", "name"],
      id_field: "componentId",
    },
    delete: {
      api_url: "/workflow-configuration/components",
    },
    create: {
      api_url: "/workflow-configuration/components",
    },
    edit: {
      api_url: "/workflow-configuration/components",
    },
  },
  production_email_tracking: {
    title: "Production email tracking",
    listing: {
      api_url: "/production-email-tracking",
      visible_fields: ["locationId", "name"],
      id_field: "locationId",
    },
    delete: {
      api_url: "/workflow-locations",
    },
    create: {
      api_url: "/workflow-locations",
    },
    edit: {
      api_url: "/workflow-locations",
    },
  },
};

export default config;
