import React from "react";

const AssemblyInstruction = ({ row, showViewButton, workflowViewClick }) => {
  let valueCount = 0;

  const rowValues = Object.values(row).map(value => {
    const valueId = "value-" + valueCount;
    valueCount++;
    return <td key={valueId}>{value}</td>;
  });

  let assemblyInstruction = "";

  showViewButton
    ? (assemblyInstruction = (
        <tr
          className="clickable"
          onClick={e =>
            workflowViewClick("AI", row.serviceTemplateAssemblyInstructionId)
          }
        >
          {rowValues}
        </tr>
      ))
    : (assemblyInstruction = <tr>{rowValues}</tr>);

  return assemblyInstruction;

  // return <tr>{rowValues}</tr>;
};

export default AssemblyInstruction;
