import React, { useEffect } from "react";
import { MDBListGroup, MDBListGroupItem, MDBBtn, MDBInput } from "mdbreact";

const RecordsFilter = props => {
  const availableFilters = props.availableFilters;
  const activeFilters = props.activeFilters;
  const filterRecords = props.filterRecords;
  const runFilters = props.runFilters;

  useEffect(() => {
    if (activeFilters.length) {
      activeFilters.forEach(activeFilter => {
        activeFilter.values.forEach(activeFilterValue => {
          document.getElementById(
            "filter-" + activeFilter.field + "-" + activeFilterValue
          ).checked = true;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterChange = e => {
    const filter = {
      field: e.target.name,
      value: e.target.value,
      status: e.target.checked
    };
    filterRecords(filter);
  };

  return (
    <MDBListGroup>
      {availableFilters.length > 0
        ? availableFilters.map(filter => {
            return (
              <MDBListGroupItem hover key={filter.field}>
                <h5>{filter.label}</h5>
                {filter.values.map(value => {
                  const checkboxId =
                    "filter-" + filter.field + "-" + Object.values(value)[0];
                  return (
                    <MDBInput
                      key={checkboxId}
                      type="checkbox"
                      value={Object.values(value)[0]}
                      label={Object.values(value)[1]}
                      id={checkboxId}
                      name={filter.field}
                      onChange={e => onFilterChange(e)}
                    />
                  );
                })}
              </MDBListGroupItem>
            );
          })
        : ""}
      <MDBListGroupItem>
        <MDBBtn onClick={runFilters}>OK</MDBBtn>
      </MDBListGroupItem>
    </MDBListGroup>
  );
};

export default RecordsFilter;
