import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBBtn,
  MDBRow,
  MDBCol
} from "mdbreact";

export const NewWorkflowItemModal = ({
  newWorkflowItemModalOpen,
  newWorkflowItemModalToggle,
  newWorkflowItemModalFields,
  newWorkflowItemModalType,
  fetchData
}) => {
  const [fieldObjects, setFieldObjects] = useState([]);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    buildFields();
    // eslint-disable-next-line
  }, []);

  const handleFormChange = (fieldName, e) => {
    let newFormValues = Object.assign(formValues, formValues);
    newFormValues[fieldName] = e;
    setFormValues(newFormValues);
  };
  const handleFormSubmit = async e => {
    e.preventDefault();
    formValues.type = newWorkflowItemModalType;

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: formValues
    };
    await API.put("take-1-console", "/workflow-items/", requestOptions).then(
      response => {
        if (response.statusCode === 201) {
          afterSuccessfulSubmit();
        } else {
          alert("Not authorised!");
          window.location.href = "/";
        }
      }
    );
  };
  const afterSuccessfulSubmit = () => {
    fetchData();
  };

  const buildFields = () => {
    let workflowFormItems = [];
    // eslint-disable-next-line
    for (const field in newWorkflowItemModalFields) {
      if (newWorkflowItemModalFields.hasOwnProperty(field)) {
        const fieldName = newWorkflowItemModalFields[field];
        let fieldObject = "";
        if (fieldName.values === "") {
          let fieldKey = fieldName.field;
          handleFormChange(fieldKey, fieldName.default);
          fieldObject = (
            <div key={fieldKey}>
              <MDBRow>
                <MDBCol size="12">
                  <MDBInput
                    type="text"
                    id={fieldKey}
                    label={fieldName.label}
                    valueDefault={fieldName.default}
                    onChange={e =>
                      handleFormChange(fieldName.field, e.target.value)
                    }
                    className="input-text"
                  />
                </MDBCol>
              </MDBRow>
            </div>
          );
          workflowFormItems.push(fieldObject);
        } else {
          let values = [];
          let fieldKey = fieldName.field;
          const fieldRequired = fieldName.required;
          const defaultValue = Object.values(fieldName.default)[0];
          handleFormChange(fieldKey, defaultValue);
          fieldName.values.forEach(option => {
            if (Object.values(option)[1] !== null) {
              const value = Object.values(option)[0];
              const text = Object.values(option)[1].toString();
              let newValue = {
                text: text + " (" + value + ")",
                value: value
              };
              if (defaultValue !== "0") {
                if (value === defaultValue) {
                  newValue.checked = true;
                }
              }
              values.push(newValue);
            }
          });
          fieldObject = (
            <div key={fieldKey}>
              <MDBRow>
                <MDBCol size="12">
                  <MDBSelect
                    options={values}
                    label={fieldName.label}
                    className="input-select"
                    getValue={e => handleFormChange(fieldName.field, e[0])}
                    required={fieldRequired}
                  />
                </MDBCol>
              </MDBRow>
            </div>
          );
          workflowFormItems.push(fieldObject);
        }
        setFieldObjects(workflowFormItems);
      }
    }
  };

  let modalContent = "";

  modalContent = (
    <MDBModal
      isOpen={newWorkflowItemModalOpen}
      toggle={newWorkflowItemModalToggle}
      size="lg"
    >
      <MDBModalHeader>New workflow item</MDBModalHeader>
      <form onSubmit={e => handleFormSubmit(e)}>
        <MDBModalBody>{fieldObjects}</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={newWorkflowItemModalToggle}>
            Cancel
          </MDBBtn>
          <MDBBtn
            type="submit"
            onClick={e => handleFormSubmit(e)}
            color="primary"
          >
            Save
          </MDBBtn>
        </MDBModalFooter>
      </form>
      {/* {modalContent} */}
    </MDBModal>
  );
  return modalContent;
};
