import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBIcon } from "mdbreact";
import DeleteButton from "../../components/DeleteButton";
import { Auth, API } from "aws-amplify";
import LoadingIcon from "../../components/LoadingIcon";
import IngredientSetDetailsModal from "../../components/IngredientSetDetailModal/IngredientSetDetailsModal";
import { Redirect, Link } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

const Edit = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);

  const [activeRecord, setActiveRecord] = useState({});
  const [formValues, setFormValues] = useState([]);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [currentIngredientSet, setCurrentIngredientSet] = useState("");

  const [
    captureInstructionTemplateId,
    setCaptureInstructionTemplateId
  ] = useState("");
  const [originName, setOriginName] = useState("");
  const [configOriginIds, setConfigOriginIds] = useState([]);
  const [configOriginId, setConfigOriginId] = useState("");
  const [ingredientSetIds, setIngredientSetIds] = useState([]);
  const [ingredientSetId, setIngredientSetId] = useState("");
  const [originJobSkillId, setOriginJobSkillId] = useState("");
  const [defaultFromLanguageIds, setDefaultFromLanguageIds] = useState([]);
  const [defaultFromLanguageId, setDefaultFromLanguageId] = useState("");
  const [defaultToLanguageIds, setDefaultToLanguageIds] = useState([]);
  const [defaultToLanguageId, setDefaultToLanguageId] = useState("");

  const [canWrite, setCanWrite] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const recordId = match.params.id;

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    const response = await API.get(
      config.api_name,
      config.capture_instruction_templates.edit.api_url + "/" + recordId,
      requestOptions
    );
    if (response) {
      if (response.statusCode === 200) {
        setPageTitle(response.template_raw.displayName);
        setActiveRecord(response.template);

        setConfigOriginIds(response.template_form_values.configOriginId);
        setIngredientSetIds(response.template_form_values.ingredientSetId);
        setDefaultFromLanguageIds(
          response.template_form_values.defaultFromLanguageId
        );
        setDefaultToLanguageIds(
          response.template_form_values.defaultToLanguageId
        );

        setCaptureInstructionTemplateId(
          response.template_raw.captureInstructionTemplateId
        );
        setOriginName(response.template_raw.originName);
        setConfigOriginId(response.template_raw.configOriginId);
        setIngredientSetId(response.template_raw.ingredientSetId);
        setOriginJobSkillId(response.template_raw.originJobSkillId);
        setDefaultFromLanguageId(response.template_raw.defaultFromLanguageId);
        setDefaultToLanguageId(response.template_raw.defaultToLanguageId);

        setFormValues(response.template_form_values);
        setLoading(false);

        setCanWrite(response.can_write);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        captureInstructionTemplateId: captureInstructionTemplateId,
        originName: originName,
        configOriginId: configOriginId,
        ingredientSetId: ingredientSetId,
        originJobSkillId: originJobSkillId,
        defaultFromLanguageId: defaultFromLanguageId,
        defaultToLanguageId: defaultToLanguageId
      }
    };
    await API.post(
      "take-1-console",
      "/capture-instruction-templates/" + recordId,
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        afterSuccessfulSubmit();
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const afterSuccessfulSubmit = () => {
    setLoading(false);
    setRedirectToHome(true);
  };

  const triggerDetailsModal = async (e, recordId) => {
    const resetDetailsModalContent = () => {
      setCurrentIngredientSet("");
    };
    if (detailModalOpen === true) {
      setDetailModalOpen(false);
      resetDetailsModalContent();
    } else {
      setDetailModalOpen(true);
      e.preventDefault();

      setLoading(true);

      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken
        }
      };
      await API.get(
        config.api_name,
        "/ingredient-sets/" + recordId,
        requestOptions
      ).then(response => {
        setLoading(false);
        response && response.template
          ? setCurrentIngredientSet(response.template)
          : setCurrentIngredientSet({});
      });
    }
  };

  let editForm = "";

  if (loading === true) {
    editForm = <LoadingIcon />;
  } else {
    if (redirectToHome === true) {
      editForm = <Redirect to="/capture-instruction-templates" />;
    } else {
      if (activeRecord && formValues) {
        editForm = (
          <>
            <MDBRow>
              <MDBCol>
                <h1 className="page-title">Editing {activeRecord.originName}</h1>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <MDBInput
                      type="text"
                      value={originName}
                      id="originName"
                      label="originName"
                      onChange={e => setOriginName(e.target.value)}
                    />
                  </div>

                  {configOriginIds.length ? (
                    <div className="form-group">
                      <label htmlFor="configOriginId">configOriginId</label>
                      <select
                        className="browser-default custom-select"
                        id="configOriginId"
                        value={configOriginId}
                        onChange={e => setConfigOriginId(e.target.value)}
                      >
                        {formValues.configOriginId.map(option => {
                          return (
                            <option
                              value={option.configOriginId}
                              key={option.configOriginId}
                            >
                              {option.originDescription}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {ingredientSetIds.length ? (
                    <div className="form-group">
                      <label htmlFor="ingredientSetId">ingredientSetId</label>
                      <MDBRow>
                        <MDBCol size="11">
                          <select
                            className="browser-default custom-select"
                            id="ingredientSetId"
                            value={ingredientSetId}
                            onChange={e => setIngredientSetId(e.target.value)}
                          >
                            {formValues.ingredientSetId.map(option => {
                              return (
                                <option
                                  value={option.ingredientSetId}
                                  key={option.ingredientSetId}
                                >
                                  {option.displayName}
                                </option>
                              );
                            })}
                          </select>
                        </MDBCol>
                        <MDBCol size="1">
                          <MDBBtn
                            className="m-0"
                            onClick={e =>
                              triggerDetailsModal(e, ingredientSetId)
                            }
                          >
                            <MDBIcon icon="search" />
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ) : (
                    ""
                  )}

                  {originJobSkillId ? (
                    <div className="form-group">
                      <MDBInput
                        type="text"
                        value={originJobSkillId}
                        id="originJobSkillId"
                        label="originJobSkillId"
                        onChange={e => setOriginJobSkillId(e.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {defaultFromLanguageIds.length ? (
                    <div className="form-group">
                      <label htmlFor="defaultFromLanguageId">
                        defaultFromLanguageId
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="defaultFromLanguageId"
                        value={defaultFromLanguageId}
                        onChange={e => setDefaultFromLanguageId(e.target.value)}
                      >
                        {formValues.defaultFromLanguageId.map(option => {
                          return (
                            <option
                              value={option.configLanguageId}
                              key={option.configLanguageId}
                            >
                              {option.languageDescription}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {defaultToLanguageIds.length ? (
                    <div className="form-group">
                      <label htmlFor="defaultToLanguageId">
                        defaultToLanguageId
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="defaultToLanguageId"
                        value={defaultToLanguageId}
                        onChange={e => defaultToLanguageId(e.target.value)}
                      >
                        {formValues.defaultToLanguageId.map(option => {
                          return (
                            <option
                              value={option.configLanguageId}
                              key={option.configLanguageId}
                            >
                              {option.languageDescription}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  <MDBBtn tag={Link} to="/capture-instruction-templates">
                    Back
                  </MDBBtn>
                  {canWrite ? (
                    <>
                      <DeleteButton
                        content_type="capture_instruction_templates"
                        id={recordId}
                        title={activeRecord.originName}
                        setRedirectToHome={setRedirectToHome}
                      />
                      <MDBBtn
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Submit
                      </MDBBtn>
                    </>
                  ) : (
                    ""
                  )}
                </form>
              </MDBCol>
            </MDBRow>
            <IngredientSetDetailsModal
              detailModalOpen={detailModalOpen}
              triggerDetailsModal={triggerDetailsModal}
              ingredientSet={currentIngredientSet}
            />
          </>
        );
      }
    }
  }
  return <>{editForm}</>;
};

export default Edit;
