import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from "mdbreact";
import DeleteButton from "../../components/DeleteButton";
import { Auth, API } from "aws-amplify";
import LoadingIcon from "../../components/LoadingIcon";
import { Redirect, Link } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

import Merging from "../../components/ServiceTemplateDetailModal/Merging";
import Assembly from "../../components/ServiceTemplateDetailModal/Assembly";
import Despatch from "../../components/ServiceTemplateDetailModal/Despatch";

import WorkflowItemModal from "../../components/WorkflowItemModal";

import { NewWorkflowItemModal } from "../../components/NewWorkflowItemModal";

const Edit = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [activeRecord, setActiveRecord] = useState({});
  const [redirectToHome, setRedirectToHome] = useState(false);

  const [serviceTemplateId, setServiceTemplateId] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const [legacyReference, setlegacyReference] = useState("");
  const [take1Featured, setTake1Featured] = useState("");
  const [restrictedToCustomerId, setRestrictedToCustomerId] = useState("");
  const [ingredientSetId, setIngredientSetId] = useState("");
  const [deprecated, setDeprecated] = useState("");
  const [validationDataProcessSetId, setValidationDataProcessSetId] = useState(
    ""
  );
  const [status, setStatus] = useState("");
  const [dateCreated, setDateCreated] = useState("");

  const [ingredientSets, setIngredientSets] = useState([]);

  const [modalWorkflowItem, setModalWorkflowItem] = useState(null);
  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);

  const [canWrite, setCanWrite] = useState(false);

  const [newWorkflowItemModalOpen, setNewWorkflowItemModalOpen] = useState(
    false
  );
  const [newWorkflowItemModalFields, setnewWorkflowItemModalFields] = useState(
    []
  );
  const [newWorkflowItemModalType, setNewWorkflowItemModalType] = useState("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const recordId = match.params.id;

  const fetchData = async () => {
    setLoading(true);
    setNewWorkflowItemModalOpen(false);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    const response = await API.get(
      config.api_name,
      config.service_templates.edit.api_url + "/" + recordId,
      requestOptions
    );
    if (response) {
      if (response.statusCode === 403) {
        alert("Not authorised!");
        window.location.href = "/";
      } else {
        setActiveRecord(response.service_template);

        setServiceTemplateId(response.service_template.serviceTemplateId);
        setDateCreated(response.service_template.dateCreated);
        setTake1Featured(response.service_template.take1Featured);
        setReferenceName(response.service_template.referenceName);
        setlegacyReference(response.service_template.legacyReference);
        setRestrictedToCustomerId(
          response.service_template.restrictedToCustomerId
        );
        setIngredientSetId(response.service_template.ingredientSetId);
        setDeprecated(response.service_template.deprecated);
        setValidationDataProcessSetId(
          response.service_template.validationDataProcessSetId
        );
        setStatus(response.service_template.status);

        setCanWrite(response.can_write);

        setIngredientSets(response.ingredient_sets);

        setPageTitle(response.service_template.referenceName);

        setLoading(false);
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        serviceTemplateId: serviceTemplateId,
        take1Featured: take1Featured,
        dateCreated: dateCreated,
        referenceName: referenceName,
        legacyReference: legacyReference,
        restrictedToCustomerId: restrictedToCustomerId,
        ingredientSetId: ingredientSetId,
        deprecated: deprecated,
        validationDataProcessSetId: validationDataProcessSetId,
        status: status
      }
    };
    await API.post(
      "take-1-console",
      "/service-templates/",
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        afterSuccessfulSubmit();
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const afterSuccessfulSubmit = () => {
    setLoading(false);
    setRedirectToHome(true);
  };

  const workflowViewClick = async (type, id) => {
    setLoading(true);
    let title = "";
    switch (type) {
      case "MI":
        title = "Merging instruction " + id;
        break;
      case "FI":
        title = "Filtering instruction " + id;
        break;
      case "CI":
        title = "Compiling instruction " + id;
        break;
      case "AI":
        title = "Assembly instruction " + id;
        break;
      default:
        title = "Instruction " + id;
    }
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken
      }
    };
    let queryString = "/workflow-items?type=" + type + "&id=" + id;
    if (type === "AI") {
      queryString += "&full=1";
    }
    const response = await API.get(
      config.api_name,
      queryString,
      requestOptions
    );
    response.type = type;
    response.id = id;
    response.title = title;
    workflowModalToggle();
    setModalWorkflowItem(response);
    setLoading(false);
  };

  const workflowModalToggle = () => {
    setWorkflowModalOpen(!workflowModalOpen);
  };

  const newWorkflowClick = async (type, parentId, serviceTemplateId) => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        type: type,
        parentId: parentId,
        serviceTemplateId: serviceTemplateId
      }
    };
    await API.put(config.api_name, "/workflow-items", requestOptions);

    fetchData();
  };

  const newWorkflowItemModalToggle = () => {
    setNewWorkflowItemModalOpen(!newWorkflowItemModalOpen);
  };
  const newWorkflowItemClick = async (e, type) => {
    e.preventDefault();
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    let queryString =
      "/workflow-items/fields?type=" +
      type +
      "&service_template_id=" +
      serviceTemplateId;
    await API.get(config.api_name, queryString, requestOptions).then(
      response => {
        if (response.statusCode === 200) {
          setnewWorkflowItemModalFields(response.form_values);
          setNewWorkflowItemModalType(type);
          setLoading(false);
        }
      }
    );

    // setnewWorkflowItemModalType(type);
    newWorkflowItemModalToggle();
  };

  let editForm = "";

  if (loading) {
    editForm = <LoadingIcon />;
  } else {
    if (redirectToHome === true) {
      editForm = <Redirect to="/service-templates" />;
    } else {
      if (activeRecord) {
        let workflowDetailsContent = [];
        const mergingInstructions = activeRecord.merging_instructions;
        const despatchInstructions = activeRecord.despatch_instructions;
        const despatchInstructionsFriendly =
          activeRecord.despatch_instructions_friendly;
        workflowDetailsContent.push(
          <h3 key="merging-instructions-title">Merging instructions</h3>
        );
        if (mergingInstructions) {
          workflowDetailsContent.push(
            <Merging
              key="merging-instructions"
              mergingInstructions={mergingInstructions}
              showViewButton={canWrite}
              workflowViewClick={workflowViewClick}
              newWorkflowClick={newWorkflowClick}
              canWrite={canWrite}
              showAddButton={true}
              newWorkflowItemClick={newWorkflowItemClick}
            />
          );
        }
        if (canWrite === true) {
          workflowDetailsContent.push(
            <div className="new-workflow-item" key="new-merging">
              <MDBBtn
                color="primary"
                onClick={e =>
                  newWorkflowClick("MI", serviceTemplateId, serviceTemplateId)
                }
              >
                <MDBIcon icon="plus-circle" className="mr-2" /> Add new merging
                instruction
              </MDBBtn>
            </div>
          );
        }
        if (activeRecord.assembly_instructions) {
          workflowDetailsContent.push(
            <Assembly
              key="assembly-instructions"
              assemblyInstructions={activeRecord.assembly_instructions}
              showViewButton={true}
              workflowViewClick={workflowViewClick}
              canWrite={canWrite}
            />
          );
        }

        if (despatchInstructions && mergingInstructions) {
          workflowDetailsContent.push(
            <h3 key="despatch-instructions-title" className="mt-2">
              Despatch instructions
            </h3>
          );
          workflowDetailsContent.push(
            <Despatch
              key="despatch-instructions"
              despatchInstructions={despatchInstructions}
              despatchInstructionsFriendly={despatchInstructionsFriendly}
              showViewButton={true}
              workflowViewClick={workflowViewClick}
              canWrite={canWrite}
            />
          );
          if (canWrite === true) {
            workflowDetailsContent.push(
              <div className="new-workflow-item" key="new-despatch">
                <MDBBtn
                  color="primary"
                  onClick={e => newWorkflowItemClick(e, "DI")}
                >
                  <MDBIcon icon="plus-circle" className="mr-2" /> Add new
                  despatch instruction
                </MDBBtn>
              </div>
            );
          }
        }

        editForm = (
          <>
            <MDBRow>
              <MDBCol>
                <h1 className="page-title">Editing {activeRecord.referenceName}</h1>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <MDBInput
                      label="Reference name"
                      type="text"
                      value={referenceName}
                      onChange={e => setReferenceName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <MDBInput
                      label="Legacy reference name"
                      type="text"
                      value={legacyReference}
                      onChange={e => setlegacyReference(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="take1Featured">Take 1 featured</label>
                    <select
                      className="browser-default custom-select"
                      value={take1Featured}
                      onChange={e => setTake1Featured(e.target.value)}
                      id="take1Featured"
                    >
                      <option value="0">Not featured</option>
                      <option value="1">Featured</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="restrictedToCustomerId">
                      Restricted to customer
                    </label>
                    <select
                      className="browser-default custom-select"
                      value={restrictedToCustomerId}
                      onChange={e => setRestrictedToCustomerId(e.target.value)}
                      id="restrictedToCustomerId"
                    >
                      <option value="0">Not restricted</option>
                      <option value="1">Restricted</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="ingredientSetId">Ingredient set</label>
                    <select
                      className="browser-default custom-select"
                      value={ingredientSetId}
                      onChange={e => setIngredientSetId(e.target.value)}
                      id="ingredientSetId"
                    >
                      {ingredientSets.map(ingredientSet => {
                        return (
                          <option
                            key={ingredientSet.ingredientSetId}
                            value={ingredientSet.ingredientSetId}
                          >
                            {ingredientSet.displayName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="deprecated">Deprecated</label>
                    <select
                      className="browser-default custom-select"
                      value={deprecated}
                      onChange={e => setDeprecated(e.target.value)}
                      id="deprecated"
                    >
                      <option value="0">Not deprecated</option>
                      <option value="1">Deprecated</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="validationDataProcessSetId">
                      Validation data process set ID
                    </label>
                    <select
                      className="browser-default custom-select"
                      value={validationDataProcessSetId}
                      onChange={e =>
                        setValidationDataProcessSetId(e.target.value)
                      }
                      id="validationDataProcessSetId"
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      className="browser-default custom-select"
                      value={status}
                      onChange={e => setStatus(e.target.value)}
                      id="status"
                    >
                      <option value="0">Inactive</option>
                      <option value="1">Active</option>
                    </select>
                  </div>

                  <MDBCard>
                    <MDBCardBody>
                      <MDBCardTitle tag="h2">Workflow</MDBCardTitle>
                      <MDBCardText tag="div">
                        {workflowDetailsContent}
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>

                  <MDBBtn tag={Link} to="/service-templates">
                    Back
                  </MDBBtn>
                  {canWrite ? (
                    <>
                      <DeleteButton
                        content_type="service_templates"
                        id={serviceTemplateId}
                        title={referenceName}
                        setRedirectToHome={setRedirectToHome}
                      />
                      <MDBBtn
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Submit
                      </MDBBtn>
                    </>
                  ) : (
                    ""
                  )}
                </form>
              </MDBCol>
            </MDBRow>
            {canWrite ? (
              <>
                <WorkflowItemModal
                  workflowModalOpen={workflowModalOpen}
                  workflowModalToggle={workflowModalToggle}
                  workflowItem={modalWorkflowItem}
                  fetchData={fetchData}
                />
                <NewWorkflowItemModal
                  newWorkflowItemModalOpen={newWorkflowItemModalOpen}
                  newWorkflowItemModalToggle={newWorkflowItemModalToggle}
                  newWorkflowItemModalFields={newWorkflowItemModalFields}
                  newWorkflowItemModalType={newWorkflowItemModalType}
                  fetchData={fetchData}
                />
              </>
            ) : (
              ""
            )}
          </>
        );
      }
    }
  }
  return <>{editForm}</>;
};

export default Edit;
