import React, { useState } from "react";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput, MDBAlert } from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";

export const NewUserModal = ({ setIsLoading, getUsers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newUserData, setNewUserData] = useState({});
  const [newUserFormFields, setNewUserFormFields] = useState([]);

  const buildForm = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/users/fields", "get")
      .then((response) => {
        let newNewUserFormFields = [];
        response.form_values.forEach((field) => {
          if (field.htmlElement === "text") {
            newNewUserFormFields.push(
              <MDBInput
                label={field.label}
                type="text"
                required={field.required}
                valueDefault={field.default}
                onChange={(e) => onFormChange(e)}
                key={field.name}
                name={field.name}
              />
            );
          }
          if (field.htmlElement === "email") {
            newNewUserFormFields.push(
              <MDBInput
                label={field.label}
                type="email"
                required={field.required}
                valueDefault={field.default}
                onChange={(e) => onFormChange(e)}
                key={field.name}
                name={field.name}
              />
            );
          }
          if (field.htmlElement === "password") {
            newNewUserFormFields.push(
              <MDBInput
                label={field.label}
                type="password"
                required={field.required}
                valueDefault={field.default}
                onChange={(e) => onFormChange(e)}
                key={field.name}
                name={field.name}
              />
            );
            newNewUserFormFields.push(
              <p key="password-description">
                Password must be at least six characters and include at least one uppercase letter, a number, and a
                symbol.
              </p>
            );
          }
          if (field.htmlElement === "checkbox") {
            newNewUserFormFields.push(<legend key="new-user-roles-title">Roles</legend>);
            field.data.forEach((role) => {
              const roleKey = "role-" + role.uuid;
              newNewUserFormFields.push(
                <div key={roleKey}>
                  <MDBInput
                    label={role.label}
                    type="checkbox"
                    id={role.name}
                    name={field.name}
                    value={role.uuid}
                    onChange={(e) => onFormChange(e)}
                  />
                </div>
              );
            });
          }
        });
        setNewUserFormFields(newNewUserFormFields);
        setIsLoading(false);
        setIsOpen(true);
      })
      .catch(() => {
        setIsLoading(false);
        setIsOpen(false);
        showNotification("Access denied", "You do not have permission to create users.", "warning");
      });
  };

  const toggle = () => {
    if (!isOpen) {
      buildForm();
    } else {
      setIsOpen(false);
    }
  };

  const onFormChange = (e) => {
    const field_name = e.target.name;
    const field_value = e.target.value;
    const field_status = e.target.checked;

    field_name === "roles"
      ? setNewUserData((oldUserData) => {
          if (oldUserData.roles === undefined) {
            oldUserData.roles = [];
          }
          if (field_status === true) {
            oldUserData.roles.push(field_value);
          } else {
            oldUserData.roles = oldUserData.roles.filter((roleName) => roleName !== field_value);
          }
          return oldUserData;
        })
      : setNewUserData((oldUserData) => {
          oldUserData[field_name] = field_value;
          return oldUserData;
        });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await apiGatewayCall(config.api_name, "/user-management/users", "put", { body: newUserData })
      .then((response) => {
        if (response.statusCode === 201) {
          onSuccess(newUserData.email);
        } else {
          onFailure(response);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toggle();
        showNotification("Access denied", "You do not have permission to create users.", "warning");
      });
  };

  const onSuccess = (email) => {
    showNotification("User created", email + " was successfully created.", "success");
    toggle();
    getUsers();
  };

  const onFailure = (response) => {
    if (response.error === "UsernameNotValid") {
      showNotification("Error", "That didn't work. Try again with a valid email address.", "danger");
    } else if (response.error === "UsernameExistsException") {
      showNotification(
        "Error",
        "That didn't work. This email address is already assigned to an existing user.",
        "danger"
      );
    } else if (response.error === "InvalidPasswordException" || response.error === "UsernameOrPasswordInvalid") {
      showNotification(
        "Error",
        "That didn't work. Make sure the password you're setting has at least six characters long and contains at least one upper case letter, a number, and a special character.",
        "danger"
      );
    }
  };

  return (
    <>
      <MDBAlert className="d-none message-success" color="success">
        <p>User has been created. An email has been sent to them with their password.</p>
        <p>They will be instructed to set their own password the first time they log in.</p>
        <p>They will be unable to use the Take 1 Console until they have set their own password.</p>
      </MDBAlert>
      <MDBBtn onClick={toggle}>New user</MDBBtn>
      <MDBModal isOpen={isOpen} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>New user</MDBModalHeader>
        <form id="new-user-form" onSubmit={(e) => onSubmit(e)}>
          <MDBModalBody>{newUserFormFields}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggle}>
              Close
            </MDBBtn>
            <MDBBtn color="default" type="submit" onClick={(e) => onSubmit(e)}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </>
  );
};
