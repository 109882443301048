import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import setPageTitle from "../../helpers/setPageTitle";
import config from "../../content_types";
import { MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBDataTable, MDBRow, MDBCol } from "mdbreact";
import showNotification from "../../helpers/showNotification";
import apiGatewayCall from "../../helpers/apiGatewayCall";

const WorkflowRecipesEdit = ({ content_type, match, setLoading }) => {
  const recordId = match.params.id;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [redirectToHome, setRedirectToHome] = useState(false);

  const [recipe, setRecipe] = useState(null);

  const [canEdit, setCanEdit] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/recipes/" + recordId, "get")
      .then((response) => {
        setRecipe(response.recipe);
        setPageTitle("Editing " + response.recipe.name);
        setCanEdit(response.can_edit);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to view recipes.", "warning");
      });
  };
  const validateForm = () => {
    let requiredFields = [
      {
        name: "name",
        label: "Name",
      },
    ];
    let invalidFields = [];
    requiredFields.forEach((requiredField) => {
      if (recipe[requiredField.name] === "") {
        invalidFields.push(requiredField.label);
      }
    });

    if (invalidFields.length === 0) {
      return true;
    } else {
      let invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " + invalidFieldsString,
        "warning"
      );
    }
  };

  const handleFormChange = (fieldName, e) => {
    setRecipe((oldRecipe) => {
      if (e === true) {
        e = 1;
      } else if (e === false) {
        e = 0;
      }
      oldRecipe[fieldName] = e;
      return oldRecipe;
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      let options = {
        body: recipe,
      };
      await apiGatewayCall(config.api_name, "/workflow-management/recipes/" + recipe.recipeId, "post", options)
        .then((response) => {
          showNotification("Success", recipe.name + " was successfully updated", "success");
          fetchData();
        })
        .catch(() => {
          showNotification("Access denied", "You do not have permission to edit recipes.", "warning");
        });
    }
  };

  const saveNewMetadata = (e) => {
    e.preventDefault();
    const newMetadataName = document.getElementById("new-metadata-name").value;
    const newMetadataValue = document.getElementById("new-metadata-value").value;
    if (newMetadataName !== "") {
      let newRecipe = Object.assign({}, recipe);
      newRecipe.metadata[newMetadataName] = { value: newMetadataValue };
      setRecipe(newRecipe);
      document.getElementById("new-metadata-name").value = "";
      document.getElementById("new-metadata-value").value = "";
    }
  };

  const deleteMetadata = (metadataName) => {
    let newRecipe = Object.assign({}, recipe);
    delete newRecipe.metadata[metadataName];
    setRecipe(newRecipe);
  };

  let recipeMetadata;

  let formContent = [];
  if (recipe) {
    if (recipe.metadata) {
      let recipeMetadataData = {
        columns: [
          { label: "Name", field: "name" },
          { label: "Value", field: "value" },
          { label: "", field: "actions" },
        ],
        rows: [],
      };
      Object.keys(recipe.metadata).forEach((metadataName) => {
        recipeMetadataData.rows.push({
          name: metadataName,
          value: recipe.metadata[metadataName].value,
          actions: (
            <MDBIcon icon="times" className="clickable float-right" onClick={(e) => deleteMetadata(metadataName)} />
          ),
        });
      });
      recipeMetadata = (
        <MDBDataTable
          data={recipeMetadataData}
          displayEntries={false}
          info={false}
          noBottomColumns={true}
          paging={false}
          searching={false}
          sortable={false}
          noRecordsFoundLabel="Please add at least one metadata item"
          hover
        />
      );
    }
    formContent.push(
      <MDBInput label="Name" valueDefault={recipe.name} getValue={(e) => handleFormChange("name", e)} />
    );
    formContent.push(
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Metadata</MDBCardTitle>
          <p>Metadata from the selected component is shown below. Overwrite using the form below.</p>
          <form onSubmit={(e) => saveNewMetadata(e)}>
            <MDBRow>
              <MDBCol size="4">
                <MDBInput label="Metadata name" id="new-metadata-name" valueDefault="" />
              </MDBCol>
              <MDBCol size="5">
                <MDBInput label="Metadata value" id="new-metadata-value" valueDefault="" />
              </MDBCol>
              <MDBCol size="3">
                <MDBBtn type="submit" onClick={(e) => saveNewMetadata(e)} className="mb-0">
                  Set
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
          {recipeMetadata}
        </MDBCardBody>
      </MDBCard>
    );
  }

  let pageContent;
  if (redirectToHome) {
    pageContent = <Redirect to="/workflow-configuration/recipes" />;
  } else {
    if (recipe) {
      pageContent = (
        <>
          <h1 className="page-title">{recipe.name}</h1>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            {formContent}
            <MDBBtn color="secondary" onClick={(e) => setRedirectToHome(true)}>
              Back
            </MDBBtn>
            {canEdit === true ? (
              <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} color="primary">
                Save
              </MDBBtn>
            ) : null}
          </form>
        </>
      );
    } else {
      pageContent = <p></p>;
    }
  }

  return pageContent;
};

export default withRouter(WorkflowRecipesEdit);
