import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import React from 'react';
import Select from 'react-select';
import Birthday from './Birthday';

const PersonalInformation = ({ userData, updateUserData, timezones, languages }) => {
  let defaultTimezone = null;
  if (timezones) {
    timezones.forEach((timezone) => {
      if (timezone.label.includes('London')) defaultTimezone = timezone.value;
    });
  }

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag='h2' className='h4'>
          Personal information
        </MDBCardTitle>
        <div className='form-group'>
          <label htmlFor='fullName'>
            Full name <span className='red-text'>*</span>
          </label>
          <input
            type='text'
            className='form-control'
            id='fullName'
            onChange={(e) => updateUserData(e)}
            defaultValue={userData.fullName}
            required
            maxLength='29'
          />
          <div className='invalid-feedback'>Please enter your full name.</div>
        </div>
        <div className='form-group'>
          <label htmlFor='nickname' className='mb-0'>
            Nickname
          </label>
          <p className='mb-1'>
            <small>If you would like to be addressed by a specific name, enter it here.</small>
          </p>
          <input
            type='text'
            className='form-control'
            id='nickname'
            onChange={(e) => updateUserData(e)}
            defaultValue={userData.nickname}
          />
        </div>
        {timezones && (
          <div className='form-group'>
            <label htmlFor='timezone'>
              Time zone <span className='red-text'>*</span>
            </label>
            <Select
              required
              options={timezones}
              id='timezone'
              isSearchable={true}
              value={
                'timezone' in userData
                  ? timezones.filter((timezone) => timezone.value === userData.timezone)
                  : defaultTimezone
              }
              onChange={(value) => {
                updateUserData({ target: { id: 'timezone', value: value.value } });
              }}
            />
          </div>
        )}
        {languages && (
          <div className='form-group'>
            <label htmlFor='motherTongue'>Mother tongue language</label>
            <Select
              options={languages}
              id='motherTongue'
              isSearchable={true}
              value={
                'motherTongue' in userData &&
                languages.filter((language) => language.value === parseInt(userData.motherTongue))
              }
              onChange={(value) => {
                updateUserData({ target: { id: 'motherTongue', value: value.value } });
              }}
            />
          </div>
        )}
        <Birthday userData={userData} updateUserData={updateUserData} />
      </MDBCardBody>
    </MDBCard>
  );
};

export default PersonalInformation;
