import { MDBCard, MDBCardBody, MDBInput } from "mdbreact";
import moment from "moment";
import React from "react";

const ConfigurationSetMoreInformation = ({ configurationSet, userFullName }) => {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        {userFullName && <MDBInput disabled value={userFullName} label="Schema owner" />}
        <MDBInput
          disabled
          value={moment(configurationSet.createDateTime).format("YYYY-MM-DD HH:mm:ss")}
          label="Created"
        />
        {"modifiedDateTime" in configurationSet && (
          <MDBInput
            disabled
            value={moment(configurationSet.modifiedDateTime).format("YYYY-MM-DD HH:mm:ss")}
            label="Last modified"
          />
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetMoreInformation;
