/*
ExternalConfigurationSet component generates a field that import data from a field in another Configuration Set.

You pass it the field data, the function to update the field values, the original Configuration Set, an array of existing Configuration Sets, and the "canEdit" property.

The field it generates looks like this:

field_slug (i.e. the name of the field we're building) : {
  externalConfigurationSet: [uuid of the source Configuration Set],
  externalConfigurationSetField: [slug of field within that Configuration Set you want to use in this Configuration Set]
}
*/

import React, { useState, useEffect } from "react";
import LoadingIcon from "../../components/LoadingIcon";
import config from "../../content_types";
import apiGatewayCall from "../../helpers/apiGatewayCall";

const ExternalConfigurationSet = ({
  field,
  updateConfigurationSetValues,
  configurationSet,
  configurationSets,
  canEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [externalConfigurationSet, setExternalConfigurationSet] = useState(null);
  const [externalConfigurationSetFields, setExternalConfigurationSetFields] = useState(null);

  useEffect(() => {
    // If this is being used as part of an existing Configuration Set, pop the ID of the Configuration Set in the externalConfigurationSet state object call the getConfigurationSet() function with the ID of the external Configuration Set
    async function fetchData() {
      setExternalConfigurationSet(configurationSet.values[field.inputInstance.slug].externalConfigurationSet);
      await getConfigurationSet(configurationSet.values[field.inputInstance.slug].externalConfigurationSet);
    }
    if (configurationSet.values[field.inputInstance.slug]) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  // Fired when the "select Configuration Set" dropdown is changed. If it's not 0, i.e. if the user has actually selected a Configuration Set, make an API call to get that Set
  const getConfigurationSet = async (externalConfigurationSetId) => {
    if (externalConfigurationSetId !== "0") {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/configuration-sets/" + externalConfigurationSetId, "get").then(
        (response) => {
          setLoading(false);

          // Pass all the fields contained in the selected Configuration Set into the externalConfigurationSetFields state object
          setExternalConfigurationSetFields(() => {
            let _fields = [];
            response.configurationSet.schema.inputs.forEach((externalConfigurationSetInput) => {
              _fields.push(externalConfigurationSetInput);
            });
            return _fields;
          });
        }
      );
    }
  };

  // Fired when the user selects a source Configuration Set. Sets the externalConfigurationSet state object and fetches its data from the API
  const onExternalConfigurationSetChange = (selectedExternalConfigurationSet) => {
    if (selectedExternalConfigurationSet === "0") {
      updateConfigurationSetValues(field, "");
      setExternalConfigurationSet(null);
      setExternalConfigurationSetFields(null);
    } else {
      updateConfigurationSetValues(field, "");
      setExternalConfigurationSet(selectedExternalConfigurationSet);
      setExternalConfigurationSetFields(null);
      getConfigurationSet(selectedExternalConfigurationSet);
    }
  };

  // Fired when the user selects a field within the configuration set. Updates our new configuration set values to include the externalConfigurationSet ID and the externalConfigurationSetField slug
  const onExternalConfigurationSetFieldChange = (externalConfigurationSetField) => {
    if (externalConfigurationSetField !== "0") {
      updateConfigurationSetValues(field, {
        externalConfigurationSet: externalConfigurationSet,
        externalConfigurationSetField: externalConfigurationSetField,
      });
    }
  };

  let defaultParentValue = "0";
  if (configurationSet.values[field.inputInstance.slug].externalConfigurationSet !== undefined)
    defaultParentValue = configurationSet.values[field.inputInstance.slug].externalConfigurationSet;

  return (
    <div className="configuration-set-external-configuration-set-container">
      {loading && <LoadingIcon fullHeight={false} />}
      <div className="form-group">
        <label htmlFor={field.inputInstance.slug + "-configuration-set"}>{field.inputInstance.label}</label>
        <select
          className="form-control"
          id={field.inputInstance.slug + "-configuration-set"}
          onChange={(e) => onExternalConfigurationSetChange(e.target.value)}
          defaultValue={defaultParentValue}
          disabled={!canEdit}
        >
          <option value="0">Use default settings</option>
          {configurationSets.length ? (
            // We have an array of configurationSets, so map over it. If the schema for the configuration set in that array matches the field's parent schema, pop it into this select dropdown.
            configurationSets.map((existingConfigurationSet) => {
              let configurationSetOption;
              if (
                existingConfigurationSet.uuid !== configurationSet.uuid &&
                existingConfigurationSet.schema.uuid === field.inputInstance.parentSchema
              ) {
                configurationSetOption = (
                  <option key={existingConfigurationSet.uuid} value={existingConfigurationSet.uuid}>
                    {existingConfigurationSet.label}
                  </option>
                );
              }

              return configurationSetOption;
            })
          ) : (
            // No configuration sets using the field's parent schema? Never mind.
            <option disabled>No configuration sets available</option>
          )}
        </select>
        {externalConfigurationSet && (
          <p>
            <small>
              <a href={"/configuration-sets/" + externalConfigurationSet} target="_blank" rel="noreferrer">
                View
              </a>
            </small>
          </p>
        )}
      </div>
      {externalConfigurationSetFields && externalConfigurationSetFields.length && (
        // This is a second field that displays if we have a configuration set selected, and that configuration set includes some fields we can choose from.

        <div className="form-group pl-4">
          <label htmlFor={field.inputInstance.slug + "-field"}>Field to use in {field.inputInstance.label}</label>
          <select
            id={field.inputInstance.slug + "-field"}
            className="form-control"
            onChange={(e) => onExternalConfigurationSetFieldChange(e.target.value)}
            // If we already have a value (i.e. we're editing an existing configuration set), make it the default value of the dropdown
            value={
              configurationSet.values[field.inputInstance.slug] !== undefined &&
              configurationSet.values[field.inputInstance.slug] !== ""
                ? configurationSet.values[field.inputInstance.slug].externalConfigurationSetField
                : "0"
            }
          >
            <option value="0">Select field</option>
            {externalConfigurationSetFields.map((externalConfigurationSetField) => {
              return (
                <option
                  key={externalConfigurationSetField.inputInstance.id}
                  value={externalConfigurationSetField.inputInstance.slug}
                >
                  {externalConfigurationSetField.inputInstance.slug}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </div>
  );
};

export default ExternalConfigurationSet;
