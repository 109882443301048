import React, { useState, useEffect } from "react";
import { MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import { withRouter } from "react-router-dom";

const WorkFlowManagementNav = ({ page }) => {
  const [currentPage, setCurrentPage] = useState(page);
  useEffect(() => {
    buildNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildNav = () => {
    const links = [
      {
        text: "Connections",
        keys: ["workflow-configuration-connections-listing", "workflow-configuration-connections-edit"],
        url: "/workflow-configuration/connections/",
      },
      {
        text: "Workflows",
        url: "/workflow-configuration/workflows/",
        keys: [
          "workflow-configuration-workflow-listing",
          "workflow-configuration-workflow-new",
          "workflow-configuration-workflow-edit",
        ],
      },
      {
        text: "",
        url: "",
        keys: ["workflow-configuration-divider"],
      },
      {
        text: "Component prototypes",
        keys: ["workflow-configuration-component-prototypes"],
        url: "/workflow-configuration/component-prototypes/",
      },
      {
        text: "Components",
        keys: ["workflow-configuration-components"],
        url: "/workflow-configuration/components/",
      },
      {
        text: "Recipes",
        keys: ["workflow-configuration-recipes", "workflow-configuration-recipes-edit"],
        url: "/workflow-configuration/recipes/",
      },
      {
        text: "Locations",
        keys: ["workflow-configuration-locations", "workflow-configuration-locations-edit"],
        url: "/workflow-configuration/locations/",
      },
    ];

    let linksContent = [];
    links.forEach((link) => {
      let linkContent = null;
      if (link.text === "") {
        linkContent = (
          <MDBNavItem key={link.keys[0]} className="nav-divider" />
        );
      } else {
        if (link.keys.includes(currentPage)) {
          linkContent = (
            <MDBNavItem key={link.keys[0]} active>
              <MDBNavLink to={link.url} onClick={(e) => setCurrentPage(link.keys[0])}>
                {link.text}
              </MDBNavLink>
            </MDBNavItem>
          );
        } else {
          linkContent = (
            <MDBNavItem key={link.keys[0]}>
              <MDBNavLink to={link.url} onClick={(e) => setCurrentPage(link.keys[0])}>
                {link.text}
              </MDBNavLink>
            </MDBNavItem>
          );
        }
      }
      linksContent.push(linkContent);
    });

    return linksContent;
  };

  return <MDBNav className="flex-column sidenav">{buildNav()}</MDBNav>;
};

export default withRouter(WorkFlowManagementNav);
