import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBSelect,
} from "mdbreact";
import { Auth, API } from "aws-amplify";
import config from "../content_types";

import LoadingIcon from "./LoadingIcon";

const HomepageCardsEditModal = ({ toggleEditModal, uuid, updateCard, deleteCard, cardCategories }) => {
  const [loading, setLoading] = useState(false);

  const [cardTitle, setCardTitle] = useState("");
  const [taskDetails, setTaskDetails] = useState({});

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
      },
    };
    await API.get(config.api_name, "/cards/" + uuid, requestOptions).then((response) => {
      setLoading(false);
      const task = response.card;
      setCardTitle(task.cardTitle);
      setTaskDetails(task);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateCard(taskDetails);
  };

  let cardCategoryOptions = [];
  if (cardCategories) {
    cardCategories.forEach((category) => {
      let cardCategoryOption = {
        value: category.name,
        text: category.label,
      };
      category.name === taskDetails.cardCategory
        ? (cardCategoryOption.checked = true)
        : (cardCategoryOption.checked = false);
      cardCategoryOptions.push(cardCategoryOption);
    });
  }

  let modalContent = "";
  if (loading) {
    modalContent = <LoadingIcon />;
  } else {
    modalContent = (
      <>
        <form onSubmit={handleSubmit} className="homepage-card-form">
          <MDBModalHeader toggle={toggleEditModal}>Editing {cardTitle}</MDBModalHeader>
          <MDBModalBody>
            <div className="form-group">
              <MDBInput
                label="Title"
                type="text"
                value={taskDetails.cardTitle}
                onChange={(e) => setTaskDetails({ ...taskDetails, cardTitle: e.target.value })}
              />
            </div>
            <div className="form-group">
              <MDBInput
                label="Icon"
                type="text"
                value={taskDetails.cardIcon}
                onChange={(e) =>
                  setTaskDetails({
                    ...taskDetails,
                    cardIcon: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <MDBInput
                label="URL"
                type="text"
                value={taskDetails.cardUrl}
                onChange={(e) =>
                  setTaskDetails({
                    ...taskDetails,
                    cardUrl: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <MDBInput
                label="Required permission"
                type="text"
                value={taskDetails.cardPermission}
                onChange={(e) =>
                  setTaskDetails({
                    ...taskDetails,
                    cardPermission: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <MDBSelect
                options={cardCategoryOptions}
                label="Category"
                getValue={(e) => {
                  if (e[0] !== taskDetails.cardCategory) {
                    setTaskDetails({
                      ...taskDetails,
                      cardCategory: e[0],
                    });
                  }
                }}
              />
            </div>
            <div className="form-group">
              <MDBInput
                label="Active"
                type="checkbox"
                id="card-active"
                checked={taskDetails.active}
                onChange={(e) =>
                  setTaskDetails({
                    ...taskDetails,
                    active: e.target.checked,
                  })
                }
              />
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggleEditModal}>
              Close
            </MDBBtn>
            <MDBDropdown className="float-right">
              <MDBDropdownToggle caret color="danger" className="float-right">
                Delete
              </MDBDropdownToggle>
              <MDBDropdownMenu basic>
                <MDBDropdownItem onClick={(e) => deleteCard(uuid)}>Delete</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <MDBBtn color="primary" type="submit">
              Save changes
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </>
    );
  }

  return modalContent;
};

export default HomepageCardsEditModal;
