import React, { useState, useRef } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const NewUserProperty = ({ setIsLoading, allProperties, onNewPropertySaved }) => {
  const [newPropertyFields, setNewPropertyFields] = useState(null);
  const [newPropertyModalOpen, setNewPropertyModalOpen] = useState(false);
  const [newPropertyData, setNewPropertyData] = useState(null);

  const newPropertyDataRef = useRef();

  const onNewPropertySelected = (property) => {
    allProperties.forEach((allProperty) => {
      if (allProperty.uuid === property) {
        setNewPropertyFields(allProperty);
        setNewPropertyModalOpen(true);
        const focusInput = (ref) => {
          setTimeout(() => {
            ref.current.focus();
          }, 800);
        };
        if (allProperty.inputType === "text" || allProperty.inputType === "number") {
          focusInput(newPropertyDataRef);
        }
      }
    });
  };

  const toggleNewPropertyModal = () => {
    setNewPropertyModalOpen(!newPropertyModalOpen);
  };

  const newPropertySubmit = (e) => {
    e.preventDefault();
    onNewPropertySaved({
      uuid: newPropertyFields.uuid,
      value: newPropertyData,
      label: newPropertyFields.label,
      inputType: newPropertyFields.inputType,
      propertyType: newPropertyFields.propertyType,
      name: newPropertyFields.name,
    });
    setNewPropertyFields(null);
    setNewPropertyData(null);
    setNewPropertyModalOpen(false);
  };

  let newPropertyOptions = [];
  if (allProperties) {
    allProperties.forEach((property) => {
      newPropertyOptions.push(
        <option value={property.uuid} key={property.uuid}>
          {property.label}
        </option>
      );
    });
  }

  let newPropertyModal = null;
  if (newPropertyFields) {
    let newPropertyFieldContent;

    if (newPropertyFields.inputType === "text") {
      newPropertyFieldContent = (
        <div className="form-group">
          <label htmlFor="property-value-input">{newPropertyFields.label}</label>
          <input
            className="form-control"
            type="text"
            valueDefault=""
            onChange={(event) => setNewPropertyData(event.target.value)}
            ref={newPropertyDataRef}
            id="property-value-input"
          />
        </div>
      );
    }
    if (newPropertyFields.inputType === "number") {
      newPropertyFieldContent = (
        <MDBInput
          type="number"
          label={newPropertyFields.label}
          valueDefault=""
          getValue={setNewPropertyData}
          ref={newPropertyDataRef}
        />
      );
    }
    if (newPropertyFields.inputType === "date") {
      newPropertyFieldContent = (
        <DatePicker
          selected={newPropertyData}
          onChange={(date) => setNewPropertyData(date)}
          showTimeSelect
          dateFormat="MMMM d, yyyy HH:mm 'UTC'"
          placeholderText={newPropertyFields.label}
          className="form-control form-control-sm ml-0 my-1"
        />
      );
    }
    if (newPropertyFields.inputType === "select" || newPropertyFields.inputType === 'language-select' || newPropertyFields.inputType === 'timezone-select') {
      const options = newPropertyFields.metadata;
      newPropertyFieldContent = (
        <select className="browser-default custom-select" onChange={(e) => setNewPropertyData(e.target.value)}>
          <option value={null}>{newPropertyFields.label}</option>
          {options.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      );
    }
    if (newPropertyFields.inputType === "multiple" || newPropertyFields.inputType === 'language-multiple') {

      console.log(newPropertyFields)
      let options = [];
      let _metadata = newPropertyFields.metadata;
      // let _metadata = JSON.parse(newPropertyFields.metadata);
      _metadata.forEach((option) => {
        options.push(
          <MDBSelectOption key={option.value} value={option.value}>
            {option.label}
          </MDBSelectOption>
        );
      });
      newPropertyFieldContent = (
        <MDBSelect multiple getValue={(e) => setNewPropertyData(JSON.stringify(e))}>
          <MDBSelectInput selected={newPropertyFields.label} />
          <MDBSelectOptions>{options}</MDBSelectOptions>
        </MDBSelect>
      );
    }
    if (newPropertyFields.inputType === "checkbox") {
      newPropertyFieldContent = (
        <MDBInput
          type="checkbox"
          id={newPropertyFields.name}
          label={newPropertyFields.label}
          onChange={(e) => {
            setNewPropertyData(e.target.checked.toString());
          }}
        />
      );
    }

    newPropertyModal = (
      <MDBModal isOpen={newPropertyModalOpen} toggle={toggleNewPropertyModal} className="full-height">
        <MDBModalHeader>Add new property</MDBModalHeader>
        <form onSubmit={(e) => newPropertySubmit(e)}>
          <MDBModalBody>{newPropertyFieldContent}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                setNewPropertyFields(null);
                setNewPropertyData(null);
                setNewPropertyModalOpen(false);
              }}
            >
              Cancel
            </MDBBtn>
            <MDBBtn type="submit" onClick={(e) => newPropertySubmit(e)}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    );
  }

  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBCardTitle tag="h5">Add new property</MDBCardTitle>
        <select className="browser-default custom-select" onChange={(e) => onNewPropertySelected(e.target.value)}>
          <option value={null}>Select a property to add</option>
          {newPropertyOptions}
        </select>
      </MDBCardBody>
      {newPropertyModal}
    </MDBCard>
  );
};

export default NewUserProperty;
