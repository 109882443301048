import React, { Fragment } from "react";
import { MDBIcon } from "mdbreact";
import showNotification from "../helpers/showNotification";

const CopyIcon = ({ text }) => {
  const onCopy = (e) => {
      e.preventDefault()
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    showNotification("", "Copied to clipboard.", "success");
  };
  return (
    <Fragment>
      <span className="copy-icon-text">
        {text} <MDBIcon far icon="copy" onClick={(e) => onCopy(e)} className="copy-icon" title="Click to copy" />
      </span>
    </Fragment>
  );
};

export default CopyIcon;
