import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBInput } from "mdbreact";
import React, { useState } from "react";
import showNotification from "../../helpers/showNotification";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import { Auth } from "aws-amplify";

const SetPassword = ({ setLoading }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const clearForm = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentPassword === "" || newPassword === "" || confirmNewPassword === "") {
      showNotification("Error", "Please make sure you fill in every field.", "danger");
    } else {
      if (newPassword === confirmNewPassword) {
        setLoading(true);
        const jwt = await Auth.currentSession();
        const accessToken = jwt.accessToken.jwtToken;

        await apiGatewayCall(config.api_name, "/user-portal/set-password/", "post", {
          body: {
            current_password: currentPassword,
            new_password: newPassword,
            access_token: accessToken,
          },
        })
          .then((response) => {
            setLoading(false);
            if (response.statusCode === 400) {
              showNotification("Error", response.error, "danger");
            } else {
              showNotification("Password changed", "Your password was successfully changed.", "success");
              clearForm();
            }
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
            showNotification("Error", e.error, "danger");
          });

        // const jwt = await Auth.currentSession();
        // const user_name = jwt.accessToken.payload.username;
        // const jwtToken = jwt.idToken.jwtToken;
        // const accessToken = jwt.accessToken.jwtToken;
        // const requestOptions = {
        //   headers: {
        //     Authorization: jwtToken,
        //     user_name: user_name,
        //   },
        //   body: {
        //     current_password: currentPassword,
        //     new_password: newPassword,
        //     access_token: accessToken,
        //   },
        // };
        // await API.post(config.api_name, "/user-management/set-password/", requestOptions).then((response) => {
        //   setLoading(false);
        //   if (response.statusCode === 400) {
        //     showNotification("Error", response.error, "danger");
        //   } else {
        //     showNotification("Password changed", "Your password was successfully changed.", "success");
        //     clearForm();
        //   }
        // });
      } else {
        showNotification("Error", "Please make sure your new password fields match.", "danger");
      }
    }
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          Update password
        </MDBCardTitle>
        <form onSubmit={(e) => handleSubmit(e)}>
          <MDBInput type="password" label="Current password" onChange={(e) => setCurrentPassword(e.target.value)} />
          <MDBInput type="password" label="New password" onChange={(e) => setNewPassword(e.target.value)} />
          <MDBInput
            type="password"
            label="Confirm new password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <MDBBtn type="submit" onClick={(e) => handleSubmit(e)} color="secondary" className="float-right">
            Save
          </MDBBtn>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SetPassword;
