import React, { useState, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import showNotification from '../helpers/showNotification';
import { Auth } from 'aws-amplify';
import QrCode from './QRCode';
import CopyIcon from './CopyIcon';
import LoadingIcon from './LoadingIcon';
import config from '../config';
import ResetPassword from './ResetPassword';
import PhoneInput from 'react-phone-input-2';

const LoginForm = ({ setIsAuthenticated, redirectToReturnUrl }) => {
  const [isLoading, setIsLoading] = useState(false);
  // let history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [userObject, setUserObject] = useState({});
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [newPasswordModalOpen, setNewPasswordModalOpen] = useState(false);

  const [setupMfaModalOpen, setSetupMfaModalOpen] = useState(false);
  const [qrCodeContent, setQrCodeContent] = useState(null);
  const [qrCodeString, setQrCodeString] = useState(null);

  const [totpMfaModalOpen, setTotpMfaModalOpen] = useState(false);
  const [smsMfaModalOpen, setSmsMfaModalOpen] = useState(false);
  const [smsNumber, setSmsNumber] = useState(null);
  const [setupSmsMfaModalOpen, setSetupSmsMfaModalOpen] = useState(false);

  const [mfaCode, setMfaCode] = useState('');

  const totpCodeRef = useRef();
  const smsCodeRef = useRef();

  let environmentName = '';
  if (config.environmentName !== '' && config.environmentName !== 'production')
    environmentName = ' (' + config.environmentName + ')';

  const focusInput = (ref) => {
    setTimeout(() => {
      ref.current.focus();
    }, 800);
  };

  const handleSubmit = async (event) => {
    localStorage.setItem('loginCount', parseInt(localStorage.getItem('loginCount')) + 1);
    setIsLoading(true);
    event.preventDefault();
    try {
      await Auth.signIn(email, password)
        .then((user) => {
          if ('challengeName' in user) {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setUserObject(user);
              setNewPasswordModalOpen(true);
              setIsLoading(false);
            } else if (user.challengeName === 'SMS_MFA') {
              setUserObject(user);
              setIsLoading(false);
              setSmsMfaModalOpen(true);
              setSmsNumber(user.challengeParam.CODE_DELIVERY_DESTINATION);
              focusInput(smsCodeRef);
            } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
              setUserObject(user);
              setIsLoading(false);
              setTotpMfaModalOpen(true);
              focusInput(totpCodeRef);
            }
          } else {
            setupMFA(user);
          }
        })
        .catch((e) => {
          showNotification('Error', e.message, 'warning');
          setIsLoading(false);
        });
    } catch (e) {
      alert(e.message);
    }
  };

  const toggleNewPasswordModal = () => {};

  const validateNewPasswordForm = () => {
    if (
      newPassword === confirmNewPassword &&
      fullName !== '' &&
      mobileNumber.startsWith('+') &&
      !mobileNumber.startsWith('0')
    ) {
      return true;
    } else {
      showNotification(
        'Error',
        'Please make sure your passwords match, you have entered your name, and a mobile phone number beginning with "+" and your country code.',
        'warning'
      );
    }
  };

  const submitNewPassword = async (e) => {
    e.preventDefault();
    if (validateNewPasswordForm()) {
      await Auth.completeNewPassword(userObject, newPassword, { name: fullName, phone_number: mobileNumber })
        .then(async (user) => {
          setNewPasswordModalOpen(false);
          setupMFA(user);
        })
        .catch((e) => {
          if (e.code === 'InvalidPasswordException') {
            showNotification(
              'Error',
              "The password you entered was not valid. Make sure it's at least six characters long and contains at least one uppercase letter, one lowercase letter, one number, and one special character.",
              'warning'
            );
          } else {
            showNotification('Error', `${e}`, 'error');
          }
        });
    }
  };

  const submitMfaCode = async (e, method) => {
    let code = '';

    switch (method) {
      case 'SMS_MFA':
        e.preventDefault();
        code = mfaCode;
        break;
      case 'SOFTWARE_TOKEN_MFA':
        code = e.target.value;
        break;
      default:
        break;
    }
    console.log('USEROBJECT:', userObject);
    console.log('CODE:', code);
    console.log('METHOD:', method);
    await Auth.confirmSignIn(userObject, code, method)
      .then((user) => {
        if (user.signInUserSession !== null) {
          setIsAuthenticated(true);
          setIsLoading(false);
          setSmsMfaModalOpen(false);
          redirectToReturnUrl();
        }
      })
      .catch((e) => {
        if (e.code === 'CodeMismatchException') {
          setMfaCode('');
          showNotification('Incorrect code', 'The code you entered was not correct. Please try again.', 'warning');
        }
      });
  };
  const toggleSmsMfaModal = () => {};
  const toggleTotpMfaModal = () => {};

  const setupMFA = async (user) => {
    setUserObject(user);
    setIsLoading(false);
    setSetupMfaModalOpen(true);
    await Auth.setupTOTP(user).then((code) => {
      const issuer = encodeURIComponent('Take 1 Console' + environmentName);
      const qrCodeUrl = 'otpauth://totp/' + encodeURIComponent(email) + '?secret=' + code + '&issuer=' + issuer;
      setQrCodeContent(<QrCode value={qrCodeUrl} link={false} />);
      setQrCodeString(code);
    });
  };

  const setupSmsMfa = async (e) => {
    e.preventDefault();
    setSetupMfaModalOpen(false);
    await Auth.setPreferredMFA(userObject, 'SMS').then(async (response) => {
      await Auth.signOut();
      setSetupSmsMfaModalOpen(true);
    });
  };
  const toggleSetupSmsMfaModalOpen = () => {};
  const closeSetupSmsMfaModal = (e) => {
    e.preventDefault();
    setSetupSmsMfaModalOpen(false);
  };

  const submitMfaMethod = async (e) => {
    e.preventDefault();
    Auth.verifyTotpToken(userObject, mfaCode)
      .then(async (response) => {
        if (response.Status === 'SUCCESS') {
          await Auth.setPreferredMFA(userObject, 'TOTP').then((response) => {
            setIsAuthenticated(true);
            redirectToReturnUrl();
          });
        }
      })
      .catch((e) => {
        showNotification('Invalid code', 'That code is not valid. Try again.', 'warning');
        console.error(e);
      });
  };

  const toggleSetupMfaModal = () => {};

  let loadingContent = null;
  if (isLoading) loadingContent = <LoadingIcon />;

  const smsMfaModal = (
    <MDBModal isOpen={smsMfaModalOpen} toggle={toggleSmsMfaModal}>
      <MDBModalHeader>Additional security</MDBModalHeader>
      <form onSubmit={(e) => submitMfaCode(e, 'SMS_MFA')}>
        <MDBModalBody>
          <p>A verification code has been sent to {smsNumber}. Please enter it below to complete your login.</p>

          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='basic-addon'>
                <i className='fa fa-mobile-alt prefix'></i>
              </span>
            </div>
            <input
              className='form-control'
              type='number'
              value={mfaCode}
              onChange={(event) => setMfaCode(event.target.value)}
              ref={smsCodeRef}
            />
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color='primary' type='submit' onClick={(e) => submitMfaCode(e, 'SMS_MFA')}>
            Go
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );

  const setupSmsMfaModal = (
    <MDBModal isOpen={setupSmsMfaModalOpen} toggle={toggleSetupSmsMfaModalOpen}>
      <MDBModalHeader>Additional security</MDBModalHeader>
      <MDBModalBody>
        You have opted to use text messages to log in to the Take 1 Console. Click below to log in again.
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color='primary' onClick={(e) => closeSetupSmsMfaModal(e)}>
          OK
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );

  const onInputCodeChange = (e) => {
    setMfaCode(e.target.value);
    if (e.target.value.length === 6) submitMfaCode(e, 'SOFTWARE_TOKEN_MFA');
  };

  const totpMfaModal = (
    <MDBModal isOpen={totpMfaModalOpen} toggle={toggleTotpMfaModal}>
      <MDBModalHeader>Additional security</MDBModalHeader>
      <form onSubmit={(e) => submitMfaCode(e, 'SOFTWARE_TOKEN_MFA')}>
        <MDBModalBody>
          <p>Please enter a verification code generated by an Authenticator app.</p>

          <div className='input-group mb-4'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='basic-addon'>
                <i className='fa fa-mobile-alt prefix'></i>
              </span>
            </div>
            <input
              className='form-control'
              type='number'
              value={mfaCode}
              onChange={(event) => setMfaCode(event.target.value)}
              onChange={(e) => onInputCodeChange(e)}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
              ref={totpCodeRef}
            />
          </div>

          <p className='mb-0'>
            <small>
              <a
                href='https://take1jira.atlassian.net/servicedesk/customer/portal/2'
                target='_blank'
                rel='noopener noreferrer'>
                Lost your device? Submit a Service Desk ticket here.
              </a>
            </small>
          </p>
        </MDBModalBody>
        {/* <MDBModalFooter>
          <MDBBtn color='primary' type='submit' onClick={(e) => submitMfaCode(e, 'SOFTWARE_TOKEN_MFA')}>
            Go
          </MDBBtn>
        </MDBModalFooter> */}
      </form>
    </MDBModal>
  );

  const setupMfaModal = (
    <MDBModal isOpen={setupMfaModalOpen} toggle={toggleSetupMfaModal}>
      <MDBModalHeader>Let's get started</MDBModalHeader>
      <form onSubmit={(e) => submitMfaMethod(e)}>
        <MDBModalBody>
          <p>We need to set up two-factor authentication for your account. </p>
          <p>
            Open Google Authenticator (or a similar authentication app) and scan the QR code below to add Take 1
            Console.
          </p>
          {qrCodeContent}
          <p>Alternatively, enter this code on your device:</p>
          <code>
            <CopyIcon text={qrCodeString} />
          </code>
          <p>Now enter the code generated by your app below.</p>
          <MDBInput
            type='text'
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            label='Code'
            icon='mobile-alt'
          />
          <p>
            <a href='#!' onClick={(e) => setupSmsMfa(e)}>
              Use text messages instead
            </a>
            {/* <MDBBtn >Use text messages instead</MDBBtn> */}
          </p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color='primary' type='submit' onClick={(e) => submitMfaMethod(e)}>
            Go
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );

  const newPasswordModal = (
    <MDBModal isOpen={newPasswordModalOpen} toggle={toggleNewPasswordModal}>
      <MDBModalHeader>Let's get started</MDBModalHeader>
      <form onSubmit={(e) => submitNewPassword(e)}>
        <MDBModalBody>
          <p>Because this is your first time logging in to the Take 1 Console, please complete the fields below.</p>
          <p>
            Your mobile phone number must start with "+" followed by your country code, without the 0 at the beginning.
            For example, the UK phone number "07123 456 789" should be entered as "+447123456789".
          </p>
          <p>
            Passwords must be at least six characters and include at least one uppercase letter, one lower case letter,
            one number, and one special character.
          </p>

          <div className='grey-text'>
            <MDBInput
              label='Full name (required)'
              icon='user'
              type='text'
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
            />
            {
              <PhoneInput
                onChange={(phoneNumber) => setMobileNumber('+' + phoneNumber.replace(/\s/g, ''))}
                country={'gb'}
                preferredCountries={['gb', 'cl']}
                inputProps={{ className: 'form-control', id: 'phoneNumber', required: true }}
                autoFormat={true}
                countryCodeEditable={false}
                prefix='+'
                isValid={(inputNumber, country) => {
                  if (inputNumber.slice(country.dialCode.length).charAt(0) === '0') {
                    return 'Invalid value: ' + inputNumber + ', No first zero please';
                  }
                  return true;
                }}
              />
            }
            {/* <MDBInput
              label="Mobile number (required)"
              icon="mobile-alt"
              type="tel"
              value={mobileNumber}
              onChange={(event) => setMobileNumber(event.target.value)}
            /> */}
            <MDBInput
              label='New password (required)'
              icon='lock'
              type='password'
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
            <MDBInput
              label='Confirm new password (required)'
              icon='lock'
              type='password'
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
            />
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color='primary' type='submit' onClick={(e) => submitNewPassword(e)}>
            Go
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );

  return (
    <Fragment>
      {loadingContent}
      {smsMfaModal}
      {totpMfaModal}
      {setupMfaModal}
      {setupSmsMfaModal}
      {newPasswordModal}
      <h1 className='page-title'>Log in</h1>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='grey-text'>
          <MDBInput
            label='Type your email'
            icon='envelope'
            group
            type='email'
            validate
            error='wrong'
            success='right'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <MDBInput
            label='Type your password'
            icon='lock'
            group
            type='password'
            validate
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        <ResetPassword />

        <MDBBtn type='submit'>Login</MDBBtn>
      </form>
    </Fragment>
  );
};

export default LoginForm;
