import React, { useEffect, useState } from "react";
import {
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBSelect
} from "mdbreact";
import DeleteWorkflowButton from "./DeleteWorkflowButton";

const DespatchItemForm = ({
  workflowItem,
  saveClick,
  workflowModalToggle,
  afterSuccessfulSubmit
}) => {
  useEffect(() => {
    buildFormData();
    // eslint-disable-next-line
  }, []);

  const saveClickConstructor = () => {
    let requestBody = {
      type: "DI",
      serviceTemplateDespatchInstructionId: serviceTemplateDespatchInstructionId,
      serviceTemplateId: serviceTemplateId,
      deliveryInstructionTemplateId: deliveryInstructionTemplateId,
      packagingInstructionTemplateId: packagingInstructionTemplateId,
      packageGroupId: packageGroupId,
      deliveryHoldingId: deliveryHoldingId,
      serviceTemplateAssemblyInstructionId: serviceTemplateAssemblyInstructionId,
      packageLocationId: packageLocationId,
      fileNameTypePrefix: fileNameTypePrefix,
      fileNameTypeId: fileNameTypeId,
      fileNameTypeSuffix: fileNameTypeSuffix,
      status: status
    };

    saveClick(requestBody);
  };

  const serviceTemplateDespatchInstructionId =
    workflowItem.despatch_instruction.serviceTemplateDespatchInstructionId;
  const serviceTemplateId = workflowItem.despatch_instruction.serviceTemplateId;
  const [
    deliveryInstructionTemplateId,
    setDeliveryInstructionTemplateId
  ] = useState(workflowItem.despatch_instruction.deliveryInstructionTemplateId);
  const [
    packagingInstructionTemplateId,
    setPackagingInstructionTemplateId
  ] = useState(
    workflowItem.despatch_instruction.packagingInstructionTemplateId
  );
  const [packageGroupId, setPackageGroupId] = useState(
    workflowItem.despatch_instruction.packageGroupId
  );
  const [deliveryHoldingId, setDeliveryHoldingId] = useState(
    workflowItem.despatch_instruction.deliveryHoldingId
  );
  const [
    serviceTemplateAssemblyInstructionId,
    setServiceTemplateAssemblyInstructionId
  ] = useState(
    workflowItem.despatch_instruction.serviceTemplateAssemblyInstructionId
  );
  const [packageLocationId, setPackageLocationId] = useState(
    workflowItem.despatch_instruction.packageLocationId
  );
  const [fileNameTypePrefix, setFileNameTypePrefix] = useState(
    workflowItem.despatch_instruction.fileNameTypePrefix
  );
  const [fileNameTypeId, setFileNameTypeId] = useState(
    workflowItem.despatch_instruction.fileNameTypeId
  );
  const [fileNameTypeSuffix, setFileNameTypeSuffix] = useState(
    workflowItem.despatch_instruction.fileNameTypeSuffix
  );
  const [status, setStatus] = useState(
    workflowItem.despatch_instruction.status
  );

  const [formContent, setFormContent] = useState([]);

  const buildFormData = async () => {
    let inputs = [];

    const field_serviceTemplateDespatchInstructionId = (
      <div key="field_serviceTemplateDespatchInstructionId">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_serviceTemplateDespatchInstructionId"
              label="Despatch instruction ID"
              disabled
              value={serviceTemplateDespatchInstructionId}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_serviceTemplateDespatchInstructionId);
    const field_serviceTemplateId = (
      <div key="field_serviceTemplateId">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_serviceTemplateId"
              label="Service template ID"
              disabled
              value={serviceTemplateId}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_serviceTemplateId);

    let serviceTemplateAssemblyInstructions = [];
    workflowItem.form_values.assembly_instruction_templates.forEach(
      template => {
        let serviceTemplateAssemblyInstruction = {
          text: template.notes,
          value: template.serviceTemplateAssemblyInstructionId,
          checked: false
        };
        if (
          template.serviceTemplateAssemblyInstructionId ===
          serviceTemplateAssemblyInstructionId
        ) {
          serviceTemplateAssemblyInstruction.checked = true;
        }
        serviceTemplateAssemblyInstructions.push(
          serviceTemplateAssemblyInstruction
        );
      }
    );
    const field_serviceTemplateAssemblyInstructionId = (
      <div key="field_serviceTemplateAssemblyInstructionId">
        <MDBRow>
          <MDBCol size="12">
            <MDBSelect
              options={serviceTemplateAssemblyInstructions}
              label="Assembly instruction template"
              getValue={e => setServiceTemplateAssemblyInstructionId(e[0])}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_serviceTemplateAssemblyInstructionId);

    let deliveryInstructionTemplates = [];
    workflowItem.form_values.delivery_instruction_templates.forEach(
      template => {
        let deliveryInstructionTemplate = {
          text: template.name,
          value: template.deliveryInstructionTemplateId,
          checked: false
        };
        if (
          template.deliveryInstructionTemplateId ===
          deliveryInstructionTemplateId
        ) {
          deliveryInstructionTemplate.checked = true;
        }
        deliveryInstructionTemplates.push(deliveryInstructionTemplate);
      }
    );
    const field_deliveryInstructionTemplateId = (
      <div key="field_deliveryInstructionTemplateId">
        <MDBRow>
          <MDBCol size="12">
            <MDBSelect
              options={deliveryInstructionTemplates}
              label="Delivery instruction template"
              getValue={e => setDeliveryInstructionTemplateId(e[0])}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_deliveryInstructionTemplateId);

    let packagingInstructionTemplates = [];
    workflowItem.form_values.packaging_instruction_templates.forEach(
      template => {
        let packagingInstructionTemplate = {
          text: template.name,
          value: template.packagingInstructionTemplateId,
          checked: false
        };
        if (
          template.packagingInstructionTemplateId ===
          packagingInstructionTemplateId
        ) {
          packagingInstructionTemplate.checked = true;
        }
        packagingInstructionTemplates.push(packagingInstructionTemplate);
      }
    );
    const field_packagingInstructionTemplateId = (
      <div key="field_packagingInstructionTemplateId">
        <MDBRow>
          <MDBCol size="12">
            <MDBSelect
              options={packagingInstructionTemplates}
              label="Packaging instruction template"
              getValue={e => setPackagingInstructionTemplateId(e[0])}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_packagingInstructionTemplateId);

    const field_packageGroupId = (
      <div key="field_packageGroupId">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_packageGroupId"
              label="Package group ID"
              valueDefault={packageGroupId}
              onChange={e => setPackageGroupId(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_packageGroupId);

    const field_deliveryHoldingId = (
      <div key="field_deliveryHoldingId">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_deliveryHoldingId"
              label="Delivery holding ID"
              valueDefault={deliveryHoldingId}
              onChange={e => setDeliveryHoldingId(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_deliveryHoldingId);

    const field_packageLocationId = (
      <div key="field_packageLocationId">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_packageLocationId"
              label="Package location ID"
              valueDefault={packageLocationId}
              onChange={e => setPackageLocationId(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_packageLocationId);

    const field_fileNameTypePrefix = (
      <div key="field_fileNameTypePrefix">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_fileNameTypePrefix"
              label="Filename type prefix"
              valueDefault={fileNameTypePrefix}
              onChange={e => setFileNameTypePrefix(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileNameTypePrefix);

    let fileNameTypes = [];
    workflowItem.form_values.file_name_types.forEach(template => {
      let fileNameType = {
        text: template.name,
        value: template.fileNameTypeId,
        checked: false
      };
      if (template.fileNameTypeId === fileNameTypeId) {
        fileNameType.checked = true;
      }
      fileNameTypes.push(fileNameType);
    });
    if (fileNameTypeId === "0") {
      fileNameTypes.push({
        text: "None",
        value: "0",
        checked: true
      });
    } else {
      fileNameTypes.push({
        text: "None",
        value: "0"
      });
    }
    const field_fileNameTypeId = (
      <div key="field_fileNameTypeId">
        <MDBRow>
          <MDBCol size="12">
            <MDBSelect
              options={fileNameTypes}
              label="Filename type"
              getValue={e => setFileNameTypeId(e[0])}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileNameTypeId);

    const field_fileNameTypeSuffix = (
      <div key="field_fileNameTypeSuffix">
        <MDBRow>
          <MDBCol size="12">
            <MDBInput
              type="text"
              id="field_fileNameTypeSuffix"
              label="Filename type suffix"
              valueDefault={fileNameTypeSuffix}
              onChange={e => setFileNameTypeSuffix(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileNameTypeSuffix);

    let allStatuses = [
      {
        text: "Inactive",
        value: 0
      },
      {
        text: "Active",
        value: 1
      }
    ];
    allStatuses.forEach(allStatus => {
      if (allStatus.value === status) {
        allStatus.checked = true;
      }
    });

    const field_status = (
      <div key="field_status">
        <MDBRow>
          <MDBCol size="12">
            <MDBSelect
              options={allStatuses}
              label="Status"
              getValue={e => setStatus(e[0])}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_status);

    setFormContent(inputs);
  };

  return (
    <>
      <MDBModalBody>
        <form onSubmit={e => saveClickConstructor()}>{formContent}</form>
      </MDBModalBody>
      <MDBModalFooter>
        <DeleteWorkflowButton
          workflowItem={workflowItem}
          afterSuccessfulSubmit={afterSuccessfulSubmit}
        />
        <MDBBtn color="secondary" onClick={workflowModalToggle}>
          Cancel
        </MDBBtn>
        <MDBBtn onClick={e => saveClickConstructor()} color="primary">
          Save
        </MDBBtn>
      </MDBModalFooter>
    </>
  );
};

export default DespatchItemForm;
