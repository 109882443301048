import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { withRouter, Redirect } from "react-router-dom";
import uuid from "uuid";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import {
  MDBInput,
  MDBSelect,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
} from "mdbreact";
import showNotification from "../../helpers/showNotification";
import HtmlEditor from "../HtmlEditor";
import apiGatewayCall from "../../helpers/apiGatewayCall";

const WorkflowEdit = ({ content_type, match, setLoading }) => {
  const recordId = match.params.id;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [redirectToHome, setRedirectToHome] = useState(false);

  const [availableComponents, setAvailableComponents] = useState([]);
  const [workflowTitle, setWorkflowTitle] = useState(null);
  const [formContent, setFormContent] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [formFields, setFormFields] = useState(null);
  const [sortableItems, setSortableItems] = useState([]);
  const [componentsToAdd, setComponentsToAdd] = useState([]);

  const [canEdit, setCanEdit] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/workflows/" + recordId, "get")
      .then((response) => {
        setLoading(false);
        setPageTitle(response.body.title);
        setWorkflowTitle(response.body.title);

        setFormFields(response.form_values);
        setFormContent(buildForm(response.form_values));
        buildCurrentComponents(response.component_recipes);
        setAvailableComponents(response.components);

        setCanEdit(response.can_edit);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        showNotification("Access denied", "You do not have permission to view workflows.", "warning");
      });
  };

  const buildCurrentComponents = (component_recipes) => {
    let newSortableItems = [];
    let newComponentsToAdd = [];

    component_recipes.forEach((componentRecipe) => {
      const newComponentId = uuid();
      newSortableItems.push(
        <MDBCard key={newComponentId} itemID={newComponentId}>
          <MDBCardHeader>
            <MDBCardTitle>{componentRecipe.componentRecipeComponent.name}</MDBCardTitle>
            <MDBCardText>{componentRecipe.componentRecipeComponent.description}</MDBCardText>
          </MDBCardHeader>
          <MDBCardBody>
            {componentRecipe.componentRecipeRecipe !== null ? (
              <MDBSelect
                options={[
                  {
                    text: componentRecipe.componentRecipeRecipe.name,
                    value: componentRecipe.componentRecipeRecipe.name,
                    checked: true,
                    disabled: true,
                  },
                ]}
                label="Recipe"
              />
            ) : (
              <p>No recipe selected</p>
            )}
          </MDBCardBody>
          <MDBCardFooter>
            <MDBBtn onClick={(e) => removeComponent(newComponentId)}>Remove</MDBBtn>
          </MDBCardFooter>
        </MDBCard>
      );

      let newComponent = {
        SortableItemId: newComponentId,
        componentId: componentRecipe.componentRecipeComponent.componentId,
      };
      if (componentRecipe.componentRecipeRecipe !== null)
        newComponent.recipe = componentRecipe.componentRecipeRecipe.recipeId;

      newComponentsToAdd.push(newComponent);

      // newComponentsToAdd.push({
      //   SortableItemId: newComponentId,
      //   recipe: componentRecipe.componentRecipeRecipe.recipeId,
      //   componentId: componentRecipe.componentRecipeRecipe.componentId,
      // });
    });

    setSortableItems(newSortableItems);
    setComponentsToAdd(newComponentsToAdd);
  };
  const validateForm = () => {
    let requiredFields = [];
    let invalidFields = [];
    // eslint-disable-next-line
    for (const field in formFields) {
      if (formFields.hasOwnProperty(field)) {
        if (formFields[field].required === true) {
          requiredFields.push({
            field: formFields[field].field,
            label: formFields[field].label,
          });
        }
      }
    }
    requiredFields.forEach((requiredField) => {
      if (formValues[requiredField.field] === "") {
        invalidFields.push(requiredField.label);
      }
    });

    // if (componentsToAdd.length === 0) {
    //   invalidFields.push("Components");
    // } else {
    //   componentsToAdd.forEach((component) => {
    //     if (!component.recipe) {
    //       invalidFields.push("Recipes");
    //     }
    //   });
    // }

    if (invalidFields.length === 0) {
      return true;
    } else {
      let invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " + invalidFieldsString,
        "warning"
      );
    }
  };

  const handleFormChange = (fieldName, e) => {
    let newFormValues = Object.assign(formValues, formValues);

    if (e === true) {
      e = 1;
    } else if (e === false) {
      e = 0;
    }
    newFormValues[fieldName] = e;

    setFormValues(newFormValues);
  };

  const buildForm = (formFieldItems) => {
    setFormValues({});
    let workflowFormItems = [];

    // eslint-disable-next-line
    for (const field in formFieldItems) {
      let fieldObject = null;

      const fieldKey = uuid();
      const fieldName = formFieldItems[field];
      let fieldRequired = false;
      fieldName.required === 1 ? (fieldRequired = true) : (fieldRequired = false);
      let fieldReadOnly = false;
      fieldName.readOnly === 1 ? (fieldReadOnly = true) : (fieldReadOnly = false);
      let fieldParent = JSON.parse(fieldName.parent);
      let fieldLabel = null;
      fieldRequired === true ? (fieldLabel = fieldName.label + " (required)") : (fieldLabel = fieldName.label);
      const fieldType = fieldName.htmlElement;

      let fieldParentActive = false;
      if (fieldParent !== null) {
        // eslint-disable-next-line
        for (const parentField in formFieldItems) {
          if (formFieldItems[parentField].default === fieldParent.value) {
            fieldParentActive = true;
          }
        }
      }

      if (fieldType === "text") {
        if (fieldParent === null || fieldParentActive === true) {
          handleFormChange(fieldName.name, fieldName.default);
        }
        fieldObject = (
          <MDBInput
            type="text"
            id={fieldKey}
            label={fieldLabel}
            valueDefault={fieldName.default}
            onChange={(e) => handleFormChange(fieldName.name, e.target.value, fieldRequired)}
            className="input-text"
            required={fieldRequired}
            disabled={fieldReadOnly}
          />
        );
      } else if (fieldType === "textarea") {
        if (fieldParent === null || fieldParentActive === true) {
          handleFormChange(fieldName.name, fieldName.default);
        }
        fieldObject = (
          <MDBInput
            type="textarea"
            id={fieldKey}
            label={fieldLabel}
            valueDefault={fieldName.default}
            onChange={(e) => handleFormChange(fieldName.name, e.target.value, fieldRequired)}
            className="input-textarea"
            required={fieldRequired}
            disabled={fieldReadOnly}
          />
        );
      } else if (fieldType === "wysiwyg") {
        fieldObject = (
          <HtmlEditor
            defaultText={fieldName.default}
            onValueChange={handleFormChange}
            label={fieldLabel}
            fieldSlug={fieldName.name}
            fieldRequired={fieldRequired}
          />
        );
      } else if (fieldType === "select") {
        let fieldMultiple = false;
        fieldName.multiple === 1 ? (fieldMultiple = true) : (fieldMultiple = false);
        if (fieldParent === null) {
          handleFormChange(fieldName.name, fieldName.default);
        }
        const options = fieldName.data;
        let newOptions = [];
        options.forEach((option) => {
          let newOption = {
            text: option.text,
            value: option.value,
          };
          if (option.value === fieldName.default) newOption.checked = true;
          newOptions.push(newOption);
        });
        fieldObject = (
          <MDBSelect
            options={newOptions}
            label={fieldLabel}
            className="input-select"
            getValue={(e) => {
              fieldMultiple
                ? handleFormChange(fieldName.name, e, fieldRequired)
                : handleFormChange(fieldName.name, e[0], fieldRequired);
            }}
            required={fieldRequired}
            disabled={fieldReadOnly}
            multiple={fieldMultiple}
          />
        );
      } else if (fieldType === "checkbox") {
        let fieldDefault = false;
        fieldName.default === 1 ? (fieldDefault = true) : (fieldDefault = false);

        if (fieldParent === null) {
          handleFormChange(fieldName.name, fieldDefault);
        }
        fieldObject = (
          <MDBInput
            id={fieldName.name}
            label={fieldLabel}
            value={fieldName.name}
            onChange={(e) => {
              handleFormChange(fieldName.name, e.target.checked, fieldRequired);
            }}
            className="input-text"
            required={fieldRequired}
            disabled={fieldReadOnly}
            defaultChecked={fieldDefault}
            type="checkbox"
          />
        );
      }
      if (fieldParent !== null) {
        let childFieldClass = "has-parent d-none";
        if (fieldParentActive === true) childFieldClass = "has-parent";
        workflowFormItems.push(
          <div
            key={fieldKey}
            data-parent-field={fieldParent.field}
            data-parent-value={fieldParent.value}
            className={childFieldClass}
          >
            {fieldObject}
          </div>
        );
      } else {
        workflowFormItems.push(<div key={fieldKey}>{fieldObject}</div>);
      }
    }
    return workflowFormItems;
  };

  const updateRecipeSelection = (itemID, recipe, componentId) => {
    setComponentsToAdd((oldComponentsToAdd) => {
      let newComponentsToAdd = [].concat(oldComponentsToAdd);
      let count = 0;
      oldComponentsToAdd.forEach((component) => {
        if (component.SortableItemId === itemID) {
          newComponentsToAdd[count].recipe = recipe;
          newComponentsToAdd[count].componentId = componentId;
        }
        count++;
      });
      return newComponentsToAdd;
    });
  };

  const removeComponent = (itemID) => {
    setSortableItems((oldSortableItems) => {
      return oldSortableItems.filter((item) => item.props.itemID !== itemID);
    });
    setComponentsToAdd((oldComponentsToAdd) => {
      return oldComponentsToAdd.filter((item) => item.SortableItemId !== itemID);
    });
  };

  const addNewComponent = (componentId) => {
    availableComponents.forEach((availableComponent) => {
      if (availableComponent.componentId === componentId) {
        const newComponentId = uuid();
        let newSortableItems = [].concat(sortableItems);
        newSortableItems.push(
          <SortableItem component={availableComponent} itemID={newComponentId} key={newComponentId} />
        );

        let newComponentsToAdd = [].concat(componentsToAdd);
        newComponentsToAdd.push({
          SortableItemId: newComponentId,
          recipe: null,
          componentId: componentId,
        });

        setSortableItems(newSortableItems);
        setComponentsToAdd(newComponentsToAdd);
      }
    });
  };

  const SortableItem = ({ component, itemID }) => {
    let componentRecipeOptions = [];
    component.recipes.length > 0
      ? component.recipes.forEach((recipe) => {
          componentRecipeOptions.push({
            text: recipe.name,
            value: recipe.recipeId.toString(),
          });
        })
      : componentRecipeOptions.push({
          text: "No recipes available",
          value: null,
        });
    return (
      <MDBCard>
        <MDBCardHeader>
          <MDBCardTitle>{component.name}</MDBCardTitle>
          <MDBCardText>{component.description}</MDBCardText>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBSelect
            options={componentRecipeOptions}
            label="Recipe"
            getValue={(e) => updateRecipeSelection(itemID, e[0], component.componentId)}
          />
        </MDBCardBody>
        <MDBCardFooter>
          <MDBBtn onClick={(e) => removeComponent(itemID)}>Remove</MDBBtn>
        </MDBCardFooter>
      </MDBCard>
    );
  };

  const setFormValuesBeforeSubmit = () => {
    setFormValues(Object.assign(formValues, formValues));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      setFormValuesBeforeSubmit();
      const jwt = await Auth.currentSession();
      let options = {
        body: formValues,
      };
      options.body.components = componentsToAdd;
      options.body.owner = jwt.accessToken.payload.username;
      options.body.version = 1;

      setLoading(true);
      apiGatewayCall(config.api_name, "/workflow-management/workflows/" + recordId, "post", options)
        .then((response) => {
          setFormValues({});
          setComponentsToAdd([]);
          setSortableItems([]);
          afterSuccessfulSubmit();
        })
        .catch(() => {
          setLoading(false);
          showNotification("Access denied", "You do not have permission to edit workflows.", "warning");
        });
    }
  };
  const afterSuccessfulSubmit = () => {
    showNotification("Success", formValues.title + " was successfully updated", "success");
    fetchData();
  };

  let newComponentOptions = [];
  if (availableComponents.length) {
    availableComponents.forEach((component) => {
      if (component.recipes.length) {
        newComponentOptions.push({
          text: component.name,
          value: component.componentId,
        });
      }
    });
  }

  let pageContent = null;
  if (redirectToHome) {
    pageContent = <Redirect to="/workflow-configuration/workflows" />;
  } else {
    pageContent = (
      <>
        <h1 className="page-title">{workflowTitle}</h1>
        <form onSubmit={(e) => handleFormSubmit(e)}>
          {formContent}
          <h3>Components</h3>
          <div className="components-to-add">
            {sortableItems}
            <MDBSelect
              options={newComponentOptions}
              selected="Choose a component"
              label="Add new component"
              getValue={(e) => addNewComponent(e[0])}
            />
            {/* <p className="small">
              Can't see your component listed here? Make sure there is at least one{" "}
              <a href="/workflow-configuration/recipes/">recipe</a> associated with it.
            </p> */}
          </div>

          <MDBBtn color="secondary" onClick={(e) => setRedirectToHome(true)}>
            Cancel
          </MDBBtn>
          {/* {canDelete === true ? (
              <DeleteButton
                content_type="workflows"
                id={recordId}
                title={workflowTitle}
                setRedirectToHome={setRedirectToHome}
                key="button-delete"
              />
            ) : null} */}
          {canEdit === true ? (
            <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} color="primary">
              Save
            </MDBBtn>
          ) : null}
        </form>
      </>
    );
  }

  return pageContent;
};

export default withRouter(WorkflowEdit);
