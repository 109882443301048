import { MDBBtn, MDBDataTable, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from "mdbreact";
import React, { useState } from "react";
import sortArrayOfObjectsByKey from "../../../helpers/sortArrayOfObjectsByKey";
import apiGatewayCall from "../../../helpers/apiGatewayCall";
import config from "../../../content_types";

const UsersToNotSync = ({ usersToNotSync, getUsersToSync }) => {
  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const [buildTable, setBuildTable] = useState(null);

  const onAddUsersClick = (e) => {
    e.preventDefault();
    toggleAddUsersModal();
    usersToNotSync.forEach((x) => {
      x.action = false;
    });
    setBuildTable(buildRows(usersToNotSync));
  };

  const toggleAddUsersModal = () => {
    setAddUsersModalOpen(!addUsersModalOpen);
  };

  const selectAllCheckboxHandler = (target) => {
    let { checked } = target;
    const _users = [];

    usersToNotSync.forEach((user) => {
      user.action = checked;
      _users.push(user);
    });

    setBuildTable(buildRows(usersToNotSync));
  };

  const checkedHandler = (e) => {
    const { checked, id } = e.target;

    console.log(usersToNotSync);

    usersToNotSync.forEach((user) => {
      if (id === user.uuid) user.action = checked;
    });
    setBuildTable(buildRows(usersToNotSync));
  };

  const checkAll = (
    <>
      <input
        className="form-check-input"
        type="checkbox"
        id="checkAll"
        onChange={(e) => selectAllCheckboxHandler(e.target)}
      ></input>
      <label className="form-check-label" htmlFor="checkAll">
        Check All
      </label>
    </>
  );

  const buildRows = (users) => {
    let _data = {
      columns: [
        { label: "Name", field: "fullName" },
        { label: "Email", field: "email" },
        { label: "Resource Code", field: "resourceCode" },
        { label: checkAll, field: "actions" },
      ],
      rows: [],
    };
    usersToNotSync.forEach((user) => {
      if (user.fullName !== undefined) {
        _data.rows.push({
          fullName: user.fullName,
          email: user.email,
          resourceCode: user.resourceCode !== undefined ? user.resourceCode : "",
          actions: <MDBInput id={user.uuid} type="checkbox" checked={user.action} onChange={checkedHandler} />,
        });
      }
    });
    _data.rows = sortArrayOfObjectsByKey(_data.rows, "fullName");
    return _data;
  };

  const onConfirmClick = async (e) => {
    const _usersToProcess = [];
    usersToNotSync.forEach((user) => {
      if (user.action) _usersToProcess.push(user.uuid);
    });
    e.preventDefault();
    await apiGatewayCall(config.api_name, "/user-management/user-directory-mediapulse-sync/", "post", {
      body: { usersToProcess: _usersToProcess },
    })
      .then(() => {
        toggleAddUsersModal();
        getUsersToSync();
      })
      .catch((e) => console.log(e));
  };

  return (
    <section className="users-to-not-sync">
      <MDBBtn color="secondary" onClick={(e) => onAddUsersClick(e)}>
        Add more users
      </MDBBtn>
      <MDBModal size="fluid" isOpen={addUsersModalOpen} toggle={toggleAddUsersModal}>
        <MDBModalHeader>Users not flagged for MediaPulse import</MDBModalHeader>
        <MDBModalBody>
          <MDBDataTable
            data={buildTable}
            striped
            displayEntries={true}
            noBottomColumns={true}
            searching={false}
            sortable={true}
            paging={false}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={(e) => {
              toggleAddUsersModal();
            }}
          >
            Cancel
          </MDBBtn>
          <MDBBtn onClick={(e) => onConfirmClick(e)}>Go</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </section>
  );
};

export default UsersToNotSync;
