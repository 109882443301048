import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import setPageTitle from "../../helpers/setPageTitle";
import config from "../../content_types";
import { MDBDataTable, MDBBadge, MDBSelect } from "mdbreact";
import { NewUserModal } from "../../components/UserManagement/NewUserModal";
import showNotification from "../../helpers/showNotification";
import LoadingIcon from "../../components/LoadingIcon";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import sortArrayOfObjectsByKey from "../../helpers/sortArrayOfObjectsByKey";

export const UserManagementHome = () => {
  setPageTitle("User directory");
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [allUsers, setAllUsers] = useState({});

  const [rolesToFilterBy, setRolesToFilterBy] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState({});

  const [canCreate, setCanCreate] = useState(false);

  const getUsers = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/users/", "get")
      .then((response) => {
        setIsLoading(false);
        setAllUsers(response.users);
        setFilteredUsers(processUsers(response.users));
        setRolesToFilterBy(response.roles);
        setCanCreate(response.can_create);
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to display a list of users.", "warning");
      });
  };

  const processUsers = (users) => {
    const columns = [
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Email address",
        field: "email",
      },
      {
        label: "Cognito username",
        field: "username",
      },
      {
        label: "Roles",
        field: "roles",
        sort: "disabled",
      },
      {
        label: "Email verified",
        field: "emailVerified",
      },
      // {
      //   label: "Date created",
      //   field: "dateCreated",
      // },
      // {
      //   label: "Date modified",
      //   field: "dateModified",
      // },
    ];
    let rows = [];
    users.map((user) => {
      let userRow = {
        name: user.name,
        email: user.email,
        username: user.username,
        // dateCreated: moment(user.dateCreated).format("YYYY-MM-DD hh:mm:ss"),
        // dateModified: moment(user.dateModified).format("YYYY-MM-DD hh:mm:ss"),
        clickEvent: (e) => onUserClick(e, user.username),
      };

      let verifiedIcon = "times";
      let verifiedColor = "red-text";
      if (user.emailVerified === "true") {
        verifiedIcon = "check";
        verifiedColor = "green-text";
      }

      userRow.emailVerified = <MDBIcon icon={verifiedIcon} className={verifiedColor} />;

      let userRoles = [];
      user.roles.forEach((role) => {
        const userRoleKey = "role-" + user.username + "-" + role.name;
        userRoles.push(
          <MDBBadge key={userRoleKey} pill color="default" className="mr-1">
            {role.label}
          </MDBBadge>
        );
      });
      userRow.roles = userRoles;

      rows.push(userRow);
      return true;
    });
    rows = sortArrayOfObjectsByKey(rows, "name");
    return {
      columns: columns,
      rows: rows,
    };
  };

  const onUserClick = async (e, username) => {
    e.preventDefault();
    window.location = "/user-management/users/" + username;
  };

  let rolesFilterData = [];
  if (rolesToFilterBy.length) {
    rolesFilterData.push({ text: "All", value: "all" });
    rolesToFilterBy.forEach((role) => {
      rolesFilterData.push({ text: role.label, value: role.name });
    });
  }
  const onRoleFilterChange = (e) => {
    if (allUsers.length > 0 && e[0] !== undefined) {
      if (e[0] === "all") {
        setFilteredUsers(processUsers(allUsers));
      } else {
        let usersToProcess = [];
        allUsers.forEach((user) => {
          user.roles.forEach((role) => {
            if (role.name === e[0]) {
              usersToProcess.push(user);
            }
          });
        });
        setFilteredUsers(processUsers(usersToProcess));
      }
    }
  };

  let loadingContent = null;
  if (isLoading) loadingContent = <LoadingIcon />;

  return (
    <>
      {loadingContent}
      <MDBRow>
        <MDBCol>
          <h1 className="page-title">Take 1 user directory</h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBSelect
            options={rolesFilterData}
            selected="Filter by role"
            label="Filter by role"
            getValue={onRoleFilterChange}
          />
          <MDBDataTable
            key="current-users-table"
            hover
            entriesOptions={[50, 100]}
            entries={100}
            pagesAmount={4}
            data={filteredUsers}
            className="clickable-table"
            noBottomColumns={true}
            noRecordsFoundLabel="No users to display."
          />

          {canCreate && <NewUserModal setIsLoading={setIsLoading} getUsers={getUsers} />}
        </MDBCol>
      </MDBRow>
    </>
  );
};
