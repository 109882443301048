import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { withRouter, Redirect } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBSelect,
  MDBInput,
} from "mdbreact";
import uuid from "react-uuid";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import showNotification from "../../helpers/showNotification";

const WorkflowCreate = ({ content_type, setLoading }) => {
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [redirectToHome, setRedirectToHome] = useState(false);

  const [availableComponents, setAvailableComponents] = useState([]);
  const [sortableItems, setSortableItems] = useState([]);
  const [componentsToAdd, setComponentsToAdd] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [newWorkflowId, setNewWorkflowId] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/components/", "get")
      .then((response) => {
        setPageTitle("New workflow");
        setAvailableComponents(response.components);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to view components.", "warning");
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      let formValid = true;
      const requiredFields = [
        { field: "title", label: "Title", value: title },
        { field: "status", label: "Status", value: status },
      ];
      requiredFields.forEach((field) => {
        if (field.value === "") {
          showNotification("Error", "Please make sure the following fields are completed: " + field.label, "warning");
          formValid = false;
        }
      });
      // if (componentsToAdd.length === 0) {
      //   formValid = false;
      //   showNotification("Error", "Please make sure your workflow includes at least one component.", "warning");
      // } else {
      // componentsToAdd.forEach((component) => {
      //   if (!component.recipe) {
      //     showNotification("Error", "Please make sure each of your components has a recipe.", "warning");
      //     formValid = false;
      //   }
      // });
      // }
      return formValid;
    };

    if (validateForm()) {
      const jwt = await Auth.currentSession();

      let options = {
        body: {
          title: title,
          description: description,
          components: componentsToAdd,
          owner: jwt.accessToken.payload.username,
          version: 1,
        },
      };
      setLoading(true);
      await apiGatewayCall(config.api_name, "/workflow-management/workflows/", "post", options)
        .then((response) => {
          afterSuccessfulSubmit(response);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          showNotification("Access denied", "You do not have permission to create workflows.", "warning");
        });
    }
  };

  const afterSuccessfulSubmit = (response) => {
    setLoading(false);
    setNewWorkflowId(response.workflowId);
    setRedirectToEdit(true);
  };

  const SortableItem = ({ component, itemId }) => {
    let componentRecipeOptions = [];
    component.recipes.length > 0
      ? component.recipes.forEach((recipe) => {
          componentRecipeOptions.push({
            text: recipe.name,
            value: recipe.recipeId.toString(),
          });
        })
      : componentRecipeOptions.push({
          text: "No recipes available",
          value: null,
        });
    return (
      <MDBCard>
        <MDBCardHeader>
          <MDBCardTitle>{component.name}</MDBCardTitle>
          <MDBCardText>{component.description}</MDBCardText>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBSelect
            options={componentRecipeOptions}
            label="Recipe"
            getValue={(e) => updateRecipeSelection(itemId, e[0], component.componentId)}
          />
        </MDBCardBody>
        <MDBCardFooter>
          <MDBBtn onClick={(e) => removeComponent(itemId)}>Remove</MDBBtn>
        </MDBCardFooter>
      </MDBCard>
    );
  };

  const updateRecipeSelection = (itemId, recipe, componentId) => {
    setComponentsToAdd((oldComponentsToAdd) => {
      let newComponentsToAdd = [].concat(oldComponentsToAdd);
      let count = 0;
      oldComponentsToAdd.forEach((component) => {
        if (component.SortableItemId === itemId) {
          newComponentsToAdd[count].recipe = recipe;
          newComponentsToAdd[count].componentId = componentId;
        }
        count++;
      });
      return newComponentsToAdd;
    });
  };

  const removeComponent = (itemId) => {
    setSortableItems((oldSortableItems) => {
      return oldSortableItems.filter((item) => item.props.itemId !== itemId);
    });
    setComponentsToAdd((oldComponentsToAdd) => {
      return oldComponentsToAdd.filter((item) => item.SortableItemId !== itemId);
    });
  };

  let newComponentOptions = [];
  if (availableComponents.length) {
    availableComponents.forEach((component) => {
      // if (component.recipes.length) {
      newComponentOptions.push({
        text: component.name,
        value: component.componentId.toString(),
      });
      // }
    });
  }

  const addNewComponent = (componentId) => {
    availableComponents.forEach((availableComponent) => {
      if (availableComponent.componentId.toString() === componentId) {
        const newComponentId = uuid();
        let newSortableItems = [].concat(sortableItems);
        newSortableItems.push(
          <SortableItem component={availableComponent} itemId={newComponentId} key={newComponentId} />
        );

        let newComponentsToAdd = [].concat(componentsToAdd);
        newComponentsToAdd.push({
          SortableItemId: newComponentId,
          recipe: null,
          componentId: componentId,
        });

        setSortableItems(newSortableItems);
        setComponentsToAdd(newComponentsToAdd);
      }
    });
  };

  let pageContent = null;
  if (redirectToHome) {
    pageContent = <Redirect to="/workflow-configuration/" />;
  } else {
    if (redirectToEdit === true) {
      const newUrl = "/workflow-configuration/workflows/" + newWorkflowId;
      pageContent = <Redirect to={newUrl} />;
    } else {
      pageContent = (
        <>
          <h1 className="page-title">New workflow</h1>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <MDBInput type="text" value={title} getValue={setTitle} label="Title" required />
            <MDBInput type="textarea" value={description} getValue={setDescription} label="Description" />
            <MDBSelect
              options={[
                {
                  text: "Active",
                  value: "ACTIVE",
                },
                {
                  text: "In development",
                  value: "IN_DEVELOPMENT",
                  checked: true,
                },
                {
                  text: "Deprecated",
                  value: "DEPRECATED",
                },
              ]}
              getValue={(e) => setStatus(e[0])}
              label="Status"
            />
            <h3>Components</h3>
            <div className="components-to-add">
              {sortableItems}
              <MDBSelect
                options={newComponentOptions}
                selected="Choose a component"
                label="Add new component"
                getValue={(e) => addNewComponent(e[0])}
              />
              {/* <p className="small">
                Can't see your component listed here? Make sure there is at least one{" "}
                <a href="/workflow-configuration/recipes/">recipe</a> associated with it.
              </p> */}
            </div>

            <MDBBtn color="secondary" onClick={(e) => setRedirectToHome(true)}>
              Cancel
            </MDBBtn>
            <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} color="primary">
              Save
            </MDBBtn>
          </form>
        </>
      );
    }
  }

  return pageContent;
};

export default withRouter(WorkflowCreate);
