import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";
import setPageTitle from "../../helpers/setPageTitle";
import showNotification from "../../helpers/showNotification";
import ConfigurationSetInformation from "./new/ConfigurationSetInformation";
import ConfigurationSetData from "./ConfigurationSetData";
import ConfigurationSetPreview from "./ConfigurationSetPreview";
import ConfigurationSetMoreInformation from "./new/ConfigurationSetMoreInformation";
import queryString from "query-string";

const ConfigurationSetsNew = () => {
  setPageTitle("New configuration set");

  let query = useLocation();

  const [loading, setLoading] = useState(false);
  const [configurationSet, setConfigurationSet] = useState({
    name: "",
    owner: null,
    schema: null,
    values: {},
  });

  const [schemas, setSchemas] = useState(null);
  const [configurationSets, setConfigurationSets] = useState(null);
  const [userFullName, setUserFullName] = useState(null);
  const [existingOoonaGuidNames, setExistingOoonaGuidNames] = useState(null);

  const [defaultSchemaValue, setDefaultSchemaValue] = useState(null);

  const [savedConfigurationSetId, setSavedConfigurationSetId] = useState(null);
  const [savedModalOpen, setSavedModalOpen] = useState(false);

  useEffect(() => {
    getFormData();
    // eslint-disable-next-line
  }, []);

  const getFormData = async () => {
    setLoading(true);
    apiGatewayCall(config.api_name, "/configuration-sets/form-data", "get")
      .then((response) => {
        setLoading(false);
        setSchemas(response.schemas);
        setConfigurationSet((oldConfigurationSet) => {
          let _configurationSet = { ...oldConfigurationSet };
          _configurationSet.owner = response.user.uuid;

          query = queryString.parse(query.search);
          if (query.schemaId !== undefined) {
            setDefaultSchemaValue(query.schemaId);
            response.schemas.forEach((schema) => {
              if (schema.uuid === query.schemaId) {
                _configurationSet.schema = schema;
              }
            });
          } else {
            _configurationSet.schema = null;
          }

          return _configurationSet;
        });
        setConfigurationSets(response.configurationSets);
        setUserFullName(response.user.fullName);
        setExistingOoonaGuidNames(response.existingOoonaGuidNames);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/configuration-sets/", "post", { body: configurationSet })
        .then((response) => {
          setLoading(false);
          setSavedConfigurationSetId(response.configurationSet);
          setSavedModalOpen(true);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };

  const validateForm = () => {
    let valid = true;
    if (configurationSet.name === "") {
      valid = false;
      showNotification("Error", "Please make sure you enter a name.", "warning");
    }
    for (const value in configurationSet.values) {
      if (Object.hasOwnProperty.call(configurationSet.values, value)) {
        const valueKey = configurationSet.values[value];
        if (typeof valueKey === "object" && "ooonaGuidName" in valueKey) {
          if (existingOoonaGuidNames.includes(valueKey.ooonaGuidName)) {
            valid = false;
            showNotification(
              "Error",
              "An Ooona GUID with the name " + valueKey.ooonaGuidName + " already exists.",
              "warning"
            );
          }
        }
      }
    }
    return valid;
  };
  const updateConfigurationSetInformation = (field, newValue) => {
    if (newValue !== "0") {
      setConfigurationSet((oldConfigurationSet) => {
        let _configurationSet = { ...oldConfigurationSet };

        if (field === "schema" && newValue !== "0") {
          schemas.forEach((schema) => {
            if (schema.uuid === newValue) {
              newValue = schema;

              let schemaInputs = {};
              schema.inputs.forEach((input) => {
                if (input.type === "input") {
                  if (input.inputInstance.default !== "") {
                    schemaInputs[input.inputInstance.slug] = input.inputInstance.default;
                  } else {
                    schemaInputs[input.inputInstance.slug] = "";
                  }
                }
              });
              _configurationSet.values = schemaInputs;
            }
          });
        }

        _configurationSet[field] = newValue;
        return _configurationSet;
      });
    }
  };

  // let defaultSchemaValue;
  // configurationSet.schema !== null ? defaultSchemaValue = configurationSet.schema.uuid : defaultSchemaValue = null
  // if (configurationSet.schema !== null) defaultSchemaValue = configurationSet.schema.uuid;

  return (
    <article className="page page-new-configuration-set">
      <h1 className="page-title">New configuration set</h1>

      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a href="/">Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem>
          <a href="/configuration-sets">Configuration sets</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>New configuration set</MDBBreadcrumbItem>
      </MDBBreadcrumb>

      <MDBRow>
        <MDBCol size="7">
          <form className="new-schema-form" onSubmit={(e) => onSubmit(e)}>
            {schemas && (
              <ConfigurationSetInformation
                schemas={schemas}
                configurationSet={configurationSet}
                updateConfigurationSetInformation={updateConfigurationSetInformation}
                defaultSchemaValue={defaultSchemaValue}
              />
            )}
            {configurationSet.schema && configurationSets && (
              <ConfigurationSetData
                configurationSet={configurationSet}
                setConfigurationSet={setConfigurationSet}
                setLoading={setLoading}
                configurationSets={configurationSets}
                existingOoonaGuidNames={existingOoonaGuidNames}
                schemas={schemas}
                canEdit={true}
              />
            )}
            <footer className="mb-4">
              <MDBBtn tag="a" href="/configuration-sets" color="secondary">
                Back to configuration sets
              </MDBBtn>
              <MDBBtn type="submit" onClick={(e) => onSubmit(e)} className="float-right">
                Save
              </MDBBtn>
            </footer>
          </form>
        </MDBCol>
        <MDBCol size="5">
          <ConfigurationSetPreview configurationSet={configurationSet} />
          <ConfigurationSetMoreInformation configurationSet={configurationSet} userFullName={userFullName} />
        </MDBCol>
      </MDBRow>

      {loading && <LoadingIcon />}
      <MDBModal isOpen={savedModalOpen}>
        <MDBModalHeader>Configuration set saved</MDBModalHeader>
        <MDBModalBody>
          <p>{configurationSet.name} was successfully saved.</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" tag="a" href="/configuration-sets">
            Back to all configuration sets
          </MDBBtn>
          <MDBBtn color="default" tag="a" href={"/configuration-sets/" + savedConfigurationSetId}>
            Continue editing configuration set
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </article>
  );
};

export default ConfigurationSetsNew;
