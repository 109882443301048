import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBBtn, MDBCardBody, MDBIcon, MDBModal } from "mdbreact";
import { Auth, API } from "aws-amplify";
import config from "../content_types";

import HomepageCardsEditModal from "./HomepageCardsEditModal";
import HomepageCardsCreateModal from "./HomepageCardsCreateModal";

const HomepageCards = ({ tasks, canWrite, fetchData, cardCategories }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  let pageContent;

  const editLinkClick = (e, id) => {
    e.preventDefault();
    toggleEditModal();
    setCardId(id);
  };
  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };
  const toggleCreateModal = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const updateCard = async (taskDetails) => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
      },
      body: taskDetails,
    };
    await API.post(config.api_name, "/cards/" + taskDetails.uuid, requestOptions).then((response) => {
      if (response.statusCode === 403) {
        alert("Not authorised!");
        window.location.href = "/";
      } else {
        toggleEditModal();
        fetchData();
      }
    });
  };

  const createCard = async (taskDetails) => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
      },
      body: taskDetails,
    };
    await API.put(config.api_name, "/cards/", requestOptions).then((response) => {
      if (response.statusCode === 403) {
        alert("Not authorised!");
        window.location.href = "/";
      } else {
        toggleCreateModal();
        fetchData();
      }
    });
  };

  const deleteCard = async (uuid) => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
      },
    };
    await API.del(config.api_name, "/cards/" + uuid, requestOptions).then((response) => {
      if (response.statusCode === 403) {
        alert("Not authorised!");
        window.location.href = "/";
      } else {
        toggleEditModal();
        fetchData();
      }
    });
  };

  let cardsContent = [];
  let cardsDisplayed = [];

  if (tasks.length && cardCategories) {
    cardCategories.forEach((cardCategory) => {
      let cardCategoryContent = [];
      tasks.forEach((card) => {
        if (card.cardCategory === cardCategory.name) {
          cardCategoryContent.push(
            <MDBCol key={card.uuid} size="12" sm="6" lg="4">
              <MDBCard className="homepage-card">
                <MDBCardBody>
                  <MDBCardTitle tag="h3" className="h5">
                    <Link to={card.cardUrl}>
                      <MDBIcon icon={card.cardIcon} className="float-right ml-2" />
                      <span className="homepage-card-title">{card.cardTitle}</span>
                    </Link>
                  </MDBCardTitle>
                  <MDBBtn color="primary" href={card.cardUrl}>
                    Open
                  </MDBBtn>
                  {canWrite ? (
                    <a
                      href="#0"
                      onClick={(e) => editLinkClick(e, card.uuid)}
                      className="float-right homepage-card-edit"
                    >
                      <MDBIcon icon="edit" />
                    </a>
                  ) : (
                    ""
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          );
        }
      });
      if (!cardsDisplayed.includes(cardCategory.name)) {
        if (cardCategoryContent.length) {
          cardsContent.push(
            <div className="mb-4 homepage-card-category-group" key={cardCategory.name}>
              <MDBRow>
                <MDBCol>
                  <h2 className="mb-4 homepage-card-category-title h3">{cardCategory.label}</h2>
                </MDBCol>
              </MDBRow>
              <MDBRow>{cardCategoryContent}</MDBRow>
            </div>
          );
        }
      }
    });
  }
  pageContent = (
    <Fragment>
      {cardsContent}
      {canWrite ? (
        <Fragment>
          <MDBRow className="">
            <MDBCol>
              <MDBBtn onClick={(e) => toggleCreateModal()}>
                <MDBIcon icon="plus-circle" className="mr-2" />
                Add new
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBModal className="full-height" isOpen={editModalOpen} toggle={toggleEditModal} fetchData={fetchData}>
            <HomepageCardsEditModal
              toggleEditModal={toggleEditModal}
              uuid={cardId}
              updateCard={updateCard}
              deleteCard={deleteCard}
              cardCategories={cardCategories}
            />
          </MDBModal>
          <MDBModal className="full-height" isOpen={createModalOpen} toggle={toggleCreateModal}>
            <HomepageCardsCreateModal
              toggleCreateModal={toggleCreateModal}
              createCard={createCard}
              cardCategories={cardCategories}
            />
          </MDBModal>
        </Fragment>
      ) : (
        ""
      )}
    </Fragment>
  );

  return pageContent;
};

export default withRouter(HomepageCards);
