import React, { useState, useEffect } from "react";
import { MDBBtn, MDBInput, MDBSelect } from "mdbreact";
import { withRouter, Redirect, Link } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import DeleteButton from "../DeleteButton";

const WorkflowLocationsEdit = ({ content_type, match, setLoading }) => {
  const recordId = match.params.id;
  let content_config = config[content_type];

  const [redirectToHome, setRedirectToHome] = useState(false);

  const [currentWorkflowLocation, setCurrentWorkflowLocation] = useState({});
  const [locationId, setLocationId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  // eslint-disable-next-line
  const [content, setContent] = useState(null);

  const [inputContentPath, setInputContentPath] = useState(null);
  const [inputContentType, setInputContentType] = useState(null);
  const [inputContentRegion, setInputContentRegion] = useState(null);
  const [inputContentDirection, setInputContentDirection] = useState(null);

  const [formValues, setFormValues] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [canDelete, setCanDelete] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username,
      },
    };
    await API.get(config.api_name, content_config.edit.api_url + "/" + recordId, requestOptions).then((response) => {
      setLoading(false);
      if (response && response.workflow_location) {
        const contentValue = JSON.parse(response.workflow_location.content);
        setPageTitle(response.workflow_location.name);

        setCurrentWorkflowLocation(response.workflow_location);
        setLocationId(response.workflow_location.locationId);
        setName(response.workflow_location.name);
        setDescription(response.workflow_location.description);
        setContent(response.workflow_location.content);
        setInputContentPath(contentValue.path);
        setInputContentType(contentValue.type);
        setInputContentRegion(contentValue.region);
        setInputContentDirection(contentValue.direction);

        setFormValues(response.form_values);
        setCanEdit(response.can_edit);
        setCanDelete(response.can_delete);
      } else {
        setCurrentWorkflowLocation({});
      }
    });
  };

  const generateForm = () => {
    if (formValues) {
      let formContent = [];
      formContent.push(
        <MDBInput label="Name" value={name} onChange={(e) => setName(e.target.value)} key="input-name" />
      );
      formContent.push(
        <MDBInput
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          key="input-description"
        />
      );
      const contentTypeValues = [
        {
          text: "None",
          value: "",
        },
        {
          text: "AWS S3 bucket",
          value: "s3bucket",
        },
        {
          text: "UNC",
          value: "unc",
        },
      ];
      let contentTypeValuesCount = 0;
      contentTypeValues.forEach((value) => {
        if (value.value === inputContentType) {
          contentTypeValues[contentTypeValuesCount].checked = true;
        }
        contentTypeValuesCount++;
      });
      formContent.push(
        <MDBSelect
          options={contentTypeValues}
          label="Type"
          className="input-select"
          getValue={(e) => setInputContentType(e[0])}
          required={true}
          key="input_content_type"
        />
      );
      formContent.push(
        <div key="input_content_path">
          <MDBInput
            type="text"
            id="input_content_path"
            label="Path"
            valueDefault={inputContentPath}
            onChange={(e) => setInputContentPath(e.target.value)}
            className="input-text"
            required={true}
            key="input_content_path"
          />
        </div>
      );
      const contentRegionValues = [
        { text: "n/a", value: "" },
        { text: "Europe (London)", value: "eu-west-2" },
        { text: "US East (Ohio)", value: "us-east-2" },
        { text: "US East (N. Virginia)", value: "us-east-1" },
        { text: "US West (N. California)", value: "us-west-1" },
        { text: "US West (Oregon)", value: "us-west-2" },
        { text: "Asia Pacific (Hong Kong)***", value: "ap-east-1" },
        { text: "Asia Pacific (Mumbai)", value: "ap-south-1" },
        { text: "Asia Pacific (Osaka-Local)", value: "ap-northeast-3" },
        { text: "Asia Pacific (Seoul)", value: "ap-northeast-2" },
        { text: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
        { text: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
        { text: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
        { text: "Canada (Central)", value: "ca-central-1" },
        { text: "China (Beijing)", value: "cn-north-1" },
        { text: "China (Ningxia)", value: "cn-northwest-1" },
        { text: "Europe (Frankfurt)", value: "eu-central-1" },
        { text: "Europe (Ireland)", value: "eu-west-1" },
        { text: "Europe (Paris)", value: "eu-west-3" },
        { text: "Europe (Stockholm)", value: "eu-north-1" },
        { text: "South America (São Paulo)", value: "sa-east-1" },
        { text: "Middle East (Bahrain)", value: "me-south-1" },
      ];
      let contentRegionValuesCount = 0;
      contentRegionValues.forEach((value) => {
        if (value.value === inputContentRegion) {
          contentRegionValues[contentRegionValuesCount].checked = true;
        }
        contentRegionValuesCount++;
      });
      formContent.push(
        <div key="input_content_region">
          <MDBSelect
            options={contentRegionValues}
            label="Region"
            className="input-select"
            getValue={(e) => setInputContentRegion(e[0])}
            required={false}
            key="input_content_region"
          />
        </div>
      );
      const contentDirectionValues = [
        {
          text: "Source",
          value: "source",
        },
        {
          text: "Destination",
          value: "destination",
        },
      ];
      let contentDirectionValuesCount = 0;
      contentDirectionValues.forEach((value) => {
        if (value.value === inputContentDirection) {
          contentDirectionValues[contentDirectionValuesCount].checked = true;
        }
        contentDirectionValuesCount++;
      });
      formContent.push(
        <div key="input_content_direction">
          <MDBSelect
            options={contentDirectionValues}
            label="Direction"
            className="input-select"
            getValue={(e) => setInputContentDirection(e[0])}
            required={false}
            key="input_content_direction"
          />
        </div>
      );
      formContent.push(
        <MDBBtn tag={Link} to="/workflow-configuration/locations" key="button-back">
          Back
        </MDBBtn>
      );
      if (canDelete === true) {
        formContent.push(
          <DeleteButton
            content_type="workflow_locations"
            id={recordId}
            title={currentWorkflowLocation.name}
            setRedirectToHome={setRedirectToHome}
            key="button-delete"
          />
        );
      }
      if (canEdit === true) {
        formContent.push(
          <MDBBtn
            color="primary"
            type="submit"
            className="float-right"
            onClick={(e) => handleSubmit}
            key="button-submit"
          >
            Submit
          </MDBBtn>
        );
      }
      return formContent;
    }
  };

  const formatContentValues = () => {
    let contentValue = "{";
    contentValue = contentValue + '"path":"' + inputContentPath + '"';
    contentValue = contentValue + ",";
    contentValue = contentValue + '"type":"' + inputContentType + '"';
    contentValue = contentValue + ",";
    contentValue = contentValue + '"region":"' + inputContentRegion + '"';
    contentValue = contentValue + ",";
    contentValue = contentValue + '"direction":"' + inputContentDirection + '"';
    contentValue = contentValue + "}";

    return contentValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username,
      },
      body: {
        locationId: locationId,
        name: name,
        description: description,
        content: formatContentValues(),
      },
    };
    await API.post(config.api_name, content_config.edit.api_url + "/" + recordId, requestOptions).then((response) => {
      if (response.statusCode === 200) {
        afterSuccessfulSubmit();
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const afterSuccessfulSubmit = () => {
    setLoading(false);
    setRedirectToHome(true);
  };

  let pageContent = null;
  let formContent = generateForm();
  if (redirectToHome) {
    pageContent = <Redirect to="/workflow-configuration/locations" />;
  } else {
    pageContent = (
      <>
        <h1 className="page-title">{currentWorkflowLocation.name}</h1>
        <form onSubmit={handleSubmit}>{formContent}</form>
      </>
    );
  }
  return pageContent;
};

export default withRouter(WorkflowLocationsEdit);
