import React from "react";
import { MDBDataTable } from "mdbreact";

const WorkflowMonitoringListingTable = ({ records, onListingClick, idField, getMoreRecords }) => {
  const onPageChange = (e) => {
    if (e.activePage === e.pagesAmount - 1 || e.activePage === e.pagesAmount) {
      const lastRow = records[records.length - 1].inputInstanceId;
      getMoreRecords(lastRow);
    }
  };

  if (records && records.length > 0) {
    let columns = [];

    columns = [
      { label: "Input instance ID", field: "inputInstanceId" },
      { label: "Connection key", field: "connectionKey" },
      { label: "Registration info", field: "reg" },
      { label: "Start time", field: "startedAt" },
      { label: "Status", field: "status" },
    ];

    let recordsCount = 0;
    records.map((record) => {
      records[recordsCount].clickEvent = (e) => {
        onListingClick(e, record[idField]);
      };
      recordsCount++;
      return true;
    });

    let tableData = {};
    tableData.columns = columns;
    tableData.rows = records;

    return (
      <MDBDataTable
        barReverse
        responsive
        striped
        hover
        entries={20}
        entriesOptions={[20, 50, 100]}
        data={tableData}
        pagesAmount={20}
        className="clickable-table truncated-cells"
        onPageChange={(e) => onPageChange(e)}
        disableRetreatAfterSorting={true}
        searchLabel="Filter"
        info={false}
      />
    );
  } else {
    return <p>No results found.</p>;
  }
};

export default WorkflowMonitoringListingTable;
