import React from "react";
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBBtn } from "mdbreact";

const DatabaseSyncSelectQueryForm = ({ availableQueries, onSelectQueryFormSubmit, setSelectedSourceQuery }) => {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <form onSubmit={(e) => onSelectQueryFormSubmit(e)}>
          <MDBRow>
            <MDBCol size="12" md="10">
              <select
                name="select-query"
                id="select-query"
                className="custom-select"
                onChange={(e) => setSelectedSourceQuery(e.target.value)}
              >
                <option value="">Select a query to run</option>
                {availableQueries.map((query) => {
                  return (
                    <option key={query.name} value={query.name}>
                      {query.label}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
            <MDBCol size="12" md="2">
              <MDBBtn type="submit" className="my-0 float-right" block onClick={(e) => onSelectQueryFormSubmit(e)}>
                Go
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default DatabaseSyncSelectQueryForm;
