import React, { Fragment } from "react";
import setPageTitle from "../helpers/setPageTitle";
import { Link } from "react-router-dom";

import { MDBRow, MDBCol } from "mdbreact";

const NotFound = ({ location }) => {
  setPageTitle("404: Nothing found");
  return (
    <Fragment>
      <MDBRow>
        <MDBCol>
          <h1 className="page-title">404: Nothing found</h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <p>
            The URL <code>{location.pathname}</code> is not valid.
          </p>
          <p>
            <Link to="/" className="button btn-primary btn">
              Go home
            </Link>
          </p>
        </MDBCol>
      </MDBRow>
    </Fragment>
  );
};

export default NotFound;
