import React, { useState, useEffect } from "react";
import apiGatewayCall from "../../../helpers/apiGatewayCall";
import config from "../../../content_types";
import LoadingIcon from "../../../components/LoadingIcon";
import setPageTitle from "../../../helpers/setPageTitle";
import showNotification from "../../../helpers/showNotification";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import SchemasInputs from "./SchemasInputs";
import SchemaInformation from "../SchemaInformation";
import slugify from "slugify";
import SchemaPreview from "../edit/SchemaPreview";
import SchemaMoreInformation from "../SchemaMoreInformation";
import SchemaPermissions from "../SchemaPermissions";

/*
Schema family: Free text, autocompletes from existing families (forced lower case, "DNS compatible", i.e. no full stops, no spaces, just alphanumeric) - 16 characters
Schema subcategories: Free text, autocompletes from existing subcategories within the existing families - same validation rules as families
Schema name: Free text - same rules
*/

const SchemasNew = () => {
  setPageTitle("New schema");

  const [families, setFamilies] = useState(null);
  const [subcategories, setSubcategories] = useState(null);
  const [familyNames, setFamilyNames] = useState(null);
  const [subcategoryNames, setSubcategoryNames] = useState(null);
  const [availableInputs, setAvailableInputs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userFullName, setUserFullName] = useState(null);
  const [schema, setSchema] = useState({
    schemaFamily: null,
    schemaSubcategory: null,
    name: "",
    inputs: [],
    status: "in-development",
    owner: null,
    permissions: {
      schemaView: null,
      schemaEdit: null,
      configurationSetsView: null,
      configurationSetsEdit: null,
    },
  });
  const [configurationSets, setConfigurationSets] = useState(null);
  const [allRoles, setAllRoles] = useState(null);
  const [allSchemas, setAllSchemas] = useState(null)

  const [currentSchemaFamilyName, setCurrentSchemaFamilyName] = useState(null);
  const [currentSchemaSubcategoryName, setCurrentSchemaSubcategoryName] = useState(null);

  const [savedModalOpen, setSavedModalOpen] = useState(false);
  const [savedSchemaId, setSavedSchemaId] = useState("");

  useEffect(() => {
    getFormData();
    // eslint-disable-next-line
  }, []);

  const getFormData = async () => {
    setLoading(true);
    apiGatewayCall(config.api_name, "/schemas/form-data", "get")
      .then((response) => {
        setLoading(false);
        setFamilies(response.schemaFamilies);
        setSubcategories(response.schemaSubcategories);
        setAvailableInputs(response.inputs);
        setFamilyNames(() => {
          let _familyNames = [];
          response.schemaFamilies.forEach((family) => {
            _familyNames.push(family.name);
          });
          return _familyNames;
        });
        setSubcategoryNames(() => {
          let _subcategoryNames = [];
          response.schemaSubcategories.forEach((subcategory) => {
            _subcategoryNames.push(subcategory.name);
          });
          return _subcategoryNames;
        });
        setSchema((oldSchema) => {
          let _schema = { ...oldSchema };
          _schema.owner = response.user.uuid.S;
          return _schema;
        });
        setUserFullName(response.user.fullName.S);
        setConfigurationSets(response.configurationSets);
        setAllRoles(response.allRoles);
        setAllSchemas(response.allSchemas)
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/schemas/", "post", { body: schema })
        .then((response) => {
          setLoading(false);
          setSavedSchemaId(response.schema);
          setSavedModalOpen(true);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      showNotification(
        "Error",
        "Please make sure you enter a schema family, schema subcategory, and a schema name.",
        "warning"
      );
    }
  };

  const validateForm = () => {
    if (schema.schemaFamily && schema.schemaSubcategory && schema.name !== "" && schema.status) return true;
    else return false;
  };
  const updateSchemaData = (field, value) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      let newValue = value;

      if (["schemaFamily", "schemaSubcategory", "name"].includes(field)) {
        newValue = slugify(newValue, { replacement: "_", lower: true, remove: /[*+~.()'"!:@]/g });

        if (field === "schemaFamily") {
          setCurrentSchemaFamilyName(newValue);
          let _subcategoryNames = [];
          families.forEach((item) => {
            if (item.name === newValue) {
              newValue = item.uuid;
              subcategories.forEach((subcategory) => {
                if (subcategory.parent === item.uuid) {
                  _subcategoryNames.push(subcategory.name);
                }
              });
            }
          });
          setSubcategoryNames(_subcategoryNames);
        }
        if (field === "schemaSubcategory") {
          setCurrentSchemaSubcategoryName(newValue);
          subcategories.forEach((item) => {
            if (item.name === value) {
              newValue = item.uuid;
            }
          });
        }
      }

      _schema[field] = newValue;
      return _schema;
    });
  };

  return (
    <article className="page page-new-schema">
      <h1 className="page-title">New schema</h1>

      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a href="/">Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem>
          <a href="/schemas">Schemas</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>New schema</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <MDBRow>
        <MDBCol size="7">
          <form className="new-schema-form" onSubmit={(e) => onSubmit(e)}>
            <SchemaInformation
              familyNames={familyNames}
              subcategoryNames={subcategoryNames}
              schema={schema}
              updateSchemaData={updateSchemaData}
              userFullName={userFullName}
              currentSchemaFamilyName={currentSchemaFamilyName}
              currentSchemaSubcategoryName={currentSchemaSubcategoryName}
              canEdit={true}
            />
            {availableInputs && configurationSets && (
              <SchemasInputs
                availableInputs={availableInputs}
                schema={schema}
                setSchema={setSchema}
                configurationSets={configurationSets}
                allSchemas={allSchemas}
                canEdit={true}
              />
            )}
            <footer className="mb-4">
              <MDBBtn tag="a" href="/schemas" color="secondary">
                Back to Schemas
              </MDBBtn>
              <MDBBtn type="submit" onClick={(e) => onSubmit(e)} className="float-right">
                Save
              </MDBBtn>
            </footer>
          </form>
        </MDBCol>
        <MDBCol size="5">
          <SchemaPreview schema={schema} />
          {allRoles && <SchemaPermissions schema={schema} allRoles={allRoles} setSchema={setSchema} canEdit={true} />}
          <SchemaMoreInformation schema={schema} userFullName={userFullName} />
        </MDBCol>
      </MDBRow>
      {loading && <LoadingIcon />}
      <MDBModal isOpen={savedModalOpen}>
        <MDBModalHeader>Schema saved</MDBModalHeader>
        <MDBModalBody>
          <p>Schema {schema.name} was successfully saved.</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" tag="a" href="/schemas">
            Back to all schemas
          </MDBBtn>
          <MDBBtn color="default" tag="a" href={"/schemas/" + savedSchemaId}>
            Continue editing schema
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </article>
  );
};

export default SchemasNew;
