import React from "react";
import { Auth, API } from "aws-amplify";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from "mdbreact";

const DeleteWorkflowButton = ({ workflowItem, afterSuccessfulSubmit }) => {
  const deleteItem = async (id, type) => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken
      },
      body: {
        id: id,
        type: type
      }
    };
    await API.del("take-1-console", "/workflow-items/", requestOptions).then(
      response => {
        if (response.statusCode === 200) {
          afterSuccessfulSubmit();
        }
      }
    );
  };
  return (
    <MDBDropdown className="float-right">
      <MDBDropdownToggle caret color="danger" className="float-right">
        Delete
      </MDBDropdownToggle>
      <MDBDropdownMenu right basic>
        <MDBDropdownItem
          onClick={e => deleteItem(workflowItem.id, workflowItem.type)}
        >
          Delete {workflowItem.title}
        </MDBDropdownItem>
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};

export default DeleteWorkflowButton;
