import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import DeleteButton from "../../components/DeleteButton";
import { Auth, API } from "aws-amplify";
import LoadingIcon from "../../components/LoadingIcon";
import { Redirect, Link } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

const Edit = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [activeRecord, setActiveRecord] = useState({});
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [formValues, setFormValues] = useState([]);

  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [templateStructureSetId, setTemplateStructureSetId] = useState("");
  const [templateClipboardTypeId, setTemplateClipboardTypeId] = useState("");
  const [styleSetId, setStyleSetId] = useState("");
  const [subjectiveInstructionSetId, setSubjectiveInstructionSetId] = useState(
    ""
  );
  const [templateSpeakerRoleSetId, setTemplateSpeakerRoleSetId] = useState("");
  const [templateFootageTypeSetId, setTemplateFootageTypeSetId] = useState("");
  const [templateCSSOverrideSetId, setTemplateCSSOverrideSetId] = useState("");
  const [templateClipSetId, setTemplateClipSetId] = useState("");
  const [editType, setEditType] = useState("");
  const [
    temporaryShotLogDataFilename,
    setTemporaryShotLogDataFilename
  ] = useState("");

  const [
    templateStructureSetId_values,
    setTemplateStructureSetId_values
  ] = useState([]);
  const [
    templateClipboardTypeId_values,
    setTemplateClipboardTypeId_values
  ] = useState([]);
  const [styleSetId_values, setStyleSetId_values] = useState([]);
  const [
    subjectiveInstructionSetId_values,
    setSubjectiveInstructionSetId_values
  ] = useState([]);
  const [
    templateSpeakerRoleSetId_values,
    setTemplateSpeakerRoleSetId_values
  ] = useState([]);
  const [
    templateFootageTypeSetId_values,
    setTemplateFootageTypeSetId_values
  ] = useState([]);
  const [
    templateCSSOverrideSetId_values,
    setTemplateCSSOverrideSetId_values
  ] = useState([]);
  const [templateClipSetId_values, setTemplateClipSetId_values] = useState([]);
  const [editType_values, setEditType_values] = useState([]);

  const [canWrite, setCanWrite] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const recordId = match.params.id;

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    const response = await API.get(
      config.api_name,
      config.ingredient_sets.edit.api_url + "/" + recordId,
      requestOptions
    );
    if (response) {
      if (response.statusCode === 200) {
        setPageTitle(response.template_raw.displayName);
        setActiveRecord(response.template_raw);

        setTemplateStructureSetId_values(
          response.template_form_values.templateStructureSetId
        );
        setTemplateClipboardTypeId_values(
          response.template_form_values.templateClipboardTypeId
        );
        setStyleSetId_values(response.template_form_values.styleSetId);
        setSubjectiveInstructionSetId_values(
          response.template_form_values.subjectiveInstructionSetId
        );
        setTemplateSpeakerRoleSetId_values(
          response.template_form_values.templateSpeakerRoleSetId
        );
        setTemplateFootageTypeSetId_values(
          response.template_form_values.templateFootageTypeSetId
        );
        setTemplateCSSOverrideSetId_values(
          response.template_form_values.templateCSSOverrideSetId
        );
        setTemplateClipSetId_values(
          response.template_form_values.templateClipSetId
        );
        setEditType_values(response.template_form_values.editType);

        setName(response.template_raw.name);
        setDisplayName(response.template_raw.displayName);
        if (response.template_raw.description !== null) {
          setDescription(response.template_raw.description);
        }
        setTemplateStructureSetId(response.template_raw.templateStructureSetId);
        setTemplateClipboardTypeId(
          response.template_raw.templateClipboardTypeId
        );
        setStyleSetId(response.template_raw.styleSetId);
        setSubjectiveInstructionSetId(
          response.template_raw.subjectiveInstructionSetId
        );
        setTemplateSpeakerRoleSetId(
          response.template_raw.templateSpeakerRoleSetId
        );
        setTemplateFootageTypeSetId(
          response.template_raw.templateFootageTypeSetId
        );
        setTemplateCSSOverrideSetId(
          response.template_raw.templateCSSOverrideSetId
        );
        setTemplateClipSetId(response.template_raw.templateClipSetId);
        setEditType(response.template_raw.editType);
        setTemporaryShotLogDataFilename(
          response.template_raw.temporaryShotLogDataFilename
        );

        setFormValues(response.template_form_values);

        setLoading(false);

        setCanWrite(response.can_write);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        ingredientSetId: recordId,
        name: name,
        displayName: displayName,
        description: description,
        // creationDate: creationDate,
        templateStructureSetId: templateStructureSetId,
        templateClipboardTypeId: templateClipboardTypeId,
        styleSetId: styleSetId,
        subjectiveInstructionSetId: subjectiveInstructionSetId,
        templateSpeakerRoleSetId: templateSpeakerRoleSetId,
        templateFootageTypeSetId: templateFootageTypeSetId,
        templateCSSOverrideSetId: templateCSSOverrideSetId,
        templateClipSetId: templateClipSetId,
        editType: editType,
        temporaryShotLogDataFilename: temporaryShotLogDataFilename
      }
    };

    await API.post(
      "take-1-console",
      "/ingredient-sets/" + recordId,
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        afterSuccessfulSubmit();
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const afterSuccessfulSubmit = () => {
    setLoading(false);
    setRedirectToHome(true);
  };

  let editForm = "";

  if (loading) {
    editForm = <LoadingIcon />;
  } else {
    if (redirectToHome === true) {
      editForm = <Redirect to="/ingredient-sets" />;
    } else {
      if (activeRecord) {
        editForm = (
          <>
            <MDBRow>
              <MDBCol>
                <h1 className="page-title">Editing {activeRecord.displayName}</h1>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <MDBInput
                      label="Name"
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <MDBInput
                      label="Display name"
                      type="text"
                      value={displayName}
                      onChange={e => setDisplayName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <MDBInput
                      label="Description"
                      type="text"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </div>

                  {templateStructureSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="templateStructureSetId">
                        Template structure set
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="templateStructureSetId"
                        value={templateStructureSetId}
                        onChange={e =>
                          setTemplateStructureSetId(e.target.value)
                        }
                      >
                        {formValues.templateStructureSetId.map(option => {
                          return (
                            <option
                              value={option.templateStructureSetId}
                              key={option.templateStructureSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {templateClipboardTypeId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="templateClipboardTypeId">
                        Template clipboard type
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="templateClipboardTypeId"
                        value={templateClipboardTypeId}
                        onChange={e =>
                          setTemplateClipboardTypeId(e.target.value)
                        }
                      >
                        {formValues.templateClipboardTypeId.map(option => {
                          return (
                            <option
                              value={option.templateClipboardTypeId}
                              key={option.templateClipboardTypeId}
                            >
                              {option.name} ({option.description})
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {styleSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="styleSetId">Style set</label>
                      <select
                        className="browser-default custom-select"
                        id="styleSetId"
                        value={styleSetId}
                        onChange={e => setStyleSetId(e.target.value)}
                      >
                        {formValues.styleSetId.map(option => {
                          return (
                            <option
                              value={option.styleSetId}
                              key={option.styleSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {subjectiveInstructionSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="subjectiveInstructionSetId">
                        Subjective instruction set
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="subjectiveInstructionSetId"
                        value={subjectiveInstructionSetId}
                        onChange={e =>
                          setSubjectiveInstructionSetId(e.target.value)
                        }
                      >
                        {formValues.subjectiveInstructionSetId.map(option => {
                          return (
                            <option
                              value={option.subjectiveInstructionSetId}
                              key={option.subjectiveInstructionSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {templateSpeakerRoleSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="templateSpeakerRoleSetId">
                        Template speaker role set
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="templateSpeakerRoleSetId"
                        value={templateSpeakerRoleSetId}
                        onChange={e =>
                          setTemplateSpeakerRoleSetId(e.target.value)
                        }
                      >
                        {formValues.templateSpeakerRoleSetId.map(option => {
                          return (
                            <option
                              value={option.templateSpeakerRoleSetId}
                              key={option.templateSpeakerRoleSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {templateFootageTypeSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="templateFootageTypeSetId">
                        Template footage type set
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="templateFootageTypeSetId"
                        value={templateFootageTypeSetId}
                        onChange={e =>
                          setTemplateFootageTypeSetId(e.target.value)
                        }
                      >
                        {formValues.templateFootageTypeSetId.map(option => {
                          return (
                            <option
                              value={option.templateFootageTypeSetId}
                              key={option.templateFootageTypeSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {templateCSSOverrideSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="templateCSSOverrideSetId">
                        Template CSS override set
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="templateCSSOverrideSetId"
                        value={templateCSSOverrideSetId}
                        onChange={e =>
                          setTemplateCSSOverrideSetId(e.target.value)
                        }
                      >
                        {formValues.templateCSSOverrideSetId.map(option => {
                          return (
                            <option
                              value={option.templateCSSOverrideSetId}
                              key={option.templateCSSOverrideSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {templateClipSetId_values.length ? (
                    <div className="form-group">
                      <label htmlFor="templateClipSetId">
                        Template clip set
                      </label>
                      <select
                        className="browser-default custom-select"
                        id="templateClipSetId"
                        value={templateClipSetId}
                        onChange={e => setTemplateClipSetId(e.target.value)}
                      >
                        {formValues.templateClipSetId.map(option => {
                          return (
                            <option
                              value={option.templateClipSetId}
                              key={option.templateClipSetId}
                            >
                              {option.displayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {editType_values.length ? (
                    <div className="form-group">
                      <label htmlFor="editType">Edit type</label>
                      <select
                        className="browser-default custom-select"
                        id="editType"
                        value={editType}
                        onChange={e => setEditType(e.target.value)}
                      >
                        {formValues.editType.map(option => {
                          return (
                            <option
                              value={option.editTypeId}
                              key={option.editTypeId}
                            >
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="form-group">
                    <MDBInput
                      label="Temporary shot-log data filename"
                      type="text"
                      value={temporaryShotLogDataFilename}
                      onChange={e =>
                        setTemporaryShotLogDataFilename(e.target.value)
                      }
                    />
                  </div>

                  <MDBBtn tag={Link} to="/ingredient-sets">
                    Back
                  </MDBBtn>
                  {canWrite ? (
                    <>
                      <DeleteButton
                        content_type="ingredient_sets"
                        id={recordId}
                        title={displayName}
                        setRedirectToHome={setRedirectToHome}
                      />
                      <MDBBtn
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Submit
                      </MDBBtn>
                    </>
                  ) : (
                    ""
                  )}
                </form>
              </MDBCol>
            </MDBRow>
          </>
        );
      }
    }
  }
  return <>{editForm}</>;
};

export default Edit;
