const sortArrayOfObjectsByKey = (array, field) => {
  return array.sort(function (a, b) {
    var x = a[field];
    var y = b[field];

    if (typeof x == "string") {
      x = ("" + x).toLowerCase();
    }
    if (typeof y == "string") {
      y = ("" + y).toLowerCase();
    }

    return x < y ? -1 : x > y ? 1 : 0;
  });
};
export default sortArrayOfObjectsByKey;
