import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption
} from "mdbreact";
import { Auth, API } from "aws-amplify";
import LoadingIcon from "../../components/LoadingIcon";
import { Redirect, Link } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

const IngredientSetsNew = ({ match }) => {
  setPageTitle("New ingredient set");
  const [loading, setLoading] = useState(false);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [newIngredientSetId, setNewIngredientSetId] = useState(null);
  const [formValues, setFormValues] = useState([]);

  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [templateStructureSetId, setTemplateStructureSetId] = useState("");
  const [templateClipboardTypeId, setTemplateClipboardTypeId] = useState("");
  const [styleSetId, setStyleSetId] = useState("");
  const [subjectiveInstructionSetId, setSubjectiveInstructionSetId] = useState(
    ""
  );
  const [templateSpeakerRoleSetId, setTemplateSpeakerRoleSetId] = useState("");
  const [templateFootageTypeSetId, setTemplateFootageTypeSetId] = useState("");
  const [templateCSSOverrideSetId, setTemplateCSSOverrideSetId] = useState("");
  const [templateClipSetId, setTemplateClipSetId] = useState("");
  const [editType, setEditType] = useState("");
  const [
    temporaryShotLogDataFilename,
    setTemporaryShotLogDataFilename
  ] = useState("");

  const [
    templateStructureSetId_values,
    setTemplateStructureSetId_values
  ] = useState([]);
  const [
    templateClipboardTypeId_values,
    setTemplateClipboardTypeId_values
  ] = useState([]);
  const [styleSetId_values, setStyleSetId_values] = useState([]);
  const [
    subjectiveInstructionSetId_values,
    setSubjectiveInstructionSetId_values
  ] = useState([]);
  const [
    templateSpeakerRoleSetId_values,
    setTemplateSpeakerRoleSetId_values
  ] = useState([]);
  const [
    templateFootageTypeSetId_values,
    setTemplateFootageTypeSetId_values
  ] = useState([]);
  const [
    templateCSSOverrideSetId_values,
    setTemplateCSSOverrideSetId_values
  ] = useState([]);
  const [templateClipSetId_values, setTemplateClipSetId_values] = useState([]);
  const [editType_values, setEditType_values] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    const response = await API.get(
      config.api_name,
      config.ingredient_sets.edit.api_url + "/6",
      requestOptions
    );
    if (response) {
      if (response.can_write === true) {
        setTemplateStructureSetId_values(
          response.template_form_values.templateStructureSetId
        );
        setTemplateClipboardTypeId_values(
          response.template_form_values.templateClipboardTypeId
        );
        setStyleSetId_values(response.template_form_values.styleSetId);
        setSubjectiveInstructionSetId_values(
          response.template_form_values.subjectiveInstructionSetId
        );
        setTemplateSpeakerRoleSetId_values(
          response.template_form_values.templateSpeakerRoleSetId
        );
        setTemplateFootageTypeSetId_values(
          response.template_form_values.templateFootageTypeSetId
        );
        setTemplateCSSOverrideSetId_values(
          response.template_form_values.templateCSSOverrideSetId
        );
        setTemplateClipSetId_values(
          response.template_form_values.templateClipSetId
        );
        setEditType_values(response.template_form_values.editType);
        setFormValues(response.template_form_values);

        setTemplateStructureSetId(
          response.template_form_values.templateStructureSetId[0]
            .templateStructureSetId
        );
        setTemplateClipboardTypeId(
          response.template_form_values.templateClipboardTypeId[0]
            .templateClipboardTypeId
        );
        setStyleSetId(response.template_form_values.styleSetId[0].styleSetId);
        setSubjectiveInstructionSetId(
          response.template_form_values.subjectiveInstructionSetId[0]
            .subjectiveInstructionSetId
        );
        setTemplateSpeakerRoleSetId(
          response.template_form_values.templateSpeakerRoleSetId[0]
            .templateSpeakerRoleSetId
        );
        setTemplateFootageTypeSetId(
          response.template_form_values.templateFootageTypeSetId[0]
            .templateFootageTypeSetId
        );
        setTemplateCSSOverrideSetId(
          response.template_form_values.templateCSSOverrideSetId[0]
            .templateCSSOverrideSetId
        );
        setTemplateClipSetId(
          response.template_form_values.templateClipSetId[0].templateClipSetId
        );
        setEditType(response.template_form_values.editType[0].editType);

        setLoading(false);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        name: name,
        displayName: displayName,
        description: description,
        templateStructureSetId: templateStructureSetId,
        templateClipboardTypeId: templateClipboardTypeId,
        styleSetId: styleSetId,
        subjectiveInstructionSetId: subjectiveInstructionSetId,
        templateSpeakerRoleSetId: templateSpeakerRoleSetId,
        templateFootageTypeSetId: templateFootageTypeSetId,
        templateCSSOverrideSetId: templateCSSOverrideSetId,
        templateClipSetId: templateClipSetId,
        editType: editType,
        temporaryShotLogDataFilename: temporaryShotLogDataFilename
      }
    };
    await API.put("take-1-console", "/ingredient-sets/", requestOptions).then(
      response => {
        if (response.statusCode === 201) {
          afterSuccessfulSubmit(response);
        } else {
          alert("Not authorised!");
          window.location.href = "/";
        }
      }
    );
  };

  const afterSuccessfulSubmit = response => {
    setLoading(false);
    setNewIngredientSetId(response.new_ingredient_set);
    setRedirectToEdit(true);
  };

  let editForm = "";

  if (loading) {
    editForm = <LoadingIcon />;
  } else {
    if (redirectToEdit === true) {
      const newUrl = "/ingredient-sets/" + newIngredientSetId;
      editForm = <Redirect to={newUrl} />;
    } else {
      editForm = (
        <>
          <MDBRow>
            <MDBCol>
              <h1 className="page-title">New ingredient set</h1>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol sm="12" xl="6">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <MDBInput
                    label="Name"
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <MDBInput
                    label="Display name"
                    type="text"
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <MDBInput
                    label="Description"
                    type="text"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </div>

                {templateStructureSetId_values.length ? (
                  <MDBSelect
                    label="Template structure set"
                    getValue={setTemplateStructureSetId}
                  >
                    <MDBSelectInput selected="Template structure set" />
                    <MDBSelectOptions>
                      {formValues.templateStructureSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.templateStructureSetId}
                            key={option.templateStructureSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {templateClipboardTypeId_values.length ? (
                  <MDBSelect
                    label="Template clipboard type"
                    getValue={setTemplateClipboardTypeId}
                  >
                    <MDBSelectInput selected="Template clipboard type" />
                    <MDBSelectOptions>
                      {formValues.templateClipboardTypeId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.templateClipboardTypeId}
                            key={option.templateClipboardTypeId}
                          >
                            {option.description}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {styleSetId_values.length ? (
                  <MDBSelect label="Style set" getValue={setStyleSetId}>
                    <MDBSelectInput selected="Style set" />
                    <MDBSelectOptions>
                      {formValues.styleSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.styleSetId}
                            key={option.styleSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {subjectiveInstructionSetId_values.length ? (
                  <MDBSelect
                    label="Subjective instruction set"
                    getValue={setStyleSetId}
                  >
                    <MDBSelectInput selected="Subjective instruction set" />
                    <MDBSelectOptions>
                      {formValues.subjectiveInstructionSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.subjectiveInstructionSetId}
                            key={option.subjectiveInstructionSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {templateSpeakerRoleSetId_values.length ? (
                  <MDBSelect
                    label="Template speaker role set"
                    getValue={setTemplateSpeakerRoleSetId}
                  >
                    <MDBSelectInput selected="Template speaker role set" />
                    <MDBSelectOptions>
                      {formValues.templateSpeakerRoleSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.templateSpeakerRoleSetId}
                            key={option.templateSpeakerRoleSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {templateFootageTypeSetId_values.length ? (
                  <MDBSelect
                    label="Template footage type set"
                    getValue={setTemplateFootageTypeSetId}
                  >
                    <MDBSelectInput selected="Template footage type set" />
                    <MDBSelectOptions>
                      {formValues.templateFootageTypeSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.templateFootageTypeSetId}
                            key={option.templateFootageTypeSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {templateCSSOverrideSetId_values.length ? (
                  <MDBSelect
                    label="Template CSS override set"
                    getValue={setTemplateCSSOverrideSetId}
                  >
                    <MDBSelectInput selected="Template CSS override set" />
                    <MDBSelectOptions>
                      {formValues.templateCSSOverrideSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.templateCSSOverrideSetId}
                            key={option.templateCSSOverrideSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {templateClipSetId_values.length ? (
                  <MDBSelect
                    label="Template clip set"
                    getValue={setTemplateClipSetId}
                  >
                    <MDBSelectInput selected="Template clip set" />
                    <MDBSelectOptions>
                      {formValues.templateClipSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.templateClipSetId}
                            key={option.templateClipSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {editType_values.length ? (
                  <MDBSelect label="Edit type" getValue={setEditType}>
                    <MDBSelectInput selected="Edit type" />
                    <MDBSelectOptions>
                      {formValues.editType.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.editTypeId}
                            key={option.editTypeId}
                          >
                            {option.name}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <MDBInput
                    label="Temporary shot-log data filename"
                    type="text"
                    value={temporaryShotLogDataFilename}
                    onChange={e =>
                      setTemporaryShotLogDataFilename(e.target.value)
                    }
                  />
                </div>

                <MDBBtn tag={Link} to="/capture-instruction-templates">
                  Back
                </MDBBtn>
                <MDBBtn color="primary" type="submit" className="float-right">
                  Submit
                </MDBBtn>
              </form>
            </MDBCol>
          </MDBRow>
        </>
      );
    }
  }
  return <>{editForm}</>;
};

export default IngredientSetsNew;
