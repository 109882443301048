import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import slugify from "slugify";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from "mdbreact";

const EditProperty = ({
  propertyToEdit,
  setPropertyToEdit,
  editModalOpen,
  setEditModalOpen,
  onSaveProperty,
  canEdit,
  canDelete,
  onDeletePropertyClick,
  propertyTypes,
}) => {
  const selectMetadataFormClass = () => {
    if ("metadata" in updatedPropertyToEdit) {
      return "d-block mt-4 mb-4";
    } else {
      return "d-none";
    }
  };

  const [propertyMetadata, setPropertyMetadata] = useState({ value: "", label: "" });
  const [updatedPropertyToEdit, setUpdatedPropertyToEdit] = useState({ ...propertyToEdit });
  const [propertyMetadataFormDisplayClass, setPropertyMetadataFormClass] = useState(selectMetadataFormClass());

  const inputTypes = [
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Number",
      value: "number",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Select dropdown (single choice)",
      value: "select",
    },
    {
      label: "Select dropdown (multiple choice)",
      value: "multiple",
    },
    {
      label: "Checkbox",
      value: "checkbox",
    },
  ];

  const toggleEditModal = () => {
    setPropertyToEdit(null);
    setEditModalOpen(!editModalOpen);
  };

  const onFormChange = (e) => {
    let newUpdatedPropertyToEdit = { ...updatedPropertyToEdit };
    let field_value;
    const field_name = e.target.name;
    if ("type" in e.target && e.target.type === "checkbox") {
      field_value = e.target.checked;
    } else {
      field_value = e.target.value;
    }

    if (field_name === "inputType") {
      if (field_value === "select" || field_value === "multiple") {
        setPropertyMetadataFormClass("d-block mt-4 mb-4");
      } else {
        setPropertyMetadataFormClass("d-none");
      }
    }

    newUpdatedPropertyToEdit[field_name] = field_value;
    if (field_name === "label") {
      const slugifiedLabel = slugify(field_value, { replacement: "_", lower: true });
      newUpdatedPropertyToEdit.name = slugifiedLabel;
    }
    setUpdatedPropertyToEdit(newUpdatedPropertyToEdit);
  };

  const updatePropertyMetadata = (field, value) => {
    let newPropertyMetadata = { ...propertyMetadata };
    newPropertyMetadata[field] = value;
    if (field === "label") {
      const slugifiedLabel = slugify(value, { replacement: "_", lower: true });
      newPropertyMetadata.value = slugifiedLabel;
    }
    setPropertyMetadata(newPropertyMetadata);
  };

  const onSaveNewMetadata = async (e) => {
    e.preventDefault();
    let newUpdatedPropertyToEdit = { ...updatedPropertyToEdit };
    if (newUpdatedPropertyToEdit.metadata === undefined) {
      newUpdatedPropertyToEdit.metadata = [];
    }
    newUpdatedPropertyToEdit.metadata.push(propertyMetadata);
    setPropertyMetadata({ value: "", label: "" });
    setUpdatedPropertyToEdit(newUpdatedPropertyToEdit);
  };

  const onDeleteMetadataItem = (e, value) => {
    e.preventDefault();
    let newUpdatedPropertyToEdit = { ...updatedPropertyToEdit };
    newUpdatedPropertyToEdit.metadata = newUpdatedPropertyToEdit.metadata.filter(
      (metadataItem) => metadataItem.value !== value
    );
    setUpdatedPropertyToEdit(newUpdatedPropertyToEdit);
  };

  const onEditFormSubmit = (e) => {
    e.preventDefault();
    onSaveProperty(updatedPropertyToEdit);
  };

  let propertyMetadataItems = [];
  if (updatedPropertyToEdit && "metadata" in updatedPropertyToEdit) {
    // setPropertyMetadataFormClass("d-block mt-4 mb-4");
    updatedPropertyToEdit.metadata.forEach((metadataItem) => {
      propertyMetadataItems.push(
        <tr key={metadataItem.value}>
          <td>{metadataItem.label}</td>
          <td>{metadataItem.value}</td>
          <td>
            <MDBIcon
              icon="times"
              className="clickable float-right red-text"
              onClick={(e) => onDeleteMetadataItem(e, metadataItem.value)}
            />
          </td>
        </tr>
      );
    });
  }
  const propertyMetadataTable = (
    <table width="100%">
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{propertyMetadataItems}</tbody>
    </table>
  );

  return (
    <MDBModal isOpen={editModalOpen} toggle={toggleEditModal}>
      <MDBModalHeader>Edit {propertyToEdit.label}</MDBModalHeader>
      <form onSubmit={(e) => onEditFormSubmit(e)}>
        <MDBModalBody>
          <MDBInput
            label="Label"
            type="text"
            required={true}
            value={updatedPropertyToEdit.label}
            getValue={(e) => {
              const event = {
                target: {
                  name: "label",
                  value: e,
                },
              };
              onFormChange(event);
            }}
            key="input-label"
            name="label"
          />
          <MDBInput
            label="Name"
            type="text"
            required={true}
            value={updatedPropertyToEdit.name}
            getValue={(e) => {
              const event = {
                target: {
                  name: "name",
                  value: e,
                },
              };
              onFormChange(event);
            }}
            key="input-name"
            name="name"
          />
          <MDBInput
            label="Description (optional)"
            type="text"
            required={false}
            value={updatedPropertyToEdit.description}
            getValue={(e) => {
              const event = {
                target: {
                  name: "description",
                  value: e,
                },
              };
              onFormChange(event);
            }}
            key="input-description"
            name="description"
          />

          {/* <div className="md-form"> */}
            <label htmlFor="select-propertyType">Property type</label>
            <select
              className="browser-default custom-select"
              onChange={(e) => {
                const event = {
                  target: {
                    name: "propertyType",
                    value: e.target.value,
                  },
                };
                onFormChange(event);
              }}
              value={updatedPropertyToEdit.propertyType}
              id="select-propertyType"
            >
              {propertyTypes.map((propertyType) => {
                return (
                  <option value={propertyType.value} key={propertyType.value}>
                    {propertyType.label}
                  </option>
                );
              })}
            </select>
          {/* </div> */}

          {/* <MDBInput
            label="Property type"
            type="text"
            required={false}
            value={updatedPropertyToEdit.propertyType}
            getValue={(e) => {
              const event = {
                target: {
                  name: "propertyType",
                  value: e,
                },
              };
              onFormChange(event);
            }}
            key="input-property-type"
            name="propertyType"
          /> */}
          <div className="md-form">
            <MDBInput
              label="Personally identifiable information (PII)"
              type="checkbox"
              id="pii"
              onChange={(e) => onFormChange(e)}
              key="input-pii"
              name="pii"
              checked={updatedPropertyToEdit.pii}
            />
          </div>

          {/* <div className="md-form"> */}
            <label htmlFor="select-inputType">Input type</label>
            <select
              className="browser-default custom-select"
              onChange={(e) => {
                const event = {
                  target: {
                    name: "inputType",
                    value: e.target.value,
                  },
                };
                onFormChange(event);
              }}
              value={updatedPropertyToEdit.inputType}
              id="select-inputType"
            >
              {inputTypes.map((inputType) => {
                return (
                  <option value={inputType.value} key={inputType.value}>
                    {inputType.label}
                  </option>
                );
              })}
            </select>
          {/* </div> */}

          <MDBCard className={propertyMetadataFormDisplayClass}>
            <MDBCardBody>
              <MDBCardTitle>Available options</MDBCardTitle>
              <form onSubmit={(e) => onSaveNewMetadata(e)}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Label"
                      value={propertyMetadata.label}
                      getValue={(e) => updatePropertyMetadata("label", e)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBInput
                      label="Value"
                      value={propertyMetadata.value}
                      getValue={(e) => updatePropertyMetadata("value", e)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBBtn type="submit" onClick={(e) => onSaveNewMetadata(e)}>
                      Save
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>
              {propertyMetadataTable}
            </MDBCardBody>
          </MDBCard>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              setPropertyToEdit(null);
              toggleEditModal();
            }}
          >
            Close
          </MDBBtn>
          {canDelete && (
            <MDBBtn
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                setPropertyToEdit(null);
                onDeletePropertyClick(propertyToEdit);
                toggleEditModal();
              }}
            >
              Delete
            </MDBBtn>
          )}

          {canEdit && (
            <MDBBtn type="submit" onClick={(e) => onEditFormSubmit(e)}>
              OK
            </MDBBtn>
          )}
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

export default EditProperty;
