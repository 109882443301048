import React, { useState, useEffect } from 'react';
import { MDBContainer } from 'mdbreact';
import { Auth } from 'aws-amplify';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { BrowserRouter as Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
import Header from './components/Header';

import { createBrowserHistory } from 'history';

import Home from './pages/Home';
import PhoneList from './pages/PhoneList';
import ServiceTemplatesListing from './pages/service-templates/ServiceTemplatesListing';
import ServiceTemplatesEdit from './pages/service-templates/ServiceTemplatesEdit';
import ServiceTemplatesNew from './pages/service-templates/ServiceTemplatesNew';
import CaptureInstructionTemplatesListing from './pages/capture-instruction-templates/CaptureInstructionTemplatesListing';
import CaptureInstructionTemplatesEdit from './pages/capture-instruction-templates/CaptureInstructionTemplatesEdit';
import CaptureInstructionTemplatesNew from './pages/capture-instruction-templates/CaptureInstructionTemplatesNew';
import IngredientSetListing from './pages/ingredient-sets/IngredientSetsListing';
import IngredientSetEdit from './pages/ingredient-sets/IngredientSetsEdit';
import IngredientSetNew from './pages/ingredient-sets/IngredientSetsNew';
import WorkflowMonitoringListing from './pages/workflow-monitoring/WorkflowMonitoringListing';
import NotFound from './pages/NotFound';
import { UserManagementHome } from './pages/user-management/UserManagementHome';
import { RolesPermissionsHome } from './pages/user-management/RolesPermissionsHome';
import EditUser from './components/UserManagement/EditUser/EditUser';
import TaskListbyUser from './components/TaskManagement/TaskListbyUser';
import ProductionEmailTracking from './pages/production-email-tracking/ProductionEmailTracking';
import Take1ConsoleSearch from './pages/search/Take1ConsoleSearch';

import LoginForm from './components/LoginForm';
import LoadingIcon from './components/LoadingIcon';
import apiGatewayCall from './helpers/apiGatewayCall';
import config from './content_types';

import WorkflowConfigurationContainer from './components/WorkflowConfiguration/WorkflowConfigurationContainer';
import WorkflowMonitoringInstance from './pages/workflow-monitoring/WorkflowMonitoringInstance';
import { UserPropertySetup } from './pages/user-management/UserPropertySetup';
import DatabaseSyncHome from './pages/user-directory/database-sync/DatabaseSyncHome';
import MediaPulseSyncHome from './pages/user-directory/mediapulse-sync/MediaPulseSyncHome';
import SchemasHome from './pages/schemas/SchemasHome';
import SchemasNew from './pages/schemas/new/SchemasNew';
import SchemasEdit from './pages/schemas/edit/SchemasEdit';
import ConfigurationSetsHome from './pages/configuration-sets/ConfigurationSetsHome';
import ConfigurationSetsNew from './pages/configuration-sets/ConfigurationSetsNew';
import ConfigurationSetsEdit from './pages/configuration-sets/ConfigurationSetsEdit';
import UserProfile from './pages/user-profile/UserProfile';
import Tasks from './pages/tasks/Tasks';

import {GenerateUploaderInviteView} from './pages/uploader-management/GenerateUploaderInvite';

const App = (props) => {
  //let params = useParams();
  const history = createBrowserHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userFullName, setUserFullName] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getCurrentSession = async () => {
      await Auth.currentSession()
        .then((session) => {
          if (session.idToken.payload.name) {
            setUserFullName(session.idToken.payload.name);
          }
          setIsAuthenticated(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsAuthenticated(false);
          console.error(e);
        });
    };
    setIsLoading(true);
    getCurrentSession();
  }, []);

  useEffect(() => {
    const checkUserProfile = async () => {
      try {
        const profileCheck = await apiGatewayCall(config.api_name, '/profile-check', 'get');
        if (profileCheck && profileCheck.profileShallowCheck[0].Value === '1') {
          console.log('Redirecting...');
          if (!window.location.pathname.startsWith('/profile')) window.location.href = '/profile?redirect=true';
        }
      } catch (error) {
        console.error('Error checking if user Profile has all fields required', error);
      }
    };
    checkUserProfile();
  }, []);

  const redirectToReturnUrl = () => {
    const returnUrl = window.location.search;
    const urlParams = new URLSearchParams(returnUrl);
    if (urlParams.has('return_url')) {
      window.location.href = urlParams.get('return_url');
    }
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    const returnUrl = '/?return_url=' + encodeURIComponent(window.location.pathname);
    await Auth.signOut();
    setIsAuthenticated(false);
    window.location.href = returnUrl;
  };

  return (
    <Router>
      <div className='App'>
        <ReactNotification />
        <Header isAuthenticated={isAuthenticated} handleLogout={handleLogout} userFullName={userFullName} />
        <MDBContainer fluid className='page-content'>
          {isLoading === true ? (
            <LoadingIcon />
          ) : isAuthenticated ? (
            <Switch>
              <Route exact path='/' render={() => <Home />} />
              <Route exact path='/phone-list/' component={PhoneList} />
              <Route
                exact
                path='/service-templates'
                render={() => <ServiceTemplatesListing content='service_templates' />}
              />
              <Route exact path='/service-templates/new' component={ServiceTemplatesNew} />
              <Route path='/service-templates/:id' component={ServiceTemplatesEdit} />
              <Route
                exact
                path='/capture-instruction-templates'
                render={() => <CaptureInstructionTemplatesListing content='capture_instruction_templates' />}
              />
              <Route exact path='/capture-instruction-templates/new' component={CaptureInstructionTemplatesNew} />
              <Route path='/capture-instruction-templates/:id' component={CaptureInstructionTemplatesEdit} />
              <Route exact path='/ingredient-sets' render={() => <IngredientSetListing content='ingredient_sets' />} />
              <Route exact path='/ingredient-sets/new' component={IngredientSetNew} />
              <Route path='/ingredient-sets/:id' component={IngredientSetEdit} />
              <Route path='/user-management/users/:id' component={EditUser} />
              <Route path='/task-management/my-tasks/' component={TaskListbyUser} />

              <Route exact path='/user-management/' component={UserManagementHome} />
              <Route exact path='/user-property-setup/' component={UserPropertySetup} />

              <Route exact path='/roles-permissions/' component={RolesPermissionsHome} />

              <Route exact path='/profile' component={UserProfile} />

              <Route
                exact
                path='/workflow-configuration/workflows'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-workflow-listing'
                    content_type='workflows'
                  />
                )}
              />
              <Route
                exact
                path='/workflow-configuration'
                render={() => <Redirect to='/workflow-configuration/connections' />}
              />
              <Route
                exact
                path='/workflow-configuration/workflows/new'
                render={() => (
                  <WorkflowConfigurationContainer page='workflow-configuration-workflow-new' content_type='workflows' />
                )}
              />
              <Route
                path='/workflow-configuration/workflows/:id'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-workflow-edit'
                    content_type='workflows'
                  />
                )}
              />
              <Route
                exact
                path='/workflow-configuration/connections'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-connections-listing'
                    content_type='workflow_connections'
                  />
                )}
              />
              <Route
                path='/workflow-configuration/connections/:id'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-connections-edit'
                    content_type='workflow_connections'
                  />
                )}
              />
              <Route
                exact
                path='/workflow-configuration/recipes'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-recipes-listing'
                    content_type='workflow_recipes'
                  />
                )}
              />
              <Route
                path='/workflow-configuration/recipes/:id'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-recipes-edit'
                    content_type='workflow_recipes'
                  />
                )}
              />
              <Route
                exact
                path='/workflow-configuration/locations'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-locations-listing'
                    content_type='workflow_locations'
                  />
                )}
              />
              <Route
                path='/workflow-configuration/locations/:id'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-locations-edit'
                    content_type='workflow_locations'
                  />
                )}
              />
              <Route
                exact
                path='/workflow-configuration/components'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-components'
                    content_type='workflow_components'
                  />
                )}
              />
              <Route
                path='/workflow-configuration/components/:id'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-components-edit'
                    content_type='workflow_components'
                  />
                )}
              />
              <Route
                exact
                path='/workflow-configuration/component-prototypes'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-component-prototypes'
                    content_type='workflow_component_prototypes'
                  />
                )}
              />
              <Route
                path='/workflow-configuration/component-prototypes/:id'
                render={() => (
                  <WorkflowConfigurationContainer
                    page='workflow-configuration-component-prototypes-edit'
                    content_type='workflow_component_prototypes'
                  />
                )}
              />

              <Route
                exact
                path='/workflow-monitoring'
                render={() => <WorkflowMonitoringListing content='workflow_monitoring' />}
              />
              <Route path='/workflow-monitoring/instance/:id' component={WorkflowMonitoringInstance} />
              <Route
                exact
                path='/delivery-tracking'
                render={() => <ProductionEmailTracking content='production_email_tracking' />}
              />
              <Route
                path='/delivery-tracking/:query'
                render={() => <ProductionEmailTracking content='production_email_tracking' />}
              />
              <Route exact path='/search' render={() => <Take1ConsoleSearch />} />
              <Route path='/search/:query' component={Take1ConsoleSearch} />

              <Route exact path='/tasks' render={() => <Tasks />} />
              <Route exact path='/user-directory-database-sync' render={() => <DatabaseSyncHome />} />
              <Route exact path='/user-directory-mediapulse-sync' render={() => <MediaPulseSyncHome />} />

              <Route exact path='/schemas' render={() => <SchemasHome />} />
              <Route exact path='/schemas/new' render={() => <SchemasNew />} />
              <Route path='/schemas/:id' component={SchemasEdit} />

              <Route exact path='/configuration-sets' render={() => <ConfigurationSetsHome />} />
              <Route exact path='/configuration-sets/new' render={() => <ConfigurationSetsNew />} />
              <Route path='/configuration-sets/:id' component={ConfigurationSetsEdit} />

              <Route path='/large-file-uploader/' component={GenerateUploaderInviteView} />

              <Route component={NotFound} />
            </Switch>
          ) : (
            <Switch>
              <Route exact path='/phone-list/' component={PhoneList} />
              <Route
                exact
                path='/'
                render={() => (
                  <LoginForm setIsAuthenticated={setIsAuthenticated} redirectToReturnUrl={redirectToReturnUrl} />
                )}
              />
              <Route exact path='/' component={LoginForm} />
              <Route render={() => <LoginForm setIsAuthenticated={setIsAuthenticated} />} />
            </Switch>
          )}
        </MDBContainer>
      </div>
    </Router>
  );
};

export default App;
