import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBTooltip,
  MDBTypography,
} from "mdbreact";
import { withRouter } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import showNotification from "../../helpers/showNotification";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import moment from "moment";

const WorkflowConnectionsEdit = ({ match, setLoading }) => {
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canViewUserPages, setCanViewUserPages] = useState(false);
  const [connection, setConnection] = useState(null);
  const [updatedConnection, setUpdatedConnection] = useState(null);
  const [fields, setFields] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const recordId = match.params.id;

  const requiredFields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "key",
      label: "Connection key",
    },
    {
      name: "connectionTypeId",
      label: "Connection type",
    },
    {
      name: "sourceLocationId",
      label: "Source location",
    },
    {
      name: "destinationLocationId",
      label: "Destination location",
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/workflow-connections/" + recordId, "get")
      .then((response) => {
        setPageTitle(response.connection.name);
        setConnection(response.connection);
        setUpdatedConnection({ ...response.connection });
        setFields(response.fields);
        setCanEdit(response.can_edit);
        setCanDelete(response.can_delete);
        setCanViewUserPages(response.can_view_user_pages);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const validateForm = () => {
    let invalidFields = [];
    requiredFields.forEach((requiredField) => {
      if (updatedConnection[requiredField.name] === "") {
        invalidFields.push(requiredField.label);
      }
    });
    return invalidFields;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const invalidFields = validateForm();
    if (invalidFields.length) {
      const invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " + invalidFieldsString,
        "warning"
      );
    } else {
      let body = { ...updatedConnection };
      delete body.author;
      delete body.createdDateTime;
      delete body.connectionId;
      const options = {
        body: body,
      };
      setLoading(true);
      await apiGatewayCall(config.api_name, "/workflow-management/workflow-connections/" + recordId, "post", options)
        .then(() => {
          showNotification("Saved", connection.name + " was successfully updated.", "success");
          fetchData();
        })
        .catch((e) => console.log(e));
    }
  };

  const onFieldChange = (field, value) => {
    if (field === "key") {
      const regex = RegExp("^[a-zA-Z0-9]{1,6}$");
      if (regex.test(value)) {
        value = value.toUpperCase();
        setUpdatedConnection(() => {
          let newUpdatedConnection = { ...updatedConnection };
          newUpdatedConnection[field] = value;
          return newUpdatedConnection;
        });
      }
    } else if (field === "metadata") {
      !value.error &&
        setUpdatedConnection(() => {
          let newUpdatedConnection = { ...updatedConnection };
          newUpdatedConnection[field] = value.json;
          return newUpdatedConnection;
        });
    } else {
      setUpdatedConnection(() => {
        let newUpdatedConnection = { ...updatedConnection };
        newUpdatedConnection[field] = value;
        return newUpdatedConnection;
      });
    }
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    toggleDeleteModal();
  };
  const onConfirmDelete = async (e) => {
    e.preventDefault();
    await apiGatewayCall(config.api_name, "/workflow-management/workflow-connections/" + recordId, "del")
      .then(() => {
        window.location = "/workflow-configuration/connections";
      })
      .catch((e) => {
        setLoading(false);
        if (e.statusCode === 403) {
          showNotification("Permission denied", "You do not have permission to delete connections.", "warning");
        }
      });
  };
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  let headerContent;
  let formContent;
  let authorContent;
  if (updatedConnection && fields) {
    if (connection.author || connection.createdDateTime) {
      authorContent = (
        <p className="text-muted small">
          Created{" "}
          {connection.createdDateTime && moment(connection.createdDateTime).format("ddd MMM D YYYY h:mma") + " UTC"} by{" "}
          <MDBTooltip domElement placement="bottom" clickable>
            <span className="clickable">{connection.author.name}</span>
            <MDBCard>
              <MDBCardHeader>{connection.author.name}</MDBCardHeader>
              <MDBCardBody>
                <MDBTypography listUnStyled className="mb-0">
                  <li>
                    <a href={`mailto:${connection.author.email}`}>
                      <MDBIcon icon="envelope" className="mr-2" />
                      {connection.author.email}
                    </a>
                  </li>
                  {canViewUserPages && (
                    <li>
                      <a href={`/user-management/users/${connection.author.username}`}>
                        <MDBIcon icon="user" className="mr-2" />
                        Full details
                      </a>
                    </li>
                  )}
                </MDBTypography>
              </MDBCardBody>
            </MDBCard>
          </MDBTooltip>
        </p>
      );
    }
    headerContent = (
      <header className="page-title">
        <h1>{connection.name}</h1>
        {authorContent}
      </header>
    );
    formContent = (
      <form onSubmit={(e) => handleFormSubmit(e)} className="edit-connection-form">
        <div className="form-group">
          <label htmlFor="input-name">Name (required)</label>
          <input
            type="text"
            id="input-name"
            onChange={(e) => onFieldChange("name", e.target.value)}
            value={updatedConnection.name}
            className="form-control"
            disabled={!canEdit}
          />
        </div>
        <div className="form-group">
          <label htmlFor="select-status">Status (required)</label>
          <select
            className="browser-default custom-select mb-2"
            onChange={(e) => onFieldChange("connectionStatusId", e.target.value)}
            id="select-status"
            defaultValue={connection.connectionStatusId}
            disabled={!canEdit}
          >
            <option value={0} disabled>
              Select status
            </option>
            {fields.status.map((status) => {
              return (
                <option key={status.connectionStatusId} value={status.connectionStatusId}>
                  {status.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="input-key">Connection key (required)</label>
          <small className="form-text text-muted">Max six characters, alphanumeric only, no spaces allowed.</small>
          <input
            type="text"
            id="input-key"
            onChange={(e) => onFieldChange("key", e.target.value)}
            value={updatedConnection.key}
            className="form-control"
            disabled={!canEdit}
          />
        </div>
        <div className="form-group">
          <label htmlFor="input-description">Description</label>
          <small className="form-text text-muted">What this is, who uses it, etc.</small>
          <input
            type="text"
            id="input-description"
            onChange={(e) => onFieldChange("description", e.target.value)}
            value={updatedConnection.description}
            className="form-control"
            disabled={!canEdit}
          />
        </div>
        <div className="form-group">
          <label htmlFor="select-connection-type">Connection type (required)</label>
          <select
            className="browser-default custom-select mb-2"
            onChange={(e) => onFieldChange("connectionTypeId", e.target.value)}
            id="select-connection-type"
            defaultValue={connection.connectionTypeId}
            disabled={!canEdit}
          >
            <option value={0} disabled>
              Select a connection type
            </option>
            {fields.connectionType.map((connectionType) => {
              return (
                <option key={connectionType.workflowConnectionTypeId} value={connectionType.workflowConnectionTypeId}>
                  {connectionType.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="input-requirements">Requirements</label>
          <input
            type="text"
            id="input-requirements"
            onChange={(e) => onFieldChange("requirements", e.target.value)}
            value={updatedConnection.requirements}
            className="form-control"
            disabled={!canEdit}
          />
        </div>
        <div className="form-group">
          <label htmlFor="input-metadata">Metadata</label>
          <JSONInput
            id="input-metadata"
            locale={locale}
            theme="light_mitsuketa_tribute"
            colors={{ default: "#495057" }}
            height="auto"
            placeholder={JSON.parse(updatedConnection.metadata)}
            style={{ body: { border: "1px solid #ced4da", borderRadius: "0.25rem", color: "#495057" } }}
            onChange={(e) => onFieldChange("metadata", e)}
            viewOnly={!canEdit}
          />
        </div>
        <div className="form-group">
          <label htmlFor="select-workflow">Workflow</label>
          <select
            className="browser-default custom-select mb-2"
            onChange={(e) => onFieldChange("workflowId", e.target.value)}
            id="select-workflow"
            defaultValue={connection.workflowId === null ? 0 : connection.workflowId}
            disabled={!canEdit}
          >
            <option value={0} disabled>
              Select a workflow
            </option>
            {fields.workflows.map((workflow) => {
              return (
                <option key={workflow.workflowId} value={workflow.workflowId}>
                  {workflow.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="select-source-location">Source location (required)</label>
          <select
            className="browser-default custom-select mb-2"
            onChange={(e) => onFieldChange("sourceLocationId", e.target.value)}
            id="select-source-location"
            defaultValue={connection.sourceLocationId}
            disabled={!canEdit}
          >
            <option value={0} disabled>
              Select a source location
            </option>
            {fields.location.map((location) => {
              return (
                <option key={location.locationId} value={location.locationId}>
                  {location.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="select-destination-location">Destination location (required)</label>
          <select
            className="browser-default custom-select mb-2"
            onChange={(e) => onFieldChange("destinationLocationId", e.target.value)}
            id="select-destination-location"
            defaultValue={connection.destinationLocationId}
            disabled={!canEdit}
          >
            <option value={0} disabled>
              Select a destination location
            </option>
            {fields.location.map((location) => {
              return (
                <option key={location.locationId} value={location.locationId}>
                  {location.name}
                </option>
              );
            })}
          </select>
        </div>

        <section className="connection-usage">
          <MDBCard className="my-4">
            <MDBCardHeader>Usage</MDBCardHeader>
            <MDBCardBody>
              <p>Placeholder for connection usage information.</p>
            </MDBCardBody>
          </MDBCard>
        </section>

        <footer className="form-buttons mb-4">
          <MDBBtn color="secondary" onClick={(e) => (window.location = "/workflow-configuration/connections/")}>
            Back
          </MDBBtn>

          {canDelete && (
            <>
              <MDBBtn color="danger" onClick={(e) => onDeleteClick(e)}>
                Delete
              </MDBBtn>
              <MDBModal toggle={toggleDeleteModal} isOpen={deleteModalOpen}>
                <MDBModalHeader>Delete {connection.name}</MDBModalHeader>
                <MDBModalBody>Are you sure you want to delete {connection.name}?</MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={(e) => toggleDeleteModal()}>
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="danger" onClick={(e) => onConfirmDelete(e)}>
                    Delete
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </>
          )}

          {canEdit && (
            <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} className="float-right">
              Save
            </MDBBtn>
          )}
        </footer>
      </form>
    );
  }

  return (
    <>
      {headerContent}
      {formContent}
    </>
  );
};

export default withRouter(WorkflowConnectionsEdit);
