import React, { useEffect, useRef, useState } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import config from "../../content_types";
import { MDBRow, MDBCol, MDBDataTable, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBIcon } from "mdbreact";
import showNotification from "../../helpers/showNotification";
import setPageTitle from "../../helpers/setPageTitle";
import LoadingIcon from "../../components/LoadingIcon";

const Take1ConsoleSearch = ({ match }) => {
  const history = useHistory();

  let query = null;
  if (match !== undefined) {
    query = match.params.query;
    setPageTitle("Search results for " + query);
  } else {
    setPageTitle("Take 1 Console Search");
  }

  const [searchTerm, setSearchTerm] = useState(query);
  const [searchResultsContent, setSearchResultsContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;

  useEffect(() => {
    if (query) fetchData();
    document.addEventListener("keydown", keyboardListener);
    return () => {
      document.removeEventListener("keydown", keyboardListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    if (emailRegex.test(searchTerm)) {
      const deliveryTrackingLink =
        "/delivery-tracking/?searchCriteriaField=email_address&searchCriteriaValue=" + searchTerm;
      history.push(deliveryTrackingLink);
    } else {
      setLoading(true);
      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
        },
      };

      const destinationUrl = "/search/" + searchTerm;
      history.push(destinationUrl);

      let apiUrl = "/search/?query=" + searchTerm;
      await API.get(config.api_name, apiUrl, requestOptions)
        .then((response) => {
          setLoading(false);
          if (response.statusCode === 200) {
            setSearchResultsContent(generateSearchResults(response.searchResults, response.searchTerm));
          } else if (response.statusCode === 401) {
            alert("Not authorised!");
            window.location.href = "/";
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const searchInputRef = useRef();

  const keyboardListener = (e) => {
    if (e.keyCode === 191) {
      e.preventDefault();
      searchInputRef.current.focus();
      searchInputRef.current.select();
    }
  };

  const onSearchFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      fetchData();
    } else {
      showNotification("", "Please enter something to search.", "warning");
    }
  };

  const validateForm = () => {
    if (searchTerm !== "") {
      return true;
    } else {
      return false;
    }
  };

  const generateSearchResults = (results, searchTerm) => {
    let _searchResultsContent = [];

    if (results.length > 0) {
      let count = 0;
      results.forEach((searchResult) => {
        const searchResultKey = "search-result-" + count;
        let dataTableData = { columns: [], rows: [] };
        let columns = Object.keys(searchResult.results[0]);
        columns.push("actions");
        columns.forEach((column) => {
          let _column = {
            field: column,
          };
          if (column !== "actions") {
            _column.label = column;
          } else {
            _column.sort = "disabled";
          }
          dataTableData.columns.push(_column);
        });
        dataTableData.rows = searchResult.results;
        dataTableData.rows.forEach((row) => {
          if (row.actions === undefined) {
            const searchLink = "/search/" + row[searchResult.idField];
            const deliveryTrackingLink =
              "/delivery-tracking/?searchCriteriaField=" +
              searchResult.idField +
              "&searchCriteriaValue=" +
              row[searchResult.idField];
            let parentSearchLinkContent = null;
            let instanceLinkContent = null;
            if (row.AWSInputInstanceId !== null && row.AWSInputInstanceId !== undefined) {
              const instanceLink = "/workflow-monitoring/instance/" + row.AWSInputInstanceId;
              instanceLinkContent = (
                <li className="list-inline-item">
                  <a href={instanceLink} title="View details relating to this instance">
                    <MDBIcon icon="cogs" />
                  </a>
                </li>
              );
            }
            if (searchResult.parentIdField !== undefined) {
              const parentSearchLink = "/search/" + row[searchResult.parentIdField];
              parentSearchLinkContent = (
                <li className="list-inline-item">
                  <a href={parentSearchLink} title="Search for this job item's parent job ID">
                    <MDBIcon icon="level-up-alt" />
                  </a>
                </li>
              );
            }
            row.actions = (
              <ul className="list-inline search-results-actions">
                {instanceLinkContent}
                {parentSearchLinkContent}
                <li className="list-inline-item">
                  <a href={searchLink} title="Search for this job ID">
                    <MDBIcon icon="search" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={deliveryTrackingLink} title="Search delivery tracking">
                    <MDBIcon icon="mail-bulk" />
                  </a>
                </li>
              </ul>
            );
          }
        });
        _searchResultsContent.push(
          <MDBCard key={searchResultKey} className="mb-4">
            <MDBCardHeader>
              {searchResult.title} ({searchResult.results.length})
            </MDBCardHeader>
            <MDBCardBody>
              <MDBDataTable
                data={dataTableData}
                displayEntries={false}
                info={false}
                noBottomColumns={true}
                paging={false}
                searching={false}
                // sortable={false}
                hover
              />
            </MDBCardBody>
          </MDBCard>
        );
        count++;
      });
    } else {
      _searchResultsContent.push(<p key="no-results-found">No results found.</p>);
    }

    return (
      <MDBRow>
        <MDBCol>
          <MDBCard>
            <MDBCardHeader>
              <MDBCardTitle>Showing results for {searchTerm}</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>{_searchResultsContent}</MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  };

  let loadingContent = null;
  if (loading) loadingContent = <LoadingIcon />;

  return (
    <>
      {loadingContent}
      <MDBRow>
        <MDBCol>
          <h1 className="page-title">Take 1 Console search</h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBCard className="mb-4">
            <MDBCardBody>
              <form className="production-email-search-form search-page" onSubmit={(e) => onSearchFormSubmit(e)}>
                <div className="input-group">
                  <div className="input-group-prepend" onClick={(e) => onSearchFormSubmit(e)}>
                    <span className="input-group-text" id="basic-addon">
                      <i className="fa fa-search prefix"></i>
                    </span>
                  </div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon"
                    ref={searchInputRef}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    defaultValue={searchTerm}
                  />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      {searchResultsContent}
    </>
  );
};

export default Take1ConsoleSearch;
