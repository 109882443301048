import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import setPageTitle from "../../helpers/setPageTitle";
import config from "../../content_types";
import { MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBDataTable, MDBRow, MDBCol } from "mdbreact";
import showNotification from "../../helpers/showNotification";
import apiGatewayCall from "../../helpers/apiGatewayCall";

const WorkflowComponentPrototypesEdit = ({ content_type, match, setLoading }) => {
  const recordId = match.params.id;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [redirectToHome, setRedirectToHome] = useState(false);

  const [prototype, setPrototype] = useState(null);

  const [canEdit, setCanEdit] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/component-prototypes/" + recordId, "get")
      .then((response) => {
        setPrototype(response.component_prototype);
        setPageTitle("Editing " + response.component_prototype.name);
        setCanEdit(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to edit component prototypes.", "warning");
      });
  };
  const validateForm = () => {
    let requiredFields = [
      {
        name: "name",
        label: "Name",
      },
    ];
    let invalidFields = [];
    requiredFields.forEach((requiredField) => {
      if (prototype[requiredField.name] === "") {
        invalidFields.push(requiredField.label);
      }
    });

    if (invalidFields.length === 0) {
      return true;
    } else {
      let invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " + invalidFieldsString,
        "warning"
      );
    }
  };

  const handleFormChange = (fieldName, e) => {
    setPrototype((oldPrototype) => {
      if (e === true) {
        e = 1;
      } else if (e === false) {
        e = 0;
      }
      oldPrototype[fieldName] = e;
      return oldPrototype;
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      let options = {
        body: prototype,
      };
      await apiGatewayCall(
        config.api_name,
        "/workflow-configuration/component-prototypes/" + prototype.componentId,
        "post",
        options
      )
        .then((response) => {
          showNotification("Success", "Component prototype was successfully updated", "success");
          fetchData();
        })
        .catch(() => {
          setLoading(false);
          showNotification("Access denied", "You do not have permission to edit component prototypes.", "warning");
        });
    }
  };

  const saveNewInput = (e) => {
    e.preventDefault();
    const newInputName = document.getElementById("new-input-name").value;
    const newInputValue = document.getElementById("new-input-value").value;
    if (newInputName !== "") {
      let newPrototype = Object.assign({}, prototype);
      newPrototype.inputs[newInputName] = { value: newInputValue };
      setPrototype(newPrototype);
      document.getElementById("new-input-name").value = "";
      document.getElementById("new-input-value").value = "";
    }
  };

  const deleteInput = (inputName) => {
    let newPrototype = Object.assign({}, prototype);
    delete newPrototype.inputs[inputName];
    setPrototype(newPrototype);
  };

  let prototypeInputs;

  let formContent = [];
  if (prototype) {
    if (prototype.inputs) {
      let prototypeInputsData = {
        columns: [
          { label: "Name", field: "name" },
          { label: "Value", field: "value" },
          { label: "", field: "actions" },
        ],
        rows: [],
      };
      Object.keys(prototype.inputs).forEach((inputName) => {
        let inputRow = {
          name: inputName,
          value: prototype.inputs[inputName].value,
          actions: null,
        };
        if (canEdit)
          inputRow.actions = (
            <MDBIcon icon="times" className="clickable float-right" onClick={(e) => deleteInput(inputName)} />
          );

        prototypeInputsData.rows.push(inputRow);
      });
      prototypeInputs = (
        <MDBDataTable
          data={prototypeInputsData}
          displayEntries={false}
          info={false}
          noBottomColumns={true}
          paging={false}
          searching={false}
          sortable={false}
          noRecordsFoundLabel="Please add at least one input"
          hover
        />
      );
    }
    formContent.push(
      <MDBInput disabled label="Name" valueDefault={prototype.name} getValue={(e) => handleFormChange("name", e)} />
    );
    formContent.push(
      <MDBInput
        label="Description"
        disabled
        valueDefault={prototype.description}
        getValue={(e) => handleFormChange("description", e)}
      />
    );
    formContent.push(
      <p>
        <label>State machine ARN</label>
        <br />
        <span className="component-state-machine">
          <a
            href={
              "https://eu-west-2.console.aws.amazon.com/states/home?region=eu-west-2#/statemachines/view/" +
              prototype.stateMachineARN
            }
            target="_blank"
            rel="nofollow noreferrer"
          >
            {prototype.stateMachineARN}
          </a>
        </span>
      </p>
    );
    formContent.push(
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Inputs</MDBCardTitle>
          <p>Default values for components based on this prototype</p>
          {canEdit && (
            <form onSubmit={(e) => saveNewInput(e)}>
              <MDBRow>
                <MDBCol size="4">
                  <MDBInput label="Input name" id="new-input-name" valueDefault="" />
                </MDBCol>
                <MDBCol size="5">
                  <MDBInput label="Input default value" id="new-input-value" valueDefault="" />
                </MDBCol>
                <MDBCol size="3">
                  <MDBBtn type="submit" onClick={(e) => saveNewInput(e)} className="mb-0">
                    Add
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          )}
          {prototypeInputs}
        </MDBCardBody>
      </MDBCard>
    );
  }

  let pageContent;
  if (redirectToHome) {
    pageContent = <Redirect to="/workflow-configuration/component-prototypes" />;
  } else {
    if (prototype) {
      pageContent = (
        <>
          <h1 className="page-title">{prototype.name}</h1>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            {formContent}
            <MDBBtn color="secondary" onClick={(e) => setRedirectToHome(true)}>
              Back
            </MDBBtn>
            {canEdit === true ? (
              <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} color="primary">
                Save
              </MDBBtn>
            ) : null}
          </form>
        </>
      );
    } else {
      pageContent = <p></p>;
    }
  }

  return pageContent;
};

export default withRouter(WorkflowComponentPrototypesEdit);
