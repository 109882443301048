import { MDBCard, MDBCardBody } from "mdbreact";
import React from "react";
import moment from "moment";
import CopyIcon from "../../components/CopyIcon";

const ConsoleData = ({ userData }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <p>
          <small>User directory ID</small>
          <br />
          <CopyIcon text={userData.uuid} />
        </p>
        <p>
          <small>Account creation date</small>
          <br />
          {moment(userData.dateCreated).format("YYYY-MM-DD hh:mm:ss")}
        </p>
        {"legacyAgentHash" in userData && (
          <p>
            <small>Legacy agent hash</small>
            <br />
            <CopyIcon text={userData.legacyAgentHash} />
          </p>
        )}
        <p className="mb-0">
          <small>Take 1 Console roles</small>
        </p>
        <ul className="list-unstyled">
          {userData.roles.map((role) => (
            <li key={role}>{role}</li>
          ))}
        </ul>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConsoleData;
