import { Auth, API } from "aws-amplify";

const apiGatewayCall = async (api, url, method, _options = {}) => {
  return new Promise(async (resolve, reject) => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    let options = {..._options, ...{ "headers": {Authorization: jwtToken,}}};
    await API[method](api, url, options).then((response) => {
      if (response.statusCode === 200 || response.statusCode === 201) {
        resolve(response);
        // } else if (response.statusCode === 403) {
        //   reject(response)
        //   // alert("Not authorised!");
        //   // window.location.href = "/";
      } else {
        reject(response);
      }
      resolve(response);
    });
  });
};

export default apiGatewayCall;
