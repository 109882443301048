import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import React from "react";

const SchemaNewCurrentElement = ({ input, setSchema }) => {
  const updateInput = (inputId, field, value) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      _schema.inputs.forEach((input) => {
        if (input.inputInstance.id === inputId) {
          input.inputInstance[field] = value;
        }
      });
      return _schema;
    });
  };

  const onDeleteInput = (e, inputId) => {
    e.preventDefault();
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      _schema.inputs = _schema.inputs.filter((input) => input.inputInstance.id !== inputId);
      return _schema;
    });
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBRow>
          <MDBCol>
            <h3 className="h5">
              {input.inputTemplate.label}
              <MDBIcon
                icon="times"
                onClick={(e) => onDeleteInput(e, input.inputInstance.id)}
                className="clickable float-right"
              />
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBInput
              type="text"
              getValue={(e) => updateInput(input.inputInstance.id, "content", e)}
              label="Contents"
            />
          </MDBCol>
          <MDBCol>
            <h4 className="h6">Preview</h4>
            {input.inputTemplate.slug === "h1" && <h1>{input.inputInstance.content}</h1>}
            {input.inputTemplate.slug === "h2" && <h2>{input.inputInstance.content}</h2>}
            {input.inputTemplate.slug === "h3" && <h3>{input.inputInstance.content}</h3>}
            {input.inputTemplate.slug === "h4" && <h4>{input.inputInstance.content}</h4>}
            {input.inputTemplate.slug === "p" && <p>{input.inputInstance.content}</p>}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaNewCurrentElement;
