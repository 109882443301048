/**
 * Presents a view enabling selected users to generate a one-time link to an
 * upload session that can be handed over to a customer.
 * This is currently configured to work using the large file uploader setup only.
 * See https://take1jira.atlassian.net/browse/T1-392
 */

import { MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBBtn} from "mdbreact";
import React, {useState} from "react";

import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from '../../config';
import content_types_config from "../../content_types";
import CopyIcon from '../../components/CopyIcon';
import showNotification from "../../helpers/showNotification";

const composeOneTimeURL = (config, token) => {
  return `https://${config.largeFileUploaderHostname}/invitation/${token}`;
}

async function sendUploadInviteRequest(expiryDate, customerName) {
  console.log("Sending upload invition request for ", customerName, expiryDate);
  return await apiGatewayCall(content_types_config.api_name, "/upload-invite/", "post", {
    body: {
      date_expires: expiryDate,
      reg : {
        clientName: customerName
      }},
  })
}

const GenerateUploaderInviteView = () => {

  // The default selected date is seven days ahead
  const defaultExpiryDate = dayjs()
    .add(7, 'days')
    .toDate();

  const [expiryDate, setExpiryDate] = useState(defaultExpiryDate);
  const [customerName, setCustomerName] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [url, setUrl] = useState(null);
  const [isWorking, setIsWorking] = useState(false);

  /**
   * On action performed 'Generate' button
   */
  const generateInvite = () => {
    setIsWorking(true);
    const expiryDateISO = dayjs(expiryDate).toISOString();

    sendUploadInviteRequest(expiryDateISO, customerName).then(response => {
      console.log("Upload invitation response ", response);
      let token, passcode = null;
      try {
        token = response.body.token;
        passcode = response.body.auth.passcode;
      } catch (e) {
        console.error(e);
        showNotification(
          "Access denied",
          "Unable to generate an upload invitation", "warning");
          setIsWorking(false);
        return;
      }
      // OK, compose the URL and display the results
      const url = composeOneTimeURL(config, response.body.token);
      setPasscode(response.body.auth.passcode);
      setUrl(url);
      setIsWorking(false);
    }).catch((err) => {
      console.error("Upload invitation response ", err);
      showNotification(
        "Access denied",
        "Unable to generate an upload invitation", "warning");
      setIsWorking(false);
    });
  }

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          Large File Uploader
        </MDBCardTitle>
          <div className="form-group">
            <label>Expiry Date</label>
            <DatePicker
              selected={defaultExpiryDate}
              onChange={(date) => setExpiryDate(date)}
              showTimeSelect
              dateFormat="MMMM d, yyyy HH:mm 'UTC'"
              placeholderText="Expiry Date"
              className="form-control form-control-sm ml-0 my-1"
            />

            <MDBInput
              type="text"
              value={customerName}
              id="customerName"
              label="Customer Name"
              onChange={e => setCustomerName(e.target.value)}
            />

            <MDBBtn
              onClick={() => generateInvite()}
              className="m-0"
              disabled={expiryDate == null || customerName == null}
            >GENERATE&nbsp;
            {isWorking ?
              <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
             : null}
            </MDBBtn>

          </div>

          {passcode != null?
          <div>
            <label>Passcode:</label>
            <p><CopyIcon text={passcode}/></p>
          </div>
         : null}

          {url != null?
          <div>
            <label>One-time URL:</label>
            <p><CopyIcon text={url}/></p>
          </div>
         : null}
      </MDBCardBody>
    </MDBCard>
  );
};

export {composeOneTimeURL, GenerateUploaderInviteView};
