import { MDBBtn } from "mdbreact";
import React from "react";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";

const OoonaProjectPreview = ({ configurationSet, setLoading }) => {
  const onPreviewClick = async (e) => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/ooona/project-preview/", "post", {
      body: configurationSet,
    })
      .then((response) => {
        setLoading(false);
        const newWindow = window.open(response.url, "_blank");
        newWindow.focus();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  return (
    <div>{configurationSet.uuid !== undefined && <MDBBtn onClick={(e) => onPreviewClick(e)}>Open preview</MDBBtn>}</div>
  );
};

export default OoonaProjectPreview;
