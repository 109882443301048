import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useCookies } from "react-cookie";
import setPageTitle from "../helpers/setPageTitle";
import { MDBRow, MDBCol } from "mdbreact";
import HomepageCards from "../components/HomepageCards";
import config from "../content_types";

import LoadingIcon from "../components/LoadingIcon";
import SearchForm from "../components/SearchForm";
import apiGatewayCall from "../helpers/apiGatewayCall";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [cardCategories, setCardCategories] = useState([]);
  const [canWrite, setCanWrite] = useState(false);
  const [canSearchProductionEmails, setCanSearchProductionEmails] = useState(false);
  const [canUseConsole, setCanUseConsole] = useState(true);

  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["canUseUploader"]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);

    await apiGatewayCall(config.api_name, "/cards", "get").then((response) => {
      setLoading(false);
      if (response.statusCode === 403) {
        setCanUseConsole(false);
      } else if (response.statusCode === 200) {
        setTasks(response.cards);
        setCardCategories(response.card_categories);
        setCanWrite(response.can_write);
        setCanSearchProductionEmails(response.can_search_production_emails);
        setCookie("canUseUploader", response.can_use_uploader);
        // setCanUseUploader(response.can_use_uploader);
      }
    });
  };

  let loadingContent = null;
  if (loading) loadingContent = <LoadingIcon />;

  const notAuthorisedMessage = <p>Your account is confirmed. You may now close this window.</p>;

  setPageTitle("Home");
  return (
    <Fragment>
      <MDBRow>
        <MDBCol size="8">
          <h1 className="page-title">Take 1 Console</h1>
        </MDBCol>
        <SearchForm canSearch={canSearchProductionEmails} />
      </MDBRow>
      {canUseConsole ? (
        <HomepageCards
          tasks={tasks}
          canWrite={canWrite}
          setLoading={setLoading}
          fetchData={fetchData}
          cardCategories={cardCategories}
        />
      ) : (
        notAuthorisedMessage
      )}

      {loadingContent}
    </Fragment>
  );
};

export default withRouter(Home);
