import React, { useState } from "react";
import slugify from "slugify";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import Select from "react-select";

const NewPropertyModal = ({ setIsLoading, getProperties, propertyTypes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newPropertyData, setNewPropertyData] = useState({});

  const [inputTypes, setInputTypes] = useState([]);

  const [newPropertyMetadataFormDisplayClass, setNewPropertyMetadataFormDisplayClass] = useState("d-none");
  const [newPropertyMetadata, setNewPropertyMetadata] = useState({ value: "", label: "" });

  const buildForm = async () => {
    setInputTypes([
      {
        label: "Text",
        value: "text",
      },
      {
        label: "Text area",
        value: "textarea",
      },
      {
        label: "Number",
        value: "number",
      },
      {
        label: "Date",
        value: "date",
      },
      {
        label: "Select dropdown (single choice)",
        value: "select",
      },
      {
        label: "Select dropdown (multiple choice)",
        value: "multiple",
      },
      {
        label: "Checkbox",
        value: "checkbox",
      },
      {
        label: "Country dropdown",
        value: "country-select",
      },
      {
        label: "Language dropdown (single choice)",
        value: "language-select",
      },
      {
        label: "Language dropdown (multiple choice)",
        value: "language-multiple",
      },
      {
        label: "Timezone dropdown",
        value: "timezone-select",
      },
    ]);
    setIsOpen(true);
  };

  const toggle = () => {
    if (!isOpen) {
      buildForm();
    } else {
      setIsOpen(false);
    }
  };

  const onFormChange = (e) => {
    let field_value;
    const field_name = e.target.name;
    if ("type" in e.target && e.target.type === "checkbox") {
      field_value = e.target.checked;
    } else {
      field_value = e.target.value;
    }
    if (field_name === "inputType") {
      if (field_value === "select" || field_value === "multiple") {
        setNewPropertyMetadataFormDisplayClass("d-block mt-4 mb-4");
      } else {
        setNewPropertyMetadataFormDisplayClass("d-none");
      }
    }

    let updatedNewPropertyData = { ...newPropertyData };
    updatedNewPropertyData[field_name] = field_value;
    if (field_name === "label") {
      const slugifiedLabel = slugify(field_value, { replacement: "_", lower: true });
      updatedNewPropertyData.name = slugifiedLabel;
    }
    setNewPropertyData(updatedNewPropertyData);
  };

  const onSubmit = async (e) => {
    const validateForm = () => {
      if (
        newPropertyData.name !== undefined &&
        newPropertyData.label !== undefined &&
        newPropertyData.inputType !== undefined
      ) {
        if (newPropertyData.inputType === "select" || newPropertyData.inputType === "multiple") {
          if (newPropertyData.metadata !== undefined) {
            setNewPropertyData((oldNewPropertyData) => {
              oldNewPropertyData.metadata = JSON.stringify(oldNewPropertyData.metadata);
              return oldNewPropertyData;
            });
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    };

    e.preventDefault();
    if (validateForm()) {
      await apiGatewayCall(config.api_name, "/user-management/properties", "post", { body: newPropertyData })
        .then((response) => {
          if (response.statusCode === 201) {
            onSuccess(newPropertyData.label);
          } else {
            onFailure(response);
          }
        })
        .catch(() => {
          setIsLoading(false);
          showNotification("Access denied", "You do not have permission to create properties.", "warning");
        });
    } else {
      showNotification(
        "Error",
        "Please make sure you have entered a name, a label, and an input type. If you have chosen a select or multiple dropdown for your property, please enter some options.",
        "warning"
      );
    }
  };

  const onSuccess = (property) => {
    showNotification("Property created", property + " was successfully created.", "success");
    setNewPropertyData({});
    toggle();
    getProperties();
  };
  const onFailure = (response) => {
    console.log(response);
  };

  const updateNewPropertyMetadata = (field, value) => {
    let newNewPropertyMetadata = { ...newPropertyMetadata };
    newNewPropertyMetadata[field] = value;
    if (field === "label") {
      const slugifiedLabel = slugify(value, { replacement: "_", lower: true });
      newNewPropertyMetadata.value = slugifiedLabel;
    }
    setNewPropertyMetadata(newNewPropertyMetadata);
  };

  const onSaveNewMetadata = async (e) => {
    e.preventDefault();
    let updatedNewPropertyData = { ...newPropertyData };
    if (updatedNewPropertyData.metadata === undefined) {
      updatedNewPropertyData.metadata = [];
    }
    updatedNewPropertyData.metadata.push(newPropertyMetadata);
    setNewPropertyMetadata({ value: "", label: "" });
    setNewPropertyData(updatedNewPropertyData);
  };

  const onDeleteMetadataItem = (e, value) => {
    e.preventDefault();
    let updatedNewPropertyData = { ...newPropertyData };
    updatedNewPropertyData.metadata = newPropertyData.metadata.filter((metadataItem) => metadataItem.value !== value);
    setNewPropertyData(updatedNewPropertyData);
  };

  let newPropertyMetadataItems = [];
  const newPropertyMetadataTable = (
    <table width="100%">
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{newPropertyMetadataItems}</tbody>
    </table>
  );
  if (newPropertyData.metadata) {
    newPropertyData.metadata.forEach((metadata) => {
      newPropertyMetadataItems.push(
        <tr>
          <td>{metadata.label}</td>
          <td>{metadata.value}</td>
          <td>
            <MDBIcon
              icon="times"
              onClick={(e) => onDeleteMetadataItem(e, metadata.value)}
              className="float-right red-text clickable"
            />
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      <MDBBtn onClick={toggle}>New property</MDBBtn>
      <MDBModal isOpen={isOpen} toggle={toggle} className="full-height">
        <MDBModalHeader toggle={toggle}>New property</MDBModalHeader>
        <form id="new-property-form" onSubmit={(e) => onSubmit(e)}>
          <MDBModalBody>
            <MDBInput
              label="Label"
              type="text"
              required={true}
              value={newPropertyData.label}
              onChange={(e) => onFormChange(e)}
              key="input-label"
              name="label"
            />
            <MDBInput
              label="Name"
              type="text"
              required={true}
              value={newPropertyData.name}
              onChange={(e) => onFormChange(e)}
              key="input-name"
              name="name"
            />
            <MDBInput
              label="Description (optional)"
              type="text"
              required={false}
              value={newPropertyData.description}
              onChange={(e) => onFormChange(e)}
              key="input-description"
              name="description"
            />
            <label htmlFor="select-propertyType">Property type</label>
            <select
              className="browser-default custom-select"
              onChange={(e) => {
                const event = {
                  target: {
                    name: "propertyType",
                    value: e.target.value,
                  },
                };
                onFormChange(event);
              }}
              value={newPropertyData.propertyType}
              id="select-propertyType"
            >
              {propertyTypes.map((propertyType) => {
                return (
                  <option value={propertyType.value} key={propertyType.value}>
                    {propertyType.label}
                  </option>
                );
              })}
            </select>
            <div className="md-form">
              <MDBInput
                label="Personally identifiable information (PII)"
                type="checkbox"
                id="pii"
                onChange={(e) => onFormChange(e)}
                key="input-pii"
                name="pii"
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputType">Input type</label>
              <Select
                options={inputTypes}
                id="inputType"
                onChange={(value) => {
                  onFormChange({ target: { name: "inputType", value: value.value } });
                }}
              />
            </div>

            <MDBCard className={newPropertyMetadataFormDisplayClass}>
              <MDBCardBody>
                <MDBCardTitle>Available options</MDBCardTitle>
                <form onSubmit={(e) => onSaveNewMetadata(e)}>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Label"
                        value={newPropertyMetadata.label}
                        getValue={(e) => updateNewPropertyMetadata("label", e)}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        label="Value"
                        value={newPropertyMetadata.value}
                        getValue={(e) => updateNewPropertyMetadata("value", e)}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBBtn type="submit" onClick={(e) => onSaveNewMetadata(e)} className="mb-0">
                        Save
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
                {newPropertyMetadataTable}
              </MDBCardBody>
            </MDBCard>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                setNewPropertyData({});
                setNewPropertyMetadata("");
                toggle();
              }}
            >
              Close
            </MDBBtn>
            <MDBBtn color="default" type="submit" onClick={(e) => onSubmit(e)}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </>
  );
};

export default NewPropertyModal;
