import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import React from "react";

const SchemaPreview = ({ schema, canEditConfigurationSets }) => {
  let apiUrl;
  "label" in schema ? (apiUrl = "/" + schema.label) : (apiUrl = "");
  //   let apiUrl = "/" + configurationSet.schema.label.replaceAll(" ", "") + "/";
  // "uuid" in configurationSet ? (apiUrl = apiUrl + configurationSet.uuid) : (apiUrl = apiUrl + "{id not yet available}");
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>API preview</MDBCardTitle>
        <pre>{apiUrl}</pre>
        <pre>
          {schema.inputs.map((input) => {
            return (
              <span key={input.inputInstance.id}>
                --{input.inputInstance.slug} ({input.inputTemplate.slug}) <br />
              </span>
            );
          })}
        </pre>
        {"uuid" in schema && canEditConfigurationSets && (
          <MDBBtn tag="a" href={"/configuration-sets/new/?schemaId=" + schema.uuid}>
            New configuration set
          </MDBBtn>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaPreview;
