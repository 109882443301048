import { MDBCard, MDBCardBody, MDBCardTitle, MDBDataTable } from "mdbreact";
import React from "react";

const DatabaseSyncUninvitedUsers = ({ users }) => {
  let usersData = {
    columns: [
      { label: "Cognito username", field: "uuid" },
      { label: "Email", field: "email" },
      { label: "Name", field: "fullName" },
      { label: "Created", field: "dateCreated" },
      { label: "Modified", field: "dateModified" },
    ],
    rows: users,
  };
  return (
    <section className="user-directory-database-sync-uninvited-users mt-4">
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle tag="h2" className="h3">
            Uninvited users
          </MDBCardTitle>
          <p>
            These users have already been set up in the User Directory, but have not been informed of their login
            details. They will have to use the Console to request a new password.
          </p>
          <MDBDataTable data={usersData} striped searching={false} paging={false} noBottomColumns={true} />
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

export default DatabaseSyncUninvitedUsers;
