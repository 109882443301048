import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon
} from "mdbreact";

const IngredientSetDetailsModal = ({
  detailModalOpen,
  triggerDetailsModal,
  ingredientSet,
  canWrite
}) => {
  useEffect(() => {
    updateModalContent();
    // eslint-disable-next-line
  }, [ingredientSet]);

  const [title, setTitle] = useState("");
  const [ingredientSetContent, setIngredientSetContent] = useState("");
  const [editUrl, setEditUrl] = useState("");

  const updateModalContent = () => {
    if (ingredientSet) {
      setTitle(ingredientSet.displayName);

      const editUrl = "/ingredient-sets/" + ingredientSet.ingredientSetId;
      setEditUrl(editUrl);

      const mainDetails = () => {
        let ingredientSetDetails = ingredientSet;
        const hiddenFields = [
          "name",
          "description",
          "temporaryShotLogDataFilename",
          "editType",
          "creationDate"
        ];
        Object.keys(ingredientSetDetails).forEach(tableField => {
          if (hiddenFields.includes(tableField)) {
            delete ingredientSetDetails[tableField];
          }
        });

        let valueCount = 0;
        let table = (
          <div className="mb-4">
            <MDBTable responsive>
              <MDBTableHead>
                <tr>
                  {Object.keys(ingredientSetDetails).map(field => {
                    return <th key={field}>{field}</th>;
                  })}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr>
                  {Object.values(ingredientSetDetails).map(value => {
                    const valueId = "value-" + valueCount;
                    valueCount++;
                    return <td key={valueId}>{value}</td>;
                  })}
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
        );

        setIngredientSetContent(table);
      };

      mainDetails();
    }
  };

  return (
    <MDBModal
      isOpen={detailModalOpen}
      toggle={triggerDetailsModal}
      size="fluid"
      className="listing-detail-modal"
    >
      <MDBModalHeader toggle={triggerDetailsModal} tag="h2">
        {title}
      </MDBModalHeader>
      <MDBModalBody>{ingredientSetContent}</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={triggerDetailsModal}>
          Close
        </MDBBtn>
        {canWrite ? (
          <MDBBtn color="primary" tag={Link} to={editUrl}>
            <MDBIcon icon="edit" className="mr-2" /> Edit
          </MDBBtn>
        ) : (
          ""
        )}
      </MDBModalFooter>
    </MDBModal>
  );
};

export default IngredientSetDetailsModal;
