import React, { useEffect } from "react";

import apiGatewayCall from "../../../helpers/apiGatewayCall";
import config from "../../../content_types";
import { useState } from "react";
import LoadingIcon from "../../../components/LoadingIcon";
import { MDBBtn, MDBCol, MDBDataTable, MDBIcon, MDBRow } from "mdbreact";
import MediaPulseSyncStatus from "./MediaPulseSyncStatus";
import UsersToNotSync from "./UsersToNotSync";

const MediaPulseSyncHome = () => {
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState(null);
  const [usersToNotSync, setUsersToNotSync] = useState(null);
  const [responses, setResponses] = useState([]);
  const [syncComplete, setSyncComplete] = useState(false);
  useEffect(() => {
    getUsersToSync();
  }, []);

  const getUsersToSync = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/user-directory-mediapulse-sync/", "get")
      .then((response) => {
        setLoading(false);        
        setAllUsers(() => {
          let _allUsers = [];
          response.usersToSync.forEach((user) => {
            let _user = { ...user };
            _user.actions = [
              <a
                key={"user-edit-link-" + _user.uuid}
                href={"/user-management/users/" + _user.uuid}
                className="float-right"
              >
                <MDBIcon icon="edit" /> Open in User Directory
              </a>,
            ];
            _allUsers.push(_user);
          });          
          return _allUsers;
        });        
        setUsersToNotSync(response.usersToNotSync);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  let tableData;
  if (allUsers) {
    tableData = {
      columns: [
        { label: "Email address", field: "email" },
        { label: "Name", field: "fullName" },
        { label: "Cognito user ID", field: "uuid" },
        { label: "Legacy agent hash", field: "legacyAgentHash" },
        { label: "Current MediaPulse resource code", field: "resourceCode" },
        { label: "", field: "actions" },
      ],
      rows: allUsers,
    };
  }

  const onSyncClick = async (e) => {
    e.preventDefault();
    runSync();
  };

  const runSync = async () => {
    for (let index = 0; index < allUsers.length; index++) {
      const user = allUsers[index];
      await apiGatewayCall(config.api_name, "/user-management/user-directory-mediapulse-sync/" + user.uuid + "/", "get")
        .then((response) => {
          if (response.statusCode === 200) {
            setResponses((oldResponses) => {
              let _responses = [...oldResponses];
              _responses.push(response);
              return _responses;
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      if (index === allUsers.length - 1) setSyncComplete(true);
    }
  };

  return (
    <div>
      {loading && <LoadingIcon />}
      <h1 className="page-title">MediaPulse resource sync</h1>
      {allUsers && (
        <section className="mediapulse-sync">
          <section className="mediapulse-sync-input mb-4">
            <MDBDataTable
              data={tableData}
              displayEntries={false}
              noBottomColumns={true}
              searching={false}
              sortable={false}
              paging={false}
              noRecordsFoundLabel="No users are currently flagged for import into MediaPulse."
              hover
            />
            <footer className="mb-4">
              <MDBRow>
                <MDBCol>
                  {usersToNotSync && <UsersToNotSync usersToNotSync={usersToNotSync} getUsersToSync={getUsersToSync} />}
                </MDBCol>
                <MDBCol className="text-right">
                  <MDBBtn onClick={(e) => onSyncClick(e)}>Go</MDBBtn>
                </MDBCol>
              </MDBRow>
            </footer>
          </section>

          {responses.length ? <MediaPulseSyncStatus responses={responses} complete={syncComplete} /> : null}
        </section>
      )}
    </div>
  );
};

export default MediaPulseSyncHome;
