import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBSelect } from "mdbreact";
import { Auth, API } from "aws-amplify";
import LoadingIcon from "../../components/LoadingIcon";
import { Redirect } from "react-router-dom";
import setPageTitle from "../../helpers/setPageTitle";

const ServiceTemplatesNew = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [newServiceTemplateId, setNewServiceTemplateId] = useState(null);

  const [referenceName, setReferenceName] = useState("");
  const [legacyReference, setlegacyReference] = useState("");
  const [take1Featured, setTake1Featured] = useState("");
  const [restrictedToCustomerId, setRestrictedToCustomerId] = useState("");
  const [ingredientSetId, setIngredientSetId] = useState("");
  const [deprecated, setDeprecated] = useState("");
  const [validationDataProcessSetId, setValidationDataProcessSetId] = useState(
    ""
  );
  const [status, setStatus] = useState("");

  const [take1FeaturedOptions, setTake1FeaturedOptions] = useState([]);
  const [
    restrictedToCustomerOptions,
    setRestrictedToCustomerOptions
  ] = useState([]);
  const [ingredientSetOptions, setIngredientSetOptions] = useState([]);
  const [deprecatedOptions, setDeprecatedOptions] = useState([]);
  const [
    validationDataSetProcessIdOptions,
    setValidationDataSetProcessIdOptions
  ] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    const response = await API.get(
      "take-1-console",
      "/ingredient-sets",
      requestOptions
    );
    if (response) {
      setLoading(false);
      setPageTitle("New service template");
      setTake1FeaturedOptions([
        { text: "Not featured", value: "0", checked: true },
        { text: "Featured", value: "1" }
      ]);
      setRestrictedToCustomerOptions([
        {
          text: "Not restricted",
          value: "0",
          checked: true
        },
        { text: "Restricted", value: "1" }
      ]);
      setDeprecatedOptions([
        { text: "Not deprecated", value: "0", checked: true },
        { text: "Deprecated", value: "1" }
      ]);
      setStatusOptions([
        { text: "Inactive", value: "0" },
        { text: "Active", value: "1", checked: true }
      ]);
      setValidationDataSetProcessIdOptions([
        { text: "0", value: "0", checked: true },
        { text: "1", value: "1" }
      ]);
      let ingredientSets = [];
      ingredientSets.push({
        text: "None",
        value: 0,
        checked: true
      });
      response.ingredient_sets.map(ingredient_set => {
        let ingredientSet = {};
        ingredientSet.text = ingredient_set.displayName;
        ingredientSet.value = ingredient_set.ingredientSetId;
        ingredientSets.push(ingredientSet);
        return true;
      });
      setIngredientSetOptions(ingredientSets);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        take1Featured: take1Featured,
        referenceName: referenceName,
        legacyReference: legacyReference,
        restrictedToCustomerId: restrictedToCustomerId,
        ingredientSetId: ingredientSetId,
        deprecated: deprecated,
        validationDataProcessSetId: validationDataProcessSetId,
        status: status
      }
    };
    await API.put("take-1-console", "/service-templates/", requestOptions).then(
      response => {
        if (response.statusCode === 201) {
          afterSuccessfulSubmit(response);
        } else {
          alert("Not authorised!");
          window.location.href = "/";
        }
      }
    );
  };

  const afterSuccessfulSubmit = response => {
    setLoading(false);
    setNewServiceTemplateId(response.new_service_template);
    setRedirectToEdit(true);
  };

  let editForm = "";

  if (loading) {
    editForm = <LoadingIcon />;
  } else {
    if (redirectToEdit === true) {
      const newUrl = "/service-templates/" + newServiceTemplateId;
      editForm = <Redirect to={newUrl} />;
    } else {
      editForm = (
        <>
          <MDBRow>
            <MDBCol>
              <h1 className="page-title">New service template</h1>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol sm="12" xl="6">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <MDBInput
                    label="Reference name"
                    type="text"
                    value={referenceName}
                    onChange={e => setReferenceName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <MDBInput
                    label="Legacy reference name"
                    type="text"
                    value={legacyReference}
                    onChange={e => setlegacyReference(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <MDBSelect
                    label="Take 1 featured"
                    options={take1FeaturedOptions}
                    getValue={e => setTake1Featured(e[0])}
                  />
                </div>

                <div className="form-group">
                  <MDBSelect
                    label="Restricted to customer"
                    options={restrictedToCustomerOptions}
                    getValue={e => setRestrictedToCustomerId(e[0])}
                  />
                </div>

                <div className="form-group">
                  <MDBSelect
                    label="Ingredient set"
                    options={ingredientSetOptions}
                    getValue={e => setIngredientSetId(e[0])}
                  />
                </div>

                <div className="form-group">
                  <MDBSelect
                    label="Deprecated"
                    options={deprecatedOptions}
                    getValue={e => setDeprecated(e[0])}
                  />
                </div>

                <div className="form-group">
                  <MDBSelect
                    label="Validation data process set ID"
                    options={validationDataSetProcessIdOptions}
                    getValue={e => setValidationDataProcessSetId(e[0])}
                  />
                </div>

                <div className="form-group">
                  <MDBSelect
                    label="Status"
                    options={statusOptions}
                    getValue={e => setStatus(e[0])}
                  />
                </div>

                <MDBBtn color="primary" type="submit">
                  Submit
                </MDBBtn>
              </form>
            </MDBCol>
          </MDBRow>
        </>
      );
    }
  }
  return <>{editForm}</>;
};

export default ServiceTemplatesNew;
