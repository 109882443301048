import React, { useState } from 'react';
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBDataTable,
  MDBRow,
  MDBCol,
  MDBInput,
} from 'mdbreact';
import { generate } from 'generate-password';
import sortArrayOfObjectsByKey from '../../../helpers/sortArrayOfObjectsByKey';

const DatabaseSyncConfirmation = ({
  legacyDatabaseUsers,
  setLegacyDatabaseUsers,
  setStarted,
  setLegacyDatabaseUsersRows,
  generateTableRows,
  usersToSync,
  setUsersToSync,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [usersToSync, setUsersToSync] = useState(null);
  const [includeExistingUsers, setIncludeExistingUsers] = useState(true);

  const generateUsersToSync = () => {
    setUsersToSync(() => {
      let _usersToSync = [];
      legacyDatabaseUsers.forEach((user) => {
        if (user.includeInSync) {
          if (includeExistingUsers) {
            _usersToSync.push(generateUserToSync(user));
          } else {
            if (user.t1ud_uuid === '') {
              _usersToSync.push(generateUserToSync(user));
            }
          }
        }
      });
      _usersToSync = sortArrayOfObjectsByKey(_usersToSync, 'userId');
      return _usersToSync;
    });
  };

  const generateUserToSync = (user) => {
    const userPassword = generate({
      length: 8,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      exclude: '"#:;[](){}',
      strict: true,
    });

    let [isExistingUserDirectoryUser] = [user.t1ud_uuid !== ''];

    let userObject = {
      userId: user.EmployeeId_legacy_emp_id,
      fullName: user.fullName,
      email: user.email,
      createdDate: user.DateAdded_date_added,
      password: userPassword,
      sendInvite: user.sendInviteEmail,
      roleSetManually: false,
      isExistingUserDirectoryUser: isExistingUserDirectoryUser,
      userDirectoryId: user.t1ud_uuid,
      mp_LegacyAgentHash_mp_legacy_agent_hash: user.mp_LegacyAgentHash_mp_legacy_agent_hash,
      roles: user.defaultRoles,
      properties: user.properties,
    };

    return userObject;
  };

  const onStartButtonClick = (e) => {
    e.preventDefault();
    generateUsersToSync();
    toggleOpen();
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onConfirmClick = () => {
    toggleOpen();
    setStarted(true);
  };

  const onIncludeExistingUsersChange = (e) => {
    setIncludeExistingUsers(e.target.checked);
    setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
      let _users = [];
      oldLegacyDatabaseUsers.forEach((user) => {
        if (user.t1ud_uuid !== '') {
          if (!e.target.checked) {
            user.includeInSync = false;
          }
        }
        _users.push(user);
      });
      setLegacyDatabaseUsersRows(generateTableRows(_users));
      return _users;
    });
  };

  return (
    <div>
      <MDBRow>
        <MDBCol size='12' md='4'>
          <MDBInput
            type='checkbox'
            label='Include existing users'
            id='include-existing-users-checkbox'
            checked={includeExistingUsers}
            onChange={(e) => {
              onIncludeExistingUsersChange(e);
            }}
          />
        </MDBCol>
        <MDBCol size='12' md='8'>
          <MDBBtn onClick={(e) => onStartButtonClick(e)} className='float-right'>
            Start sync
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBModal isOpen={isOpen} toggle={toggleOpen} size='lg' className='start-user-directory-sync-modal'>
        <MDBModalHeader>Start user directory sync</MDBModalHeader>
        <MDBModalBody>
          <p>The following data will be loaded into the Take 1 user directory:</p>
          <MDBDataTable
            displayEntries={false}
            striped
            searching={false}
            noBottomColumns
            data={{
              columns: [
                { label: 'fullName', field: 'fullName' },
                { label: 'email', field: 'email' },
                { label: 'createdDate', field: 'createdDate' },
                { label: 'password', field: 'password' },
              ],
              rows: usersToSync,
            }}
            info={false}
            paging={false}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn onClick={(e) => toggleOpen()} color='secondary'>
            Cancel
          </MDBBtn>
          <MDBBtn onClick={(e) => onConfirmClick()}>Go</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
};

export default DatabaseSyncConfirmation;
