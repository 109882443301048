import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import setPageTitle from "../../helpers/setPageTitle";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";
import LoadingIcon from "../../components/LoadingIcon";
import { MDBBtn, MDBDataTable, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import NewPropertyModal from "../../components/UserManagement/NewPropertyModal";
import EditProperty from "../../components/UserManagement/EditProperty";
import UserPropertySetupPropertyTypes from "./UserPropertySetupPropertyTypes";

export const UserPropertySetup = () => {
  setPageTitle("User property setup");
  useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [allProperties, setAllProperties] = useState({});
  const [propertyTypes, setPropertyTypes] = useState([]);

  const [propertyToDelete, setPropertyToDelete] = useState({});
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [propertyToEdit, setPropertyToEdit] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const getProperties = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/properties", "get")
      .then((response) => {
        setAllProperties(processProperties(response.properties, response.property_types.propertyTypes));
        setPropertyTypes(response.property_types.propertyTypes);
        setCanCreate(response.can_create);
        setCanEdit(response.can_edit);
        setCanDelete(response.can_delete);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to display a list of properties.", "warning");
      });
  };

  const onPropertyClick = (property) => {
    setPropertyToEdit(() => {
      if ("metadata" in property) {
        if (!Array.isArray(property.metadata)) {
          property.metadata = JSON.parse(property.metadata);
        }
      }
      return property;
    });
    setEditModalOpen(true);
  };

  const processProperties = (properties, allPropertyTypes) => {
    properties.sort();
    const columns = [
      {
        label: "Label",
        field: "label",
      },
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Description",
        field: "description",
      },
      {
        label: "Input type",
        field: "inputType",
      },
      {
        label: "Type",
        field: "propertyType",
      },
      {
        label: "PII",
        field: "pii",
      },
    ];
    let rows = [];
    properties.map((property) => {
      let rowData = {
        label: property.label,
        inputType: property.inputType,
        clickEvent: (e) => onPropertyClick(property),
        name: property.name,
        description: null,
        pii: null,
      };
      if (property.description !== undefined) rowData.description = property.description;
      if (property.pii !== undefined) rowData.pii = property.pii.toString();
      if (property.propertyType !== undefined) {
        allPropertyTypes.forEach((propertyType) => {
          if (propertyType.value === property.propertyType) rowData.propertyType = propertyType.label;
        });
        // rowData.propertyType = property.propertyType;
      }

      rows.push(rowData);
      return true;
    });
    return {
      columns: columns,
      rows: rows,
    };
  };

  const onDeletePropertyClick = (property) => {
    setPropertyToDelete(property);
    setConfirmDeleteModalOpen(true);
  };

  const deleteProperty = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/properties/" + propertyToDelete.uuid, "del")
      .then(() => {
        setConfirmDeleteModalOpen(false);
        showNotification("Property deleted", propertyToDelete.label + " was successfully deleted.", "success");
        getProperties();
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to delete properties.", "warning");
      });
  };

  const toggleDeleteModal = () => {
    setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
  };

  const onSaveProperty = async (property) => {
    setIsLoading(true);
    const options = {
      body: property,
    };
    await apiGatewayCall(config.api_name, "/user-management/properties/" + property.uuid, "post", options)
      .then(() => {
        setIsLoading(false);
        setEditModalOpen(false);
        getProperties();
        setPropertyToEdit(null);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        showNotification("Access denied", "You do not have permission to edit properties.", "warning");
      });
  };

  let loadingContent = null;
  if (isLoading) loadingContent = <LoadingIcon />;

  let editModalContent;
  if (propertyToEdit) {
    editModalContent = (
      <EditProperty
        propertyToEdit={propertyToEdit}
        setPropertyToEdit={setPropertyToEdit}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        onSaveProperty={onSaveProperty}
        canEdit={canEdit}
        canDelete={canDelete}
        onDeletePropertyClick={onDeletePropertyClick}
        propertyTypes={propertyTypes}
      />
    );
  }

  return (
    <>
      {loadingContent}
      <MDBRow>
        <MDBCol>
          <h1>User property setup</h1>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {canCreate && (
            <NewPropertyModal setIsLoading={setIsLoading} getProperties={getProperties} propertyTypes={propertyTypes} />
          )}
          <MDBDataTable
            key="current-properties-table"
            hover
            entriesOptions={[50, 100]}
            entries={100}
            pagesAmount={4}
            data={allProperties}
            noRecordsFoundLabel="No properties to display."
            displayEntries={false}
            noBottomColumns={true}
            paging={false}
            className="clickable-table"
          />
          <MDBModal isOpen={confirmDeleteModalOpen} toggle={toggleDeleteModal}>
            <MDBModalHeader>Delete property</MDBModalHeader>
            <MDBModalBody>
              Are you sure you want to delete {propertyToDelete.label}? This action cannot be undone, and will affect
              every user that has this property.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={(e) => toggleDeleteModal()}>
                Cancel
              </MDBBtn>
              <MDBBtn color="primary" onClick={(e) => deleteProperty()}>
                OK
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          {editModalContent}
        </MDBCol>
      </MDBRow>
      <UserPropertySetupPropertyTypes propertyTypes={propertyTypes} getProperties={getProperties} />
    </>
  );
};
