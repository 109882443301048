import React, { useEffect, useState } from "react";
import { MDBModalBody, MDBModalFooter, MDBBtn, MDBCol, MDBRow } from "mdbreact";
import DeleteWorkflowButton from "./DeleteWorkflowButton";

const AssemblyItemForm = ({
  workflowItem,
  saveClick,
  workflowModalToggle,
  afterSuccessfulSubmit
}) => {
  useEffect(() => {
    buildFormData();
    // eslint-disable-next-line
  }, []);

  const saveClickConstructor = () => {
    let requestBody = {
      type: "AI",
      assemblyInstructionTemplateId: assemblyInstructionTemplateId,
      automationStatusOverride: automationStatusOverride,
      chosenContentDPSetId: chosenContentDPSetId,
      chosenFinishingDPSetId: chosenFinishingDPSetId,
      chosenFormattingDPSetId: chosenFormattingDPSetId,
      chosenLocalisationDPSetId: chosenLocalisationDPSetId,
      chosenRenderingDPSetId: chosenRenderingDPSetId,
      chosenStructureDPSetId: chosenStructureDPSetId,
      documentFormatId: documentFormatId,
      documentMarginPropertiesOverrideId: documentMarginPropertiesOverrideId,
      documentPagePropertiesOverrideId: documentPagePropertiesOverrideId,
      documentTablePropertiesOverrideId: documentTablePropertiesOverrideId,
      fileConversionLocationOverrideId: fileConversionLocationOverrideId,
      fileNameTypeId: fileNameTypeId,
      fileNameTypePrefix: fileNameTypePrefix,
      fileNameTypeSuffix: fileNameTypeSuffix,
      ingredientSetLimitationId: ingredientSetLimitationId,
      legacyReference: legacyReference,
      notes: notes,
      productFileTypeOverrideId: productFileTypeOverrideId,
      sortOrder: sortOrder,
      status: status
    };

    saveClick(requestBody);
  };

  const [
    assemblyInstructionTemplateId,
    setAssemblyInstructionTemplateId
  ] = useState(workflowItem.main_table_data.assemblyInstructionTemplateId);
  const [automationStatusOverride, setAutomationStatusOverride] = useState(
    workflowItem.main_table_data.automationStatusOverride
  );
  const [chosenContentDPSetId, setChosenContentDPSetId] = useState(
    workflowItem.main_table_data.chosenContentDPSetId
  );
  const [chosenFinishingDPSetId, setChosenFinishingDPSetId] = useState(
    workflowItem.main_table_data.chosenFinishingDPSetId
  );
  const [chosenFormattingDPSetId, setChosenFormattingDPSetId] = useState(
    workflowItem.main_table_data.chosenFormattingDPSetId
  );
  const [chosenLocalisationDPSetId, setChosenLocalisationDPSetId] = useState(
    workflowItem.main_table_data.chosenLocalisationDPSetId
  );
  const [chosenRenderingDPSetId, setChosenRenderingDPSetId] = useState(
    workflowItem.main_table_data.chosenRenderingDPSetId
  );
  const [chosenStructureDPSetId, setChosenStructureDPSetId] = useState(
    workflowItem.main_table_data.chosenStructureDPSetId
  );
  const [documentFormatId, setDocumentFormatId] = useState(
    workflowItem.main_table_data.documentFormatId
  );
  const [
    documentMarginPropertiesOverrideId,
    setDocumentMarginPropertiesOverrideId
  ] = useState(workflowItem.main_table_data.documentMarginPropertiesOverrideId);
  const [
    documentPagePropertiesOverrideId,
    setDocumentPagePropertiesOverrideId
  ] = useState(workflowItem.main_table_data.documentPagePropertiesOverrideId);
  const [
    documentTablePropertiesOverrideId,
    setDocumentTablePropertiesOverrideId
  ] = useState(workflowItem.main_table_data.documentTablePropertiesOverrideId);
  const [
    fileConversionLocationOverrideId,
    setFileConversionLocationOverrideId
  ] = useState(workflowItem.main_table_data.fileConversionLocationOverrideId);
  const [fileNameTypeId, setFileNameTypeId] = useState(
    workflowItem.main_table_data.fileNameTypeId
  );
  const [fileNameTypePrefix, setFileNameTypePrefix] = useState(
    workflowItem.main_table_data.fileNameTypePrefix
  );
  const [fileNameTypeSuffix, setFileNameTypeSuffix] = useState(
    workflowItem.main_table_data.fileNameTypeSuffix
  );
  const [ingredientSetLimitationId, setIngredientSetLimitationId] = useState(
    workflowItem.main_table_data.ingredientSetLimitationId
  );
  const [legacyReference, setLegacyReference] = useState(
    workflowItem.main_table_data.legacyReference
  );
  const [notes, setNotes] = useState(workflowItem.main_table_data.notes);
  const [productFileTypeOverrideId, setProductFileTypeOverrideId] = useState(
    workflowItem.main_table_data.productFileTypeOverrideId
  );
  const [sortOrder, setSortOrder] = useState(
    workflowItem.main_table_data.sortOrder
  );
  const [status, setStatus] = useState(workflowItem.main_table_data.status);

  const [formContent, setFormContent] = useState([]);

  const buildFormData = async () => {
    const originalFields = workflowItem.main_table_data;
    const allOptions = workflowItem.form_values;

    let inputs = [];
    const field_assemblyInstructionTemplateId = (
      <div className="form-group" key="field_assemblyInstructionTemplateId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="field_assemblyInstructionTemplateId">
              Assembly instruction template
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={originalFields.assemblyInstructionTemplateId}
              onChange={e => setAssemblyInstructionTemplateId(e.target.value)}
              id="field_assemblyInstructionTemplateId"
            >
              {workflowItem.templates_data.map(template => {
                return (
                  <option
                    key={template[workflowItem.template_table_id_field]}
                    value={template[workflowItem.template_table_id_field]}
                  >
                    {template[workflowItem.template_table_id_field]}:{" "}
                    {template.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_assemblyInstructionTemplateId);
    const field_ingredientSetLimitationId = (
      <div className="form-group" key="field_ingredientSetLimitationId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="field_ingredientSetLimitationId">
              Ingredient set limitation
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={ingredientSetLimitationId}
              onChange={e => setIngredientSetLimitationId(e.target.value)}
              id="field_ingredientSetLimitationId"
            >
              <option value="0">0: None</option>
              {allOptions.ingredient_sets.map(template => {
                return (
                  <option
                    key={template.ingredientSetId}
                    value={template.ingredientSetId}
                  >
                    {template.ingredientSetId}: {template.displayName}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_ingredientSetLimitationId);
    const field_notes = (
      <div className="form-group" key="field_notes">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="field_notes">Notes</label>
          </MDBCol>
          <MDBCol size="8">
            <input
              type="text"
              id="field_notes"
              className="form-control"
              defaultValue={notes}
              onChange={e => setNotes(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_notes);
    const field_sortOrder = (
      <div className="form-group" key="field_sortOrder">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="field_sortOrder">Sort order</label>
          </MDBCol>
          <MDBCol size="8">
            <input
              type="number"
              id="field_sortOrder"
              className="form-control"
              defaultValue={sortOrder}
              onChange={e => setSortOrder(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_sortOrder);
    const field_documentFormatId = (
      <div className="form-group" key="field_documentFormatId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="documentFormatId">Document format</label>{" "}
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={documentFormatId}
              onChange={e => setDocumentFormatId(e.target.value)}
              id="documentFormatId"
            >
              {allOptions.document_format.map(template => {
                return (
                  <option
                    key={template.documentFormatId}
                    value={template.documentFormatId}
                  >
                    {template.documentFormatId}: {template.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_documentFormatId);
    const field_productFileTypeOverrideId = (
      <div className="form-group" key="field_productFileTypeOverrideId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="productFileTypeOverrideId">
              Product file type override
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={productFileTypeOverrideId}
              onChange={e => setProductFileTypeOverrideId(e.target.value)}
              id="productFileTypeOverrideId"
            >
              <option value="0">0: None</option>

              {allOptions.file_types.map(template => {
                return (
                  <option key={template.fileTypeId} value={template.fileTypeId}>
                    {template.fileTypeId}: {template.typeName}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_productFileTypeOverrideId);
    const field_fileConversionLocationOverrideId = (
      <div className="form-group" key="field_fileConversionLocationOverrideId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="fileConversionLocationOverrideId">
              File conversion location override
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={fileConversionLocationOverrideId}
              onChange={e =>
                setFileConversionLocationOverrideId(e.target.value)
              }
              id="fileConversionLocationOverrideId"
            >
              <option value="0">0: None</option>

              {allOptions.file_conversion.map(template => {
                return (
                  <option
                    key={template.fileConversionLocationId}
                    value={template.fileConversionLocationId}
                  >
                    {template.fileConversionLocationId}: {template.notes}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileConversionLocationOverrideId);
    const field_status = (
      <div className="form-group" key="field_status">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="field_status">Status</label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={status}
              onChange={e => setStatus(e.target.value)}
              id="field_status"
            >
              <option value="1">Active</option>
              <option value="0">Disabled</option>
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_status);
    const field_automationStatusOverride = (
      <div className="form-group" key="field_automationStatusOverride">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="automationStatusOverride">
              Automation status override
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={automationStatusOverride}
              onChange={e => setAutomationStatusOverride(e.target.value)}
              id="automationStatusOverride"
            >
              <option value="0">0: None</option>

              {allOptions.automation_status.map(template => {
                return (
                  <option
                    key={template.layoutStatusId}
                    value={template.layoutStatusId}
                  >
                    {template.layoutStatusId}: {template.description}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_automationStatusOverride);
    const field_chosenLocalisationDPSetId = (
      <div className="form-group" key="field_chosenLocalisationDPSetId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="chosenLocalisationDPSetId">
              Chosen locatisation task set
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={chosenLocalisationDPSetId}
              onChange={e => setChosenLocalisationDPSetId(e.target.value)}
              id="chosenLocalisationDPSetId"
            >
              {allOptions.task_sets.map(template => {
                if (
                  template.setTypeId === 16 ||
                  template.setTypeId === 8 ||
                  template.dataProcessSetId === 0
                ) {
                  return (
                    <option
                      key={template.dataProcessSetId}
                      value={template.dataProcessSetId}
                    >
                      {template.dataProcessSetId}: {template.setName}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_chosenLocalisationDPSetId);
    const field_chosenStructureDPSetId = (
      <div className="form-group" key="field_chosenStructureDPSetId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="chosenStructureDPSetId">
              Chosen structure task set
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={chosenStructureDPSetId}
              onChange={e => setChosenStructureDPSetId(e.target.value)}
              id="chosenStructureDPSetId"
            >
              {allOptions.task_sets.map(template => {
                if (
                  template.setTypeId === 17 ||
                  template.setTypeId === 9 ||
                  template.dataProcessSetId === 0
                ) {
                  return (
                    <option
                      key={template.dataProcessSetId}
                      value={template.dataProcessSetId}
                    >
                      {template.dataProcessSetId}: {template.setName}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_chosenStructureDPSetId);
    const field_chosenContentDPSetId = (
      <div className="form-group" key="field_chosenContentDPSetId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="chosenContentDPSetId">
              Chosen content task set
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={chosenContentDPSetId}
              onChange={e => setChosenContentDPSetId(e.target.value)}
              id="chosenContentDPSetId"
            >
              {allOptions.task_sets.map(template => {
                if (
                  template.setTypeId === 18 ||
                  template.setTypeId === 10 ||
                  template.dataProcessSetId === 0
                ) {
                  return (
                    <option
                      key={template.dataProcessSetId}
                      value={template.dataProcessSetId}
                    >
                      {template.dataProcessSetId}: {template.setName}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_chosenContentDPSetId);
    const field_chosenFormattingDPSetId = (
      <div className="form-group" key="field_chosenFormattingDPSetId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="chosenFormattingDPSetId">
              Chosen formatting task set
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={chosenFormattingDPSetId}
              onChange={e => setChosenFormattingDPSetId(e.target.value)}
              id="chosenFormattingDPSetId"
            >
              {allOptions.task_sets.map(template => {
                if (
                  template.setTypeId === 19 ||
                  template.setTypeId === 11 ||
                  template.dataProcessSetId === 0
                ) {
                  return (
                    <option
                      key={template.dataProcessSetId}
                      value={template.dataProcessSetId}
                    >
                      {template.dataProcessSetId}: {template.setName}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_chosenFormattingDPSetId);
    const field_chosenRenderingDPSetId = (
      <div className="form-group" key="field_chosenRenderingDPSetId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="chosenRenderingDPSetId">
              Chosen rendering task set
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={chosenRenderingDPSetId}
              onChange={e => setChosenRenderingDPSetId(e.target.value)}
              id="chosenRenderingDPSetId"
            >
              {allOptions.task_sets.map(template => {
                if (
                  template.setTypeId === 20 ||
                  template.setTypeId === 7 ||
                  template.dataProcessSetId === 0
                ) {
                  return (
                    <option
                      key={template.dataProcessSetId}
                      value={template.dataProcessSetId}
                    >
                      {template.dataProcessSetId}: {template.setName}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_chosenRenderingDPSetId);
    const field_chosenFinishingDPSetId = (
      <div className="form-group" key="field_chosenFinishingDPSetId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="chosenFinishingDPSetId">
              Chosen finishing task set
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={chosenFinishingDPSetId}
              onChange={e => setChosenFinishingDPSetId(e.target.value)}
              id="chosenFinishingDPSetId"
            >
              {allOptions.task_sets.map(template => {
                if (
                  template.setTypeId === 21 ||
                  template.setTypeId === 6 ||
                  template.dataProcessSetId === 0
                ) {
                  return (
                    <option
                      key={template.dataProcessSetId}
                      value={template.dataProcessSetId}
                    >
                      {template.dataProcessSetId}: {template.setName}
                    </option>
                  );
                } else {
                  return false;
                }
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_chosenFinishingDPSetId);
    const field_documentMarginPropertiesOverrideId = (
      <div
        className="form-group"
        key="field_documentMarginPropertiesOverrideId"
      >
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="documentMarginPropertiesOverrideId">
              Document margin properties override
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={documentMarginPropertiesOverrideId}
              onChange={e =>
                setDocumentMarginPropertiesOverrideId(e.target.value)
              }
              id="documentMarginPropertiesOverrideId"
            >
              <option value="0">0: None</option>
              {allOptions.document_margin_properties.map(template => {
                return (
                  <option
                    key={template.documentMarginPropertiesId}
                    value={template.documentMarginPropertiesId}
                  >
                    {template.documentMarginPropertiesId}: {template.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_documentMarginPropertiesOverrideId);
    const field_documentTablePropertiesOverrideId = (
      <div className="form-group" key="field_documentTablePropertiesOverrideId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="documentTablePropertiesOverrideId">
              Document table properties override
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={documentTablePropertiesOverrideId}
              onChange={e =>
                setDocumentTablePropertiesOverrideId(e.target.value)
              }
              id="documentTablePropertiesOverrideId"
            >
              <option value="0">0: None</option>
              {allOptions.document_table_properties.map(template => {
                return (
                  <option
                    key={template.documentTablePropertiesId}
                    value={template.documentTablePropertiesId}
                  >
                    {template.documentTablePropertiesId}: {template.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_documentTablePropertiesOverrideId);
    const field_documentPagePropertiesOverrideId = (
      <div className="form-group" key="field_documentPagePropertiesOverrideId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="documentPagePropertiesOverrideId">
              Document page properties override
            </label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={documentPagePropertiesOverrideId}
              onChange={e =>
                setDocumentPagePropertiesOverrideId(e.target.value)
              }
              id="documentPagePropertiesOverrideId"
            >
              <option value="0">0: None</option>
              {allOptions.document_page_properties.map(template => {
                return (
                  <option
                    key={template.documentPagePropertiesId}
                    value={template.documentPagePropertiesId}
                  >
                    {template.documentPagePropertiesId}: {template.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_documentPagePropertiesOverrideId);
    const field_fileNameTypePrefix = (
      <div className="form-group" key="field_fileNameTypePrefix">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="fileNameTypePrefix">Filename type prefix</label>
          </MDBCol>
          <MDBCol size="8">
            <input
              type="text"
              id="fileNameTypePrefix"
              className="form-control"
              defaultValue={fileNameTypePrefix}
              onChange={e => setFileNameTypePrefix(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileNameTypePrefix);
    const field_fileNameTypeId = (
      <div className="form-group" key="field_fileNameTypeId">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="fileNameTypeId">Filename type</label>
          </MDBCol>
          <MDBCol size="8">
            <select
              className="browser-default custom-select"
              defaultValue={fileNameTypeId}
              onChange={e => setFileNameTypeId(e.target.value)}
              id="fileNameTypeId"
            >
              {allOptions.file_name_type.map(template => {
                return (
                  <option
                    key={template.fileNameTypeId}
                    value={template.fileNameTypeId}
                  >
                    {template.fileNameTypeId}: {template.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileNameTypeId);
    const field_fileNameTypeSuffix = (
      <div className="form-group" key="field_fileNameTypeSuffix">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="fileNameTypeSuffix">Filename type suffix</label>
          </MDBCol>
          <MDBCol size="8">
            <input
              type="text"
              id="fileNameTypeSuffix"
              className="form-control"
              defaultValue={fileNameTypeSuffix}
              onChange={e => setFileNameTypeSuffix(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_fileNameTypeSuffix);
    const field_legacyReference = (
      <div className="form-group" key="field_legacyReference">
        <MDBRow>
          <MDBCol size="4">
            <label htmlFor="legacyReference">Legacy reference</label>
          </MDBCol>
          <MDBCol size="8">
            <input
              type="text"
              id="legacyReference"
              className="form-control"
              defaultValue={legacyReference}
              onChange={e => setLegacyReference(e.target.value)}
            />
          </MDBCol>
        </MDBRow>
      </div>
    );
    inputs.push(field_legacyReference);

    setFormContent(inputs);
  };

  return (
    <>
      <MDBModalBody>
        <form onSubmit={e => saveClickConstructor()}>{formContent}</form>
      </MDBModalBody>
      <MDBModalFooter>
        <DeleteWorkflowButton
          workflowItem={workflowItem}
          afterSuccessfulSubmit={afterSuccessfulSubmit}
        />
        <MDBBtn color="secondary" onClick={workflowModalToggle}>
          Cancel
        </MDBBtn>
        <MDBBtn onClick={e => saveClickConstructor()} color="primary">
          Save
        </MDBBtn>
      </MDBModalFooter>
    </>
  );
};

export default AssemblyItemForm;
