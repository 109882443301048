import React, { useState, useEffect } from "react";
import { MDBBtn, MDBCol, MDBDataTable, MDBIcon, MDBRow } from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";
import setPageTitle from "../../helpers/setPageTitle";

const SchemasHome = () => {
  setPageTitle("Schemas");

  const [schemas, setSchemas] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSchemas();
  }, []);

  const getSchemas = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/schemas", "get")
      .then((response) => {
        setLoading(false);
        setSchemas(response.schemas);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  let tableData = {
    columns: [
      { label: "Name", field: "label" },
      { label: "", field: "actions", sort: "disabled" },
    ],
    rows: [],
  };
  schemas &&
    schemas.forEach((schema) => {
      let _row = { ...schema };
      _row.actions = (
        <a href={"/schemas/" + schema.uuid} className="float-right">
          <MDBIcon icon="edit" />
        </a>
      );
      tableData.rows.push(_row);
    });

  return (
    <article className="page page-schemas">
      <MDBRow>
        <MDBCol size="11">
          <h1 className="page-title">Schemas</h1>
        </MDBCol>
        <MDBCol size="1">
          <MDBIcon
            icon="sync"
            spin={loading}
            onClick={(e) => getSchemas()}
            className="clickable float-right"
            size="1x"
          />
        </MDBCol>
      </MDBRow>
      {schemas ? (
        <section className="section schema-listing">
          <MDBDataTable
            data={tableData}
            hover
            noBottomColumns
            displayEntries={false}
            paging={false}
            barReverse
            noRecordsFoundLabel="No schemas found."
          />
        </section>
      ) : (
        <p>Loading schemas...</p>
      )}
      <MDBBtn tag="a" href="/schemas/new" className="ml-0">
        New schema
      </MDBBtn>
      {loading && <LoadingIcon />}
    </article>
  );
};

export default SchemasHome;
