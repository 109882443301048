import config from "../config";
const setPageTitle = title => {
  let windowTitle = "";
  windowTitle = title + " - " + windowTitle;
  windowTitle = windowTitle + "Take 1 Console";
  if (config.environmentName !== "production") {
    windowTitle = windowTitle + " (" + config.environmentName + ")";
    // windowTitle = config.environmentName + " | " + windowTitle;
  }
  document.title = windowTitle;
};
export { setPageTitle as default };
