/* eslint-disable no-unused-vars */

import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";
import setPageTitle from "../../helpers/setPageTitle";
import { MDBBtn, MDBIcon } from "mdbreact";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBCollapseHeader,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from "mdbreact";
import QrCode from "../../components/QRCode";
import CopyIcon from "../../components/CopyIcon";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import showNotification from "../../helpers/showNotification";
import WorkflowMonitoringInstanceNotes from "./WorkflowMonitoringInstanceNotes";

const WorkflowMonitoringInstance = ({ match }) => {
  const recordId = match.params.id;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [instanceInstance, setInstanceInstance] = useState(null);
  const [instanceConnection, setInstanceConnection] = useState(null);
  const [instanceWorkflow, setInstanceWorkflow] = useState(null);
  const [instanceNotes, setInstanceNotes] = useState([]);
  const [instanceDdb, setInstanceDdb] = useState(null);
  const [collapseID, setCollapseId] = useState(null);
  const [canViewUserPages, setCanViewUserPages] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-instances/" + recordId, "get")
      .then((response) => {
        setIsLoading(false);
        if (response && response.statusCode) {
          setPageTitle("Instance " + response.instance.name);
          setInstanceInstance(response.instance);
          setInstanceConnection(response.connection);
          setInstanceWorkflow(response.workflow);
          setInstanceDdb(response.ddb_records);
          setInstanceNotes(response.notes);
          setCanViewUserPages(response.can_view_user_pages);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to view this instance.", "warning");
      });
  };

  const toggleCollapse = (newCollapseId) => {
    setCollapseId((oldCollapseId) => {
      if (oldCollapseId === newCollapseId) {
        return null;
      } else {
        return newCollapseId;
      }
    });
  };

  let loadingContent = <LoadingIcon />;
  if (!isLoading) loadingContent = null;

  let pageContent = loadingContent;

  let workflowContent = (
    <MDBCol xs="12" lg="4">
      <MDBCard>
        <MDBCardHeader color="primary-color lighten-1">Workflow</MDBCardHeader>
        <MDBCardBody>
          <p>No workflow information is available.</p>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  );
  if (instanceWorkflow) {
    let workflowConfiguration = [];
    workflowConfiguration.push(<hr key="workflow-configuration-hr" />);
    for (const configKey in instanceWorkflow.configuration) {
      if (instanceWorkflow.configuration.hasOwnProperty(configKey)) {
        if (typeof instanceWorkflow.configuration[configKey] === "object") {
          workflowConfiguration.push(
            <div key={configKey}>
              <MDBCardText>
                <strong>{configKey}:</strong>
              </MDBCardText>
              <pre className="card-text">{JSON.stringify(instanceWorkflow.configuration[configKey], null, 2)}</pre>
            </div>
          );
        } else {
          workflowConfiguration.push(
            <div key={configKey}>
              <MDBCardText key={configKey}>
                <strong>{configKey}:</strong> <CopyIcon text={instanceWorkflow.configuration[configKey].toString()} />
              </MDBCardText>
            </div>
          );
        }
      }
    }
    workflowContent = (
      <MDBCol xs="12" lg="4">
        <MDBCard>
          <MDBCardHeader color="primary-color lighten-1">Workflow</MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>{instanceWorkflow.name}</MDBCardTitle>
            <MDBCardText>{instanceWorkflow.description}</MDBCardText>
            {workflowConfiguration}
            {instanceWorkflow.components !== undefined
              ? instanceWorkflow.components.map((component) => {
                  const componentKey = "component-" + component.componentId;
                  let componentDescription = component.description;
                  if (componentDescription === "") componentDescription = component.prototype.description;

                  let componentIcon = null;
                  if (component.status === "SUCCEEDED")
                    componentIcon = <MDBIcon far icon="check-circle" className="green-text" title={component.status} />;
                  const componentIconContainer = (
                    <div className="workflow-instance-component-icon float-right">{componentIcon}</div>
                  );

                  return (
                    <MDBCard key={componentKey} className="workflow-instance-component mt-4">
                      <MDBCardBody>
                        <MDBCardTitle>
                          {component.name} {componentIconContainer}
                        </MDBCardTitle>
                        {componentDescription !== "" ? <MDBCardText>{componentDescription}</MDBCardText> : ""}
                      </MDBCardBody>
                    </MDBCard>
                  );
                })
              : null}
            {instanceWorkflow.error !== undefined && instanceWorkflow.error !== "" ? (
              <MDBCard>
                <MDBCardBody>
                  <MDBCardTitle>Errors</MDBCardTitle>
                  <MDBCardText>{instanceWorkflow.error}</MDBCardText>
                </MDBCardBody>
              </MDBCard>
            ) : null}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    );
  }

  if (instanceInstance && instanceConnection && instanceDdb) {
    let instanceIcon = null;
    if (instanceInstance.status === "SUCCEEDED")
      instanceIcon = <MDBIcon far icon="check-circle" className="green-text" title={instanceInstance.status} />;
    else if (instanceInstance.status === "FAILED")
      instanceIcon = <MDBIcon far icon="times-circle" className="red-text" title={instanceInstance.status} />;
    else
      instanceIcon = <MDBIcon far icon="play-circle" className="orange-text" title='In progress' />;
    const instanceIconContainer = <div className="workflow-instance-icon float-right">{instanceIcon}</div>;

    let ddbRecords = [];
    let ddbCount = 1;
    instanceDdb.forEach((ddb) => {
      const ddbId = ddbCount.toString();

      let ddbIcon = null;

      if ("status" in ddb) {
        if (ddb.status.S === "COMPLETED" || ddb.status.S === "WORKFLOW_COMPLETED") {
          ddbIcon = <MDBIcon far icon="check-circle" className="green-text" title={ddb.status.S} />;
        } else {
          ddbIcon = <MDBIcon far icon="times-circle" className="red-text" title={ddb.status.S} />;
        }
      }

      const ddbIconContainer = <div className="workflow-instance-icon float-right">{ddbIcon}</div>;
      ddbRecords.push(
        <MDBCard key={ddbId}>
          <MDBCollapseHeader onClick={(e) => toggleCollapse(ddbId)}>
            {ddb.recordType.S} {ddbIconContainer}
          </MDBCollapseHeader>
          <MDBCollapse id={ddbId} isOpen={collapseID}>
            <MDBCardBody>
              <pre>{JSON.stringify(ddb, null, 2)}</pre>
            </MDBCardBody>
          </MDBCollapse>
        </MDBCard>
      );
      ddbCount++;
    });

    pageContent = (
      <Fragment>
        {loadingContent}
        <h1 className="page-title">
          Input Instance ID {instanceInstance.name} {instanceIconContainer}
        </h1>
        <p className="workflow-monitoring-instance-times">
          <span className="workflow-monitoring-instance-times-label">Started:</span>{" "}
          <span className="workflow-monitoring-instance-times-time">
            {instanceInstance.start.replace(/\//g, "-")} UTC
          </span>
          {"end" in instanceInstance && (
            <>
              <br />
              <span className="workflow-monitoring-instance-times-label">Ended:</span>{" "}
              <span className="workflow-monitoring-instance-times-time">
                {instanceInstance.end.replace(/\//g, "-")} UTC
              </span>
            </>
          )}
        </p>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a href="/">Home</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <a href="/workflow-monitoring/">Workflow monitoring</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>{instanceInstance.name}</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <div className="workflow-monitoring-instance-detail">
          <MDBRow>
            <MDBCol xs="12" lg="4">
              <MDBCard className="mb-4">
                <MDBCardHeader color="primary-color lighten-1">Connection</MDBCardHeader>
                <MDBCardBody>
                  <MDBCardTitle>{instanceConnection.name}</MDBCardTitle>
                  <MDBCardText>{instanceConnection.description}</MDBCardText>
                  <MDBCardText>
                    <strong>Type:</strong> {instanceConnection.type}
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Registration type:</strong> {instanceConnection.registration_type}
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Source location:</strong> {instanceConnection.source_location}
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Destination location:</strong> {instanceConnection.destination_location}
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
              <WorkflowMonitoringInstanceNotes
                instanceId={instanceInstance.name}
                instanceNotes={instanceNotes}
                setInstanceNotes={setInstanceNotes}
                setIsLoading={setIsLoading}
                canViewUserPages={canViewUserPages}
              />
            </MDBCol>

            {workflowContent}

            <MDBCol xs="12" lg="4">
              <MDBCard>
                <MDBCardHeader color="primary-color lighten-1">Execution</MDBCardHeader>
                <MDBCardBody>
                  <MDBCardText>
                    <strong>Input instance ID:</strong> <CopyIcon text={instanceInstance.name} />
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Status:</strong> {instanceInstance.status}
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Started:</strong> {instanceInstance.start} UTC
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Ended:</strong> {instanceInstance.end} UTC
                  </MDBCardText>
                  <MDBCardText>
                    <strong>Execution ID:</strong>{" "}
                    <a href={instanceInstance.execution_detail_url} target="_blank" rel="noopener noreferrer">
                      <CopyIcon text={instanceInstance.execution_id} />
                    </a>
                  </MDBCardText>
                  <QrCode value={instanceInstance.execution_detail_url} link={true} />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <div className="instance-dynamodb-records">
            <MDBRow>
              <MDBCol>
                <h2 className="mb-4">Details</h2>
                {ddbRecords}
              </MDBCol>
            </MDBRow>
          </div>
          <MDBRow>
            <MDBCol>
              <MDBBtn href="/workflow-monitoring" color="primary">
                Back
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      </Fragment>
    );
  }
  return pageContent;
};

export default withRouter(WorkflowMonitoringInstance);
